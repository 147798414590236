import React from "react";
import "./ui-kits.styles.scss";

const TwoRadioButtonLine = ({ label_1, label_2, val, setVal }) => (
  <div className="two-radio-button-line">
    <div className="select-button" onClick={() => setVal && (val === 1 ? setVal(null) : setVal(1))}>
      {val === 1 ? <i className="fa fa-check-circle-o" id="label_1"></i> : <i className="fa fa-circle-o" id="label_1"></i>}
      <label htmlFor="label_1">{label_1}</label>
    </div>
    <div className="select-button" onClick={() => setVal && (val === 0 ? setVal(null) : setVal(0))}>
      {val === 0 ? <i className="fa fa-check-circle-o" id="label_2"></i> : <i className="fa fa-circle-o" id="label_1"></i>}
      <label htmlFor="label_2">{label_2}</label>
    </div>
  </div>
);

export default TwoRadioButtonLine;
