import React, { useRef, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../../assets/images/eliqx-logo-all-yellow.png";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import HeaderProfileMenu from "../shop/header-profile-menu.component";
import HeaderMainMenu from "../shop/header-main-menu.component";
import { toggleHeaderProfileMenuShow, toggleHeaderMainMenuShow } from "../../redux/setting/setting.action";
import { createStructuredSelector } from "reselect";
import { getIsActivedSeller, getToken } from "../../redux/auth/auth.selector";
import { getSearchKeyword } from "../../redux/shop/shop.selector";
import { setSearchKeyword } from "../../redux/shop/shop.action";
import "./header.styles.scss";

const Header = ({ toggleProfileMenuShow, toggleMainMenuShow, isActivedSeller, token, history, searchKey, setSearchKey }) => {
	const isIn = useRef();

	const searchInputHandle = (event) => {
		setSearchKey(event.target.value);
	};
	const submit = (event) => {
		event.preventDefault();
		history.push(`/shop/products`);
	};
	const showProfileMenu = () => {
		toggleMainMenuShow(false);
		toggleProfileMenuShow(true);
	};
	const showMainMenu = () => {
		toggleProfileMenuShow(false);
		toggleMainMenuShow(true);
	};
	const hideMenuMenu = () => {
		toggleProfileMenuShow(false);
		toggleMainMenuShow(false);
	};
	const [scroll, setScroll] = useState(false);

	useEffect(() => {
		isIn.current = true;

		window.addEventListener("scroll", () => {
			if (isIn.current) setScroll(window.scrollY > 30);
		});

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<header className={scroll ? "header scrolled" : "header"} onMouseLeave={hideMenuMenu}>
			<ToastContainer autoClose={7000} draggable={false} />
			<div className="desktop-nav-container">
				<Link to="/" className="desktop-nav-logo">
					<img src={logo} alt="logo" />
				</Link>
				<form className="search-container" onSubmit={submit}>
					<input className="search-input" type="text" placeholder="search rare bottles" value={searchKey} onChange={searchInputHandle} />
					<button className="search-arrow" type="submit" onClick={submit}>
						<span className="icon-cc-right-arrow"></span>
					</button>
				</form>
				<div className="desktop-right-nav">
					<Link
						className="desktop-nav-link"
						to={!token ? "/login" : isActivedSeller ? "/seller/profile-watchlist" : "/buyer/profile-watchlist"}
						onMouseEnter={hideMenuMenu}
					>
						<span className="icon-cc-watchlist"></span>
					</Link>
					<Link className="desktop-nav-link" to="#" onMouseOver={showProfileMenu} replace={true}>
						<span className="icon-cc-profile"></span>
					</Link>
					<Link className="desktop-nav-link" to="#" onMouseOver={showMainMenu} replace={true}>
						<span className="icon-cc-menu"></span>
					</Link>
				</div>
			</div>
			<HeaderProfileMenu />
			<HeaderMainMenu />
		</header>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
	isActivedSeller: getIsActivedSeller,
	searchKey: getSearchKeyword,
});

const mapDispatchToProps = (dispatch) => ({
	toggleProfileMenuShow: (val) => dispatch(toggleHeaderProfileMenuShow(val)),
	toggleMainMenuShow: (val) => dispatch(toggleHeaderMainMenuShow(val)),
	setSearchKey: (val) => dispatch(setSearchKeyword(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
