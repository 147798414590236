import React from "react";
import logo from "../../assets/images/order-confirm-grey-logo.svg";
import step1 from "../../assets/images/eliqx-how-it-works-step-1.jpg";
import step2 from "../../assets/images/eliqx-how-it-works-step-2.jpg";
import step3 from "../../assets/images/eliqx-how-it-works-step-3.jpg";
import "./legal-pages.styles.scss";

const SellerRegisterConfirmPage = () => (
	<div className="buyer-guide-page">
		<div className="cc-container">
			<img className="legal-img" src={logo} alt="eliqx grey logo" />
			<h2>thank you for signing up we will review your application within 48 hours and you will get a notification of our review</h2>
			<ul className="cc-feat-list">
				<li>not your normal liquor store</li>
				<li>an online marketplace for rare bottles from licensed liquor retailers throughout the state.</li>
				<li>a nexus of licensed liquor retailers competing for your business</li>
				<li>a "stock market" for rare bottles of liquor</li>
			</ul>
			<div className="cc-buyer-step-container">
				<img className="step-img" src={step1} alt="eliqx-how-it-works-step-1.jpg" />
				<h3 className="cc-buyer-step">step 1: find what you're looking for</h3>
				<p className="cc-buyer-steps">
					You can search for the exact rare bottle you’ve been looking for. Specific packaging, like tags or boxes, are not overlooked in the vetting process.
					Any single listing is sourced from multiple licensed retailers across the state.
				</p>
				<p className="cc-buyer-steps">You can see:</p>
				<p className="cc-buyer-steps-details">1) how many bottles are available and for how much.</p>
				<p className="cc-buyer-steps-details">2) the prices bottles sold for in the past.</p>
				<p className="cc-buyer-steps-details">3) what other people are willing to pay for that same rare bottle.</p>
			</div>
			<div className="cc-buyer-step-container">
				<img className="step-img" src={step2} alt="eliqx-how-it-works-step-2.jpg" />
				<h3 className="cc-buyer-step">
					step 2: <strong>buy</strong> now or make an <strong>offer</strong>
				</h3>
				<p className="cc-buyer-steps">
					Now that you have all the information you need, you can <strong>buy</strong> at the lowest asking price or <strong>offer</strong> what you want to
					pay. The <strong>buy</strong> price you see for individual items is the lowest asking price from one licensed retailer. If you want to make an
					<strong>offer</strong> for lower than the <strong>buy</strong> price, you can receive updates and notifications on the market fluctuations of that
					item. Numerous retailers are constantly competing for your business, so if you have the highest <strong>offer</strong>, one of the retailers could
					accept your <strong>offer</strong> at any time.
				</p>
			</div>
			<div className="cc-buyer-step-container">
				<img className="step-img" src={step3} alt="eliqx-how-it-works-step-3.jpg" />
				<h3 className="cc-buyer-step">
					step 3: delivery and happy <strong>sipping</strong>
				</h3>
				<p className="cc-buyer-steps">
					Once you <strong>buy</strong> a rare bottle or your <strong>offer</strong> is accepted, the delivery process begins. It will be delivered to your
					specified address. Delivery can take 4 - 8 business days and an adult signature is alway required.
				</p>
			</div>
		</div>
	</div>
);

export default SellerRegisterConfirmPage;
