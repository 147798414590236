import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import logo from "../../../assets/images/eliqx-logo-all-grey.svg";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";
import { extractErrors } from "../../../helpers/commonHelper";
import { getToken } from "../../../redux/auth/auth.selector";
import { buyerGetMyBidById, buyerRemoveMyBid } from "../../../services/ApiService";
import "./buyer-remove-bid-page.styles.scss";

const BuyerRemoveBidPage = ({ token, match }) => {
	const bidId = match.params.bidId;
	const isIn = useRef();
	const [loading, setLoading] = useState(false);
	const [bid, setBid] = useState();
	const history = useHistory();

	const loadMyBid = useCallback(() => {
		setLoading(true);
		buyerGetMyBidById(token, bidId)
			.then((res) => {
				if (isIn.current) {
					setBid(res);
					setLoading(false);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [token, bidId]);

	const removeBid = () => {
		setLoading(true);
		buyerRemoveMyBid(token, bidId)
			.then((res) => {
				history.replace("/buyer/profile-portfolio");
				toast.success("Removed successfully");
			})
			.catch((err) => {
				toast.error(extractErrors(err)[0]);
				setLoading(false);
			});
	};

	useEffect(() => {
		loadMyBid();
	}, [loadMyBid]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="buyer-remove-bid-page">
			{loading || !bid ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<div className="custom-modal">
					<div className="wrap">
						<img src={logo} alt="grey-logo" />
						<h2>Please Confirm Cancellation</h2>
						<p>
							Bids can be cancelled as long as they have not been succesfully executed on the exchange. Please confirm below by clicking on Cancel Bid.
							Alternatively, bid prices can be edited. Click on Edit Bid to change your bid price.
						</p>
						<div className="buttons">
							<Link to="#" onClick={removeBid} replace={true}>
								CANCEL BID
							</Link>
							<Link to={`/buyer/place-bid/${bid.product.id}?bid=${bid.id}`}>EDIT BID</Link>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(withRouter(BuyerRemoveBidPage));
