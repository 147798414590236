import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./shop.styles.scss";
import { shopGetProducts } from "../../services/ApiService";
import { extractErrors, numberToPriceString } from "../../helpers/commonHelper";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../redux/auth/auth.selector";
import { connect } from "react-redux";
import NormalLoading from "../ui-kits/normal-loading.component";

const PackageBox = ({ product }) => (
	<div className="top-pick-details">
		<p className="top-pick-detail">{product.size}</p>
		<p className="top-pick-detail">{product.abv}% ABV</p>
		{product.has_box !== null && <p className="top-pick-detail">{product.has_box ? "With" : "Without"} BOX</p>}
		{product.has_tag !== null && <p className="top-pick-detail">{product.has_tag ? "With" : "Without"} TAG</p>}
		{product.has_bag !== null && <p className="top-pick-detail">{product.has_bag ? "With" : "Without"} BAG</p>}
		{product.has_tin !== null && <p className="top-pick-detail">{product.has_tin ? "With" : "Without"} TIN</p>}
		{product.has_case !== null && <p className="top-pick-detail">{product.has_case ? "With" : "Without"} CASE</p>}
	</div>
);

const TopSellingProduct = ({ token }) => {
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState();
	const isIn = useRef();

	const loadData = useCallback(() => {
		setLoading(true);
		shopGetProducts({ properties: { top_pick: true } }, 3, 1, token)
			.then((res) => {
				if (!isIn.current) return;
				setLoading(false);
				setProducts(res.data);
			})
			.catch((err) => {
				if (!isIn.current) return;
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	}, [token]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="top-selling-products">
			<div className="carousel-title">
				<h2 className="category-title">eliqx picks</h2>
			</div>
			{loading || !products || products === undefined ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<div className="top-selling-product-wrap">
					{products.map((product) => (
						<div key={product.id} className="top-pick-card">
							<div className="product-card-link">
								<Link to={`/shop/product/${product.id}`}>
									<img className="top-pick-img" src={product.images[0]} alt="eliqx rare spirits product profile" />
								</Link>
								<div className="product-info">
									<Link to={`/shop/product/${product.id}`}>
										<h2 className="top-pick-product-name">{product.name}</h2>
									</Link>
									<PackageBox product={product} />
									<div className="product-price-block">
										<Link to={`/shop/product/${product.id}`}>
											<span className="product-listing-current-price">${numberToPriceString(product.min_price)}</span>
										</Link>
										<span className="product-listing-status">lowest listing</span>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(TopSellingProduct);
