import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Pagination from "react-js-pagination";
import * as ApiService from "../../services/ApiService";
import emptySearchImg from "../../assets/images/empty-search.jpg";
import AdminProductCard from "../../components/admin/admin-product-card.component";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { getLang } from "../../helpers/languageHelper";
import "./admin.styles.scss";
import NormalLoading from "../../components/ui-kits/normal-loading.component";

const AdminProductsPage = ({ token }) => {
	const isIn = useRef();
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const perPage = 12;
	const [keyword, setKeyword] = useState("");
	const [totalCount, setTotalCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const inputHandle = (event) => {
		setKeyword(event.target.value);
	};

	const loadProducts = useCallback(() => {
		setError(null);
		setLoading(true);
		ApiService.shopGetProducts({ keyword }, perPage, currentPage, token)
			.then((res) => {
				if (isIn.current) {
					setLoading(false);
					setProducts(res.data);
					setCurrentPage(res.meta.current_page);
					setTotalCount(res.meta.total);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					setError("connection_failed");
				}
			});
	}, [keyword, currentPage, token]);

	useEffect(() => {
		loadProducts();
	}, [loadProducts, keyword, currentPage, totalCount]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="admin-products-page">
			<div className="section-ptb">
				<Container>
					<Row>
						<Col lg={12}>
							<div className="mb-0 text-left">
								<h4>{getLang("product_list")}</h4>
							</div>
							<div className="mb-4">
								<form>
									<div className="form-group mb-0">
										<input type="text" className="form-control" placeholder={getLang("search_product")} value={keyword} onChange={inputHandle} />
									</div>
								</form>
							</div>
							<div className="mb-0 mb-md-4">
								{loading && (
									<div className="loading">
										<NormalLoading />
									</div>
								)}
								{error && <div className="mw-50 alert alert-danger ">{getLang(error)}</div>}
								{products.length > 0 ? (
									<Row className="products products-loop grid ciyashop-products-shortcode pgs-product-list">
										{products.map((product, index) => (
											<AdminProductCard key={index} product={product} />
										))}
									</Row>
								) : (
									!loading && (
										<Row className="products products-loop grid ciyashop-products-shortcode">
											<div className="col-sm-12 text-center  mt-4 mt-md-5">
												<img src={emptySearchImg} className="img-fluid mb-4" alt="empty" />
												<h3>{getLang("no_products_were_found_matching_your_selection")}</h3>
												<p>{getLang("please_try_to_other_words")}.</p>
											</div>
										</Row>
									)
								)}
							</div>
							<div className="row mt-md-3">
								<div className="col-12">
									<div>
										<Pagination
											activePage={currentPage}
											itemsCountPerPage={perPage}
											totalItemsCount={totalCount}
											pageRangeDisplayed={10}
											onChange={(page) => setCurrentPage(page)}
											itemClass="page-item"
											linkClass="page-link"
										/>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(AdminProductsPage);
