import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Container } from "reactstrap";
import { createStructuredSelector } from "reselect";
import NormalLoading from "../../components/ui-kits/normal-loading.component";
import { extractErrors } from "../../helpers/commonHelper";
import { getLang } from "../../helpers/languageHelper";
import { getToken } from "../../redux/auth/auth.selector";
import { adminGetUsers } from "../../services/ApiService";
import "./admin.styles.scss";

const AdminUsersPage = ({ token, history }) => {
	const isIn = useRef();
	const [users, setUsers] = useState();
	const [loading, setLoading] = useState(false);

	const loadData = useCallback(() => {
		setLoading(true);
		adminGetUsers(token)
			.then((res) => {
				if (!isIn.current) return;
				setLoading(false);
				setUsers(res);
			})
			.catch((err) => {
				if (!isIn.current) return;
				setLoading(false);
				toast.error(getLang(extractErrors(err)[0]));
			});
	}, [token]);

	useEffect(() => {
		isIn.current = true;
		loadData();

		return () => {
			isIn.current = false;
		};
	}, [loadData]);

	return (
		<div className="admin-users-page">
			<Container>
				<h3 className="subtitle">{getLang("users_list")}</h3>
				{loading || !users ? (
					<div className="loading">
						<NormalLoading />
					</div>
				) : (
					<table className="table table-striped mb-0">
						<thead>
							<tr>
								<th scope="col">{getLang("name")}</th>
								<th scope="col">{getLang("email")}</th>
								<th scope="col">{getLang("role")}</th>
								<th scope="col">{getLang("status")}</th>
								<th scope="col">{getLang("is_seller")}</th>
								<th scope="col">{getLang("email_phone_verified")}</th>
								<th scope="col">{getLang("registered_date")}</th>
							</tr>
						</thead>
						<tbody>
							{users.map((item) => (
								<tr key={item.id} onClick={() => history.push(`/admin/user/detail/${item.id}`)}>
									<td>{item.name}</td>
									<td>{item.email}</td>
									<td>{item.role}</td>
									<td>{item.status}</td>
									<td>{item.seller_profile?.status === "APPROVED" ? "YES" : "NO"}</td>
									<td>
										{item.email_verified ? "YES" : "NO"} / {item.phone_number_verified ? "YES" : "NO"}
									</td>
									<td>{item.created_at}</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</Container>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(AdminUsersPage);
