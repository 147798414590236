import React from "react";
import { Link } from "react-router-dom";

const SellerCertifyBoxComponent = ({ value, setValue }) => (
	<>
		<div className="form-check mb-2">
			<input
				className="form-check-input"
				type="checkbox"
				id="must-check-1"
				value={!!(value & 1)}
				checked={!!(value & 1)}
				onChange={() => {}}
				onClick={() => setValue(value ^ 1)}
			/>
			<label className="form-check-label" htmlFor="must-check-1">
				I certify that this is a new, undamaged, unopened and authentic bottle
			</label>
		</div>
		<div className="form-check mb-2">
			<input
				className="form-check-input"
				type="checkbox"
				id="must-check-2"
				value={!!(value & 2)}
				checked={!!(value & 2)}
				onChange={() => {}}
				onClick={() => setValue(value ^ 2)}
			/>
			<label className="form-check-label" htmlFor="must-check-2">
				I certify that I am legally allowed to sell this bottle
			</label>
		</div>
		<div className="form-check mb-2">
			<input
				className="form-check-input"
				type="checkbox"
				id="must-check-3"
				value={!!(value & 4)}
				checked={!!(value & 4)}
				onChange={() => {}}
				onClick={() => setValue(value ^ 4)}
			/>
			<label className="form-check-label" htmlFor="must-check-3">
				<Link to="/seller/terms" onClick={() => window.scroll({ top: 0, left: 0, behavior: "smooth" })}>
					I agree with seller terms of service
				</Link>
			</label>
		</div>
	</>
);

export default SellerCertifyBoxComponent;
