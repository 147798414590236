import React, { useState } from "react";
import logo from "../../assets/images/order-confirm-grey-logo.svg";
import { isPassedProtection } from "../../helpers/commonHelper";
import "./password-gateway-page.styles.scss";

const PasswordGatewayPage = () => {
	const [password, setPassword] = useState("");

	const inputHandle = (event) => {
		setPassword(event.target.value);
	};
	const submit = (event) => {
		event.preventDefault();
		if (isPassedProtection(password)) {
			window.location.reload();
		} else {
			alert("Invalid password!");
		}
	};

	return (
		<div className="password-gateway-page">
			<div className="pwContainer">
				<img className="orderLogo" src={logo} alt="eliqx grey logo" />
				<div className="pwBox">
					<form name="cc-password" onSubmit={submit}>
						<label htmlFor="password"></label>
						<input
							className="cc-signup-input-box"
							type="password"
							name="password"
							id="password"
							placeholder="Password"
							value={password}
							onChange={inputHandle}
						/>
						<button className="cc-register-btn" type="submit">
							enter
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default PasswordGatewayPage;
