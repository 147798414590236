import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import { extractErrors, numberToPriceString } from "../../../helpers/commonHelper";
import { getToken } from "../../../redux/auth/auth.selector";
import { buyerSubmitOrder, shopGetProductDetail } from "../../../services/ApiService";
import BuyerOrderConfirmedPage from "./buyer-order-confirmed.component";
import BuyerSaveCardForm from "../../../components/buyer/buyer-save-card-form/buyer-save-card-form.component";
import BuyerSaveShippingForm from "../../../components/buyer/buyer-save-shipping-form/buyer-save-shipping-form.component";
import "./buyer-place-order-page.styles.scss";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";
import BuyerPriceLimitExceedComponent from "../../../components/buyer/buyer-price-limit-exceed.component";

const BuyerPlaceOrderPage = ({ token, match, location }) => {
	const isIn = useRef();
	const productId = match.params.productId;
	const listingId = new URLSearchParams(location.search).get("listing");
	const [product, setProduct] = useState();
	const [listing, setListing] = useState();
	const [loading, setLoading] = useState(false);
	const [onShippingInsurance, setOnShippingInsurance] = useState(false);
	const [onSalesService, setOnSalesService] = useState(false);
	const [selectedCard, setSelectedCard] = useState();
	const [selectedShipping, setSelectedShipping] = useState();

	const loadProduct = useCallback(() => {
		setLoading(true);
		shopGetProductDetail(productId, token)
			.then((res) => {
				if (!isIn.current) return;
				setProduct(res);
				if (res.seller_products) {
					if (listingId) {
						setListing(res.seller_products.find((x) => x.id === listingId));
					} else {
						res.seller_products.sort((a, b) => (a.price < b.price ? -1 : 1));
						if (res.seller_products.length > 0) setListing(res.seller_products[0]);
					}
				}
				setLoading(false);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	}, [productId, token, listingId]);

	const submitOrder = () => {
		if (!selectedCard || !selectedShipping) {
			toast.error("Please select your card, and shipping");
			return;
		}
		const form = {
			billing_id: selectedCard.id,
			shipping_id: selectedShipping.id,
			price: listing.price,
		};
		setLoading(true);
		buyerSubmitOrder(token, listing.id, form)
			.then((res) => {
				localStorage.setItem(`order_confirmed_${productId}`, JSON.stringify(res));
				setLoading(false);
			})
			.catch((err) => {
				if (isIn.current) {
					if (err.error === "buy_now_price_limit_exceed") {
						localStorage.setItem(`buy_now_price_limit_exceed_${product.id}`, new Date().getTime());
						setLoading(false);
					} else {
						loadProduct();
						toast.error(extractErrors(err)[0]);
					}
				}
			});
	};

	useLayoutEffect(() => loadProduct(), [loadProduct]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	if (product && localStorage.getItem(`order_confirmed_${product.id}`)) {
		return <BuyerOrderConfirmedPage product={product} />;
	}
	if (!listing) {
		return (
			<div className="buyer-place-order-page">
				<div className="container">
					{loading ? (
						<div className="loading">
							<NormalLoading />
						</div>
					) : (
						<h1>Listing does not exist</h1>
					)}
				</div>
			</div>
		);
	}
	const { sales_tax, service_fee, shipping, insurance } = listing?.transaction_fees.buyer_fee.details;
	const total = listing.price + listing.transaction_fees.buyer_fee.sum;

	return (
		<div className="buyer-place-order-page">
			{loading || !product ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : listing ? (
				<div className="container ">
					<div className="buyScreenContainer">
						<div className="buyNow">
							<h2>{product.name}</h2>
							<ul className="details">
								<li>{product.size}</li>
								<li>{product.abv}% ABV</li>
								{product.has_box !== null && <li>{product.has_box ? "With" : "Without"} BOX</li>}
								{product.has_tag !== null && <li>{product.has_tag ? "With" : "Without"} TAG</li>}
								{product.has_bag !== null && <li>{product.has_bag ? "With" : "Without"} BAG</li>}
								{product.has_tin !== null && <li>{product.has_tin ? "With" : "Without"} TIN</li>}
								{product.has_case !== null && <li>{product.has_case ? "With" : "Without"} CASE</li>}
								{product.batch !== null && <li>{product.batch}</li>}
								{product.lot !== null && <li>{product.lot}</li>}
							</ul>
							<div className="product_img">
								<img src={product.images[0]} alt={product.id} />
							</div>
						</div>
						{localStorage.getItem(`buy_now_price_limit_exceed_${product.id}`) ? (
							<BuyerPriceLimitExceedComponent productId={product.id} />
						) : (
							<div className="buyProduct">
								<div className="top-headline">
									<div className="heading d-flex justify-content-between">
										<h2 style={{ width: "60%" }}>Market Price</h2>
										<h2>${numberToPriceString(listing.price)}</h2>
									</div>
								</div>
								<div className="invoiceTable">
									<table className="table table-borderless">
										<tbody>
											<tr>
												<td>
													Shipping
													<div className="tooltip-popup">
														<i className="icon-cc-info" onMouseEnter={() => setOnSalesService(true)} onMouseLeave={() => setOnSalesService(false)}></i>
														{onSalesService && (
															<>
																<div className="popup">
																	<table>
																		<tbody>
																			<tr>
																				<td>
																					<small>{shipping.label}</small>
																				</td>
																				<td>
																					<small>${numberToPriceString(shipping.value)}</small>
																				</td>
																			</tr>
																			<tr>
																				<td>
																					<small>{insurance.label}</small>
																				</td>
																				<td>
																					<small>${numberToPriceString(insurance.value)}</small>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</>
														)}
													</div>
												</td>
												<td>${numberToPriceString(shipping.value + insurance.value)}</td>
											</tr>
											<tr>
												<td>
													Taxes & Fees
													<div className="tooltip-popup">
														<i
															className="icon-cc-info"
															onMouseEnter={() => setOnShippingInsurance(true)}
															onMouseLeave={() => setOnShippingInsurance(false)}
														></i>
														{onShippingInsurance && (
															<>
																<div className="popup">
																	<table>
																		<tbody>
																			<tr>
																				<td>
																					<small>{service_fee.label}</small>
																				</td>
																				<td>
																					<small>${numberToPriceString(service_fee.value)}</small>
																				</td>
																			</tr>
																			<tr>
																				<td>
																					<small>{sales_tax.label}</small>
																				</td>
																				<td>
																					<small>${numberToPriceString(sales_tax.value)}</small>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</>
														)}
													</div>
												</td>
												<td>${numberToPriceString(service_fee.value + sales_tax.value)}</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<th>TOTAL</th>
												<th>${numberToPriceString(total)}</th>
											</tr>
										</tfoot>
									</table>
								</div>
								<div className="middle">
									<BuyerSaveCardForm selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
									<BuyerSaveShippingForm selectedShipping={selectedShipping} setSelectedShipping={setSelectedShipping} />
								</div>
								<div className="btnWrapper ">
									<button className="buyBtn" onClick={submitOrder}>
										Buy Now
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			) : (
				<div>
					<h1>Listing does not exist</h1>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(BuyerPlaceOrderPage);
