import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import { createStructuredSelector } from "reselect";
import NormalLoading from "../../components/ui-kits/normal-loading.component";
import { extractErrors, numberToPriceString, openPrintImage } from "../../helpers/commonHelper";
import {
	adminCreateBottleShipment,
	adminCreateBoxShipment,
	adminGetOrderDetail,
	adminPayoutToSeller,
	adminUpdateBuyerOrder,
	buyerGetShippingInfos,
} from "../../services/ApiService";
import { getToken } from "../../services/StorageService";
import PageNotFound from "../page-not-found.component";
import "./admin.styles.scss";

const AdminOrderDetailPage = ({ token, match }) => {
	const isIn = useRef();
	const orderId = match.params.orderId;
	const [loading, setLoading] = useState(true);
	const [order, setOrder] = useState();
	const [shipping, setShipping] = useState();

	const loadData = useCallback(() => {
		setLoading(true);
		adminGetOrderDetail(token, orderId)
			.then((res) => {
				setOrder(res);
				setLoading(false);
			})
			.catch((err) => {
				toast.error(extractErrors(err)[0]);
				setLoading(false);
			});
	}, [token, orderId]);

	const loadShipping = useCallback(() => {
		buyerGetShippingInfos(token)
			.then((res) => {
				setShipping(res.filter((x) => x.is_pickup === 0));
			})
			.catch((err) => {
				if (isIn.current) {
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [token]);

	const payoutNow = () => {
		if (order.payout_id) return;
		setLoading(true);
		adminPayoutToSeller(token, orderId)
			.then((res) => {
				loadData();
			})
			.catch((err) => {
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	};
	const createBoxShipment = (item) => {
		if (order.admin_shipping_status) {
			toast.error("Already requested");
			return;
		}
		setLoading(true);
		adminCreateBoxShipment(token, orderId, { admin_shipping_id: item.id })
			.then((res) => {
				toast.success("Successfully submitted");
				loadData();
			})
			.catch((err) => {
				toast.error(extractErrors(err)[0]);
				setLoading(false);
			});
	};
	const createBottleShipping = () => {
		if (order.status !== "PENDING") {
			toast.error("This order is not available");
			return;
		}
		setLoading(true);
		adminCreateBottleShipment(token, orderId)
			.then((res) => {
				toast.success("Successfully submitted");
				loadData();
			})
			.catch((err) => {
				toast.error(extractErrors(err)[0]);
				setLoading(false);
			});
	};
	const downloadBottleShippingLabel = () => {
		openPrintImage(order.shipping_label);
	};
	const downloadBoxShippingLabel = () => {
		openPrintImage(order.admin_shipping_label);
	};
	const markAdDelivered = () => {
		if (order.admin_shipping_status === "DELIVERED") return;
		setLoading(true);
		adminUpdateBuyerOrder(token, orderId, { admin_shipping_status: "DELIVERED" })
			.then((res) => {
				loadData();
				toast.success("Updated successfully");
			})
			.catch((err) => {
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	};

	useEffect(() => {
		loadData();
		loadShipping();
	}, [loadData, loadShipping]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="admin-order-detail-page">
			<Container>
				{loading ? (
					<div className="loading">
						<NormalLoading />
					</div>
				) : order ? (
					<Col md={12}>
						<h2 className="order-title">Order #{order.id}</h2>
						<Row style={{ justifyContent: "space-around" }}>
							<Col md={4}>
								<table className="table table-stripe mb-0">
									<tbody>
										<tr>
											<th scope="col">Seller name</th>
											<td>{order.seller.name}</td>
										</tr>
										<tr>
											<th scope="col">Seller email</th>
											<td>{order.seller.email}</td>
										</tr>
										<tr>
											<th scope="col">SHIP 1 Info</th>
											<td>{order.admin_shipping_status ? "Delivered" : order.admin_shipping_label ? "On Shipping" : "Pending"}</td>
										</tr>
										<tr>
											<th scope="col">SHIP 1 Status</th>
											<td>
												{order.admin_shipping_label && (
													<button className="cc-ship-btn" onClick={downloadBoxShippingLabel}>
														Download Label
													</button>
												)}
												{order.admin_shipping_status && <div className="cc-ship-status">{order.admin_shipping_status}</div>}
												{!order.admin_shipping_status &&
													!order.admin_shipping_label &&
													shipping &&
													shipping.map((item) => (
														<button className="cc-ship-btn" onClick={createBoxShipment.bind(this, item)} key={item.id}>
															Ship Now (from {item.first_name} {item.last_name} {item.email} {item.phone_number} {item.address_1} {item.address_2} {item.city}
															{item.zip_code})
														</button>
													))}
												{order.admin_shipping_status !== "DELIVERED" && (
													<button className="cc-ship-btn" onClick={markAdDelivered}>
														Mark as delivered
													</button>
												)}
												{order.track_admin_shipping_url && (
													<a className="cc-ship-btn" href={order.track_admin_shipping_url} target="_blank" rel="noreferrer">
														Track Ship 1
													</a>
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</Col>
							<Col md={4}>
								<table className="table table-stripe mb-0">
									<tbody>
										<tr>
											<th scope="col">Buyer name</th>
											<td>{order.buyer.name}</td>
										</tr>
										<tr>
											<th scope="col">Buyer email</th>
											<td>{order.buyer.email}</td>
										</tr>
										<tr>
											<th scope="col">SHIP 2 Info</th>
											<td>{order.status_shipping}</td>
										</tr>
										<tr>
											<th scope="col">SHIP 2 Status</th>
											<td>
												{order.status === "PENDING" ? (
													<button className="cc-ship-btn" onClick={createBottleShipping}>
														Ship Now
													</button>
												) : (
													<div>{order.status_shipping}</div>
												)}
												{order.shipping_label && (
													<button className="cc-ship-btn" onClick={downloadBottleShippingLabel}>
														Download Label
													</button>
												)}
												{order.track_shipping_url && (
													<a className="cc-ship-btn" href={order.track_shipping_url} target="_blank" rel="noreferrer">
														Track Ship 2
													</a>
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</Col>
							<Col md={4}>
								<table className="table table-stripe mb-0">
									<tbody>
										<tr>
											<th scope="col">Product name</th>
											<td>{order.product?.name}</td>
										</tr>
										<tr>
											<th scope="col">Product price</th>
											<td>{numberToPriceString(order.price)}</td>
										</tr>
										<tr>
											<th scope="col">Buyer fee</th>
											<td>
												{numberToPriceString(order.buyer_fee)}
												{order.fee_detail?.buyer_fee.details &&
													Object.values(order.fee_detail?.buyer_fee.details).map((item) => (
														<div key={item.label}>
															<strong>{item.label}</strong> ({numberToPriceString(item.value)})
														</div>
													))}
											</td>
										</tr>
										<tr>
											<th scope="col">Buyer paid</th>
											<td>{numberToPriceString(order.buyer_paid)}</td>
										</tr>
										<tr>
											<th scope="col">Seller fee</th>
											<td>
												{numberToPriceString(order.seller_fee)}
												{order.fee_detail?.seller_fee.details &&
													Object.values(order.fee_detail?.seller_fee.details).map((item) => (
														<div key={item.label}>
															<strong>{item.label}</strong> ({numberToPriceString(item.value)})
														</div>
													))}
											</td>
										</tr>
										<tr>
											<th scope="col">Seller Discount</th>
											<td>
												{order.discount?.code} ({numberToPriceString(order.discount?.amount)})
											</td>
										</tr>
										<tr>
											<th scope="col">Seller Payout</th>
											<td>{numberToPriceString(parseFloat(order.seller_receivable) + parseFloat(order.discount?.amount ?? 0))}</td>
										</tr>
										<tr>
											<th scope="col">Total Profit</th>
											<td>{numberToPriceString(order.total_profit)}</td>
										</tr>
										<tr>
											<th scope="col">Paid amount to sellers ({numberToPriceString(order.payout_amount)})</th>
											<td>
												<button className="btn btn-success" disabled={!!order.payout_id} onClick={payoutNow}>
													Payout Now
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</Col>
						</Row>
					</Col>
				) : (
					<PageNotFound />
				)}
			</Container>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(AdminOrderDetailPage);
