import React from "react";
import { Link } from "react-router-dom";
import { getLang } from "../../helpers/languageHelper";
import "./admin.styles.scss";

const AdminProductCard = ({ product }) => (
	<div className="col-sm-6 col-xl-3 col-lg-4 admin-product-card">
		<div className="product product_tag-black product-hover-style-default product-hover-button-style-light product_title_type-single_line product_icon_type-line-icon product-box">
			<div className="product-inner element-hovered">
				<div className="product-thumbnail">
					<div className="product-thumbnail-inner">
						<Link to={`/admin/product/detail/${product.id}`}>
							{product.images[0] ? (
								<div className="product-thumbnail-main">
									<img src={product.images[0]} className="img-fluid" alt="product" />
								</div>
							) : null}
							{product.images[1] ? (
								<div className="product-thumbnail-swap">
									<img src={product.images[1]} className="img-fluid" alt="product-back" />
								</div>
							) : null}
						</Link>
					</div>
				</div>
				<div className="product-info">
					<h3 className="product-name">
						{product.properties?.top_pick && <span className="mark">eliqx picks</span>}
						<Link to={`/admin/product/detail/${product.id}`}>{product.name}</Link>
					</h3>
					<div className="detail-info-box">
						<div className="detail-row">
							<div className="detail-value">{getLang("size")}</div>
							<div className="detail-value">{product.size}</div>
						</div>
						<div className="detail-row">
							<div className="detail-value">{getLang("abv")}</div>
							<div className="detail-value">{product.abv}%</div>
						</div>
						<div className="detail-row">
							<div className="detail-value">{getLang("status")}</div>
							<div className="detail-value">{product.status}</div>
						</div>
					</div>
					<div className="edit-button">
						<Link to={`/admin/product/update/${product.id}`}>{getLang("update")}</Link>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default AdminProductCard;
