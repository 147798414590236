import * as ApiService from "../../services/ApiService";
import * as StorageService from "../../services/StorageService";
import { getToken } from "./auth.utils";

export const LOGOUT = "LOGOUT";
export const SET_PROFILE = "SET_PROFILE";

export const register = (formData) => {
	return async (dispatch, getState) => {
		try {
			let result = await ApiService.authRegister(formData);
			const token = result.access_token;
			result = await ApiService.authGetProfile(token);
			StorageService.saveToken(token);
			dispatch({
				type: SET_PROFILE,
				token: token,
				profile: result,
			});
		} catch (error) {
			StorageService.removeToken();
			throw error;
		}
	};
};

export const loadProfile = () => {
	return async (dispatch, getState) => {
		const token = getToken(getState().token);
		if (!token) {
			dispatch({
				type: LOGOUT,
			});
		} else {
			try {
				const result = await ApiService.authGetProfile(token);
				dispatch({
					type: SET_PROFILE,
					token: token,
					profile: result,
				});
			} catch (err) {
				StorageService.removeToken();
				dispatch({ type: LOGOUT });
			}
		}
	};
};

export const login = (email, password, more = {}) => {
	return async (dispatch, getState) => {
		try {
			let result = await ApiService.authLogin(email, password, more);
			const token = result.access_token;
			result = await ApiService.authGetProfile(token);
			StorageService.saveToken(token);
			dispatch({
				type: SET_PROFILE,
				token: token,
				profile: result,
			});
		} catch (err) {
			StorageService.removeToken();
			throw err;
		}
	};
};

export const logout = () => {
	return async (dispatch, getState) => {
		try {
			const token = getToken(getState().token);
			await ApiService.authLogout(token);
			StorageService.removeToken();
			dispatch({ type: LOGOUT });
		} catch (err) {
			StorageService.removeToken();
			dispatch({ type: LOGOUT });
			throw err;
		}
	};
};

export const updateProfile = (formData) => {
	return async (dispatch, getState) => {
		try {
			const token = getToken(getState().token);
			await ApiService.authUpdateProfile(token, formData);
			const result = await ApiService.authGetProfile(token);
			dispatch({
				type: SET_PROFILE,
				token: token,
				profile: result,
			});
		} catch (error) {
			throw error;
		}
	};
};

export const signWithToken = (token, code = null) => {
	return async (dispatch, getState) => {
		try {
			let result = await ApiService.authSignWithToken(token, code);
			const access_token = result.access_token;
			result = await ApiService.authGetProfile(access_token);
			StorageService.saveToken(access_token);
			dispatch({
				type: SET_PROFILE,
				token: access_token,
				profile: result,
			});
		} catch (err) {
			StorageService.removeToken();
			throw err;
		}
	};
};
