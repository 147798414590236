import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import "./buyer-profile.styles.scss";
import BuyerProfileMenu from "./buyer-profile-menu.component";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { authGetSettings, authSetSettings } from "../../../services/ApiService";
import { toast } from "react-toastify";
import { extractErrors } from "../../../helpers/commonHelper";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";

const buyerSettings = [
	{
		slug_email: "buyer_bid_expiring_soon_email_notification",
		slug_push: "buyer_bid_expiring_soon_push_notification",
		label: "Offer Expiring Soon",
		content: "Get notified 3 days before an offer is about to expire",
	},
	{
		slug_email: "buyer_listing_new_low_ask_email_notification",
		slug_push: "buyer_listing_new_low_ask_push_notification",
		label: "Listing New Low Ask",
		content: "Get notified when there is a new low ask on an item that you are bidding on or watching",
	},
	{
		slug_email: "buyer_listing_sold_email_notification",
		slug_push: "buyer_listing_sold_push_notification",
		label: "Listing Sold",
		content: "Get alerts when an item you are bidding on or watching is sold",
	},
	{
		slug_email: "buyer_marketing_email_notification",
		slug_push: "buyer_marketing_push_notification",
		label: "Marketing Notifications",
		content: "Get latest product listings and news for the eliqx exchange",
	},
	{
		slug_email: "buyer_portfolio_item_new_high_sell_price_email_notification",
		slug_push: "buyer_portfolio_item_new_high_sell_price_push_notification",
		label: "Portfolio Item New High Sell Price",
		content: "Get notified when an item you have purchased sells at a new high price",
	},
	{
		slug_email: "buyer_portfolio_item_new_low_sell_price_email_notification",
		slug_push: "buyer_portfolio_item_new_low_sell_price_push_notification",
		label: "Portfolio Item New Low Sell Price",
		content: "Get notified when an item you have purchased sells at a new low price",
	},
	{
		slug_email: "buyer_new_highest_bid_email_notification",
		slug_push: "buyer_new_highest_bid_push_notification",
		label: "New Highest Offer If Buyer Has Offer",
		content: "Get notified of all new highest OFFERs made on a bottle you have a live OFFER for",
	},
];

const BuyerAccountSettingsPage = ({ token }) => {
	const isIn = useRef();
	const [loading, setLoading] = useState(false);
	const [settings, setSettings] = useState();

	const loadSettings = useCallback(() => {
		setLoading(true);
		authGetSettings(token)
			.then((res) => {
				if (isIn.current) {
					setSettings(res);
					setLoading(false);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					toast.error(extractErrors(err)[0]);
					setLoading(false);
				}
			});
	}, [token]);

	const handleChangeSetting = (slug) => {
		authSetSettings(token, { [slug]: !settings[slug] })
			.then((res) => {
				if (isIn.current) {
					setSettings({ ...settings, [slug]: !settings[slug] });
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	};

	useLayoutEffect(() => {
		loadSettings();
	}, [loadSettings]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="buyer-settings-page">
			<div className="dashboard">
				<BuyerProfileMenu />
				<div className="dashboard-main">
					<div className="settings">
						<div className="page-title">
							<h2>SETTINGS</h2>
						</div>
						<div className="title">
							<h4>Notifications Manager</h4>
						</div>
						{loading || !settings ? (
							<div className="loading">
								<NormalLoading />
							</div>
						) : (
							<div className="nList">
								<div className="item">
									<div className="switchbutton">
										<p>EMAIL</p>
									</div>
									<div className="switchbutton">
										<p>PUSH</p>
									</div>
									<div className="details d-none"></div>
								</div>
								{buyerSettings.map((item, i) => (
									<div className="item" key={i}>
										<div className="switchbutton">
											<input
												type="checkbox"
												id={item.slug_email}
												checked={!!settings[item.slug_email]}
												onChange={handleChangeSetting.bind(this, item.slug_email)}
											/>
											<label htmlFor={item.slug_email}></label>
											<span></span>
										</div>
										<div className="switchbutton">
											<input
												type="checkbox"
												id={item.slug_push}
												checked={!!settings[item.slug_push]}
												onChange={handleChangeSetting.bind(this, item.slug_push)}
											/>
											<label htmlFor={item.slug_push}></label>
											<span></span>
										</div>
										<div className="details">
											<h5>{item.label}</h5>
											<p>{item.content}</p>
										</div>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(BuyerAccountSettingsPage);
