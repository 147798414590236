import React, { useCallback, useEffect, useRef, useState } from "react";
import logo_grey from "../../../assets/images/eliqx-logo-all-grey.svg";
import search_confirm from "../../../assets/images/eliqx-search-confirm-yellow.png";
import camera_icon from "../../../assets/images/eliqx-camera-icon-dk-blue.png";
import bottle_sample from "./bottle-sample.jpg";
import "./seller-make-product-listing-page.styles.scss";
import { createStructuredSelector } from "reselect";
import { getProfile, getToken } from "../../../redux/auth/auth.selector";
import { connect } from "react-redux";
import {
	buyerGetShippingInfos,
	buyerSaveShippingInfo,
	sellerCreateProduct,
	sellerRegisterToProduct,
	sellerRequestConnectBank,
	shopCalcTransactionFees,
	shopGetDiscountCode,
	shopGetProducts,
} from "../../../services/ApiService";
import { extractErrors, isMobile, numberToPriceString, strToFloat, strToPhoneNumberFormat, strToPhoneNumberString } from "../../../helpers/commonHelper";
import { toast } from "react-toastify";
import { loadProfile } from "../../../redux/auth/auth.action";
import { Link } from "react-router-dom";
import SellerCertifyBoxComponent from "../../../components/seller/seller-certify-box.component";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";

const storageItemKey = "seller_make_product_listing_product_id";

const productCloneProperties = [
	{ label: "Batch #", value: "batch" },
	{ label: "Lot #", value: "lot" },
	{ label: "Distillery", value: "distillery" },
	{ label: "Release", value: "year_release" },
];

const productTxtProperties = [
	{ label: "Batch #", value: "batch" },
	{ label: "Lot #", value: "lot" },
	{ label: "Distillery", value: "distillery" },
	{ label: "Release", value: "year_release" },
];

const initialProductForm = {
	name: "",
	size: "",
	abv: "",
	description: "",
	images: [null, null],
	has_box: null,
	has_tag: null,
	has_bag: null,
	has_tin: null,
	has_case: null,
	batch: "",
	lot: "",
	distillery: "",
	company: "",
	category: "",
	sub_category_1: "",
	classification: "",
	brand: "",
	collection: "",
	specification: "",
	year_release: "",
	aged: "",
	sku: "",
	weight: 1,
	width: "",
	length: "",
	height: "",
};

const initialListingForm = {
	price: "",
	discount_code: "",
	take_shipping_fee: true,
	expiration: 15,
};

const initialShippingForm = {
	first_name: "",
	last_name: "",
	phone_number: "",
	address_1: "",
	address_2: "",
	city: "",
	zip_code: "",
	special_instruction: "",
};

const ProductSearchBox = ({ keyword, setKeyword, searchNow }) => {
	const inputHandle = (event) => {
		setKeyword(event.target.value);
	};
	const submit = (event) => {
		event.preventDefault();
		searchNow();
	};

	return (
		<div className="search-bottle">
			<div className="logo">
				<img src={logo_grey} alt="logo_grey" />
			</div>
			<div className="content">
				<h2>List a bottle for sale</h2>
				<p>Start by searching for your bottle by Product Name or Scan Bar Code on your bottle.</p>
				<form onSubmit={submit}>
					<div className="input-wrap">
						<input type="text" placeholder="Bottle Name" value={keyword} onChange={inputHandle} />
						<button className="search-btn" type="submit">
							<img src={search_confirm} alt="eliqx-search-confirm-yellow.png" />
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

const ExistProductsBox = ({ products, selectProduct }) => (
	<>
		<div>
			<button className="suggest-button" onClick={() => selectProduct(null)}>
				SUGGEST NEW BOTTLE
			</button>
		</div>
		<p className="existing-text">Please select from existing products in our database</p>
		<div className="exist-products">
			{products.map((item) => (
				<div className="product" key={item.id} onClick={() => selectProduct(item)}>
					<h3 className="product-name">{item.name}</h3>
					<p>
						<span>{item.size}</span>
						<span>{item.abv}% ABV</span>
						{item.has_box !== null && <span>W/{item.has_box ? "" : "O"} Box</span>}
						{item.has_bag !== null && <span>W/{item.has_bag ? "" : "O"} Bag</span>}
						{item.has_tag !== null && <span>W/{item.has_tag ? "" : "O"} Tag</span>}
						{item.has_tin !== null && <span>W/{item.has_tin ? "" : "O"} Tin</span>}
						{item.has_case !== null && <span>W/{item.has_case ? "" : "O"} Case</span>}
						{item.lot && <span>Lot #{item.lot}</span>}
						{item.batch && <span>Batch #{item.batch}</span>}
						{item.aged && <span>{item.aged}</span>}
					</p>
				</div>
			))}
		</div>
	</>
);

const NewProductBox = ({ form, setForm }) => {
	const inputHandle = (event) => {
		let { name, value } = event.target;
		if (name === "abv") value = strToFloat(value);
		setForm({ ...form, [name]: value });
	};
	const imageChange = (i, event) => {
		try {
			const reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = (e) => {
				let temp = [...form.images];
				temp[i] = reader.result;
				setForm({ ...form, images: temp });
			};
		} catch (err) {
			toast.error("This image is not available to upload");
		}
	};

	return (
		<>
			{!isMobile() ? (
				<div className="upload-photo">
					<div className="front-photo">
						<div className="input">
							<input type="file" id="d-front-photo" onChange={imageChange.bind(this, 0)} />
							<label htmlFor="d-front-photo">UPLOAD PHOTO OF FRONT</label>
						</div>
						<div className="placeholder-img">
							<img src={form.images[0] ?? bottle_sample} alt="bottle_sample" />
						</div>
						<label className="retake" htmlFor="d-front-photo">
							RETAKE
						</label>
					</div>
					<div className="back-photo">
						<div className="input">
							<input type="file" id="d-back-photo" onChange={imageChange.bind(this, 1)} />
							<label htmlFor="d-back-photo">UPLOAD PHOTO OF BACK</label>
						</div>
						<div className="placeholder-img">
							<img src={form.images[1] ?? bottle_sample} alt="bottle_sample" />
						</div>
						<label className="retake" htmlFor="d-back-photo">
							RETAKE
						</label>
					</div>
				</div>
			) : (
				<div className="upload-photo-mobile">
					<div className="front-photo">
						<p>FRONT OF BOTTLE</p>
						<div className="input">
							<input type="file" id="m-front-photo" onChange={imageChange.bind(this, 0)} />
							<label htmlFor="m-front-photo">
								<img src={camera_icon} alt="camera-icon" />
							</label>
							<p>Use camera to take photos of bottle</p>
						</div>
						<div className="placeholder-img">
							<img src={form.images[0] ?? bottle_sample} alt="bottle_sample" />
						</div>
						<label className="retake" htmlFor="m-front-photo">
							RETAKE
						</label>
					</div>
					<div className="back-photo">
						<p>BACK OF BOTTLE</p>
						<div className="input">
							<input type="file" id="m-back-photo" onChange={imageChange.bind(this, 1)} />
							<label htmlFor="m-back-photo">
								<img src={camera_icon} alt="camera-icon" />
							</label>
							<p>Use camera to take photos of bottle</p>
						</div>
						<div className="placeholder-img">
							<img src={form.images[1] ?? bottle_sample} alt="bottle_sample" />
						</div>
						<label className="retake" htmlFor="m-back-photo">
							RETAKE
						</label>
					</div>
				</div>
			)}
			<input type="text" className="input-st1" placeholder="Product Name" value={form.name} name="name" onChange={inputHandle} />
			<input type="text" className="input-st1" placeholder="Size" value={form.size} name="size" onChange={inputHandle} />
			<input type="text" className="input-st1" placeholder="% ABV" name="abv" value={form.abv.length <= 0 ? "" : form.abv + "%"} onChange={inputHandle} />
			<div className="radio-wrap">
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio1"
						name="box"
						checked={form.has_box === 1}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_box: 1 })}
					/>
					<label className="form-radio-label" htmlFor="radio1">
						w/ box
					</label>
				</div>
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio2"
						name="box"
						checked={form.has_box === 0}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_box: 0 })}
					/>
					<label className="form-radio-label" htmlFor="radio2">
						w/o box
					</label>
				</div>
				<button className="btn btn-warning" onClick={() => setForm({ ...form, has_box: null })}>
					Reset Box
				</button>
				<br />
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio3"
						name="tag"
						checked={form.has_tag === 1}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_tag: 1 })}
					/>
					<label className="form-radio-label" htmlFor="radio3">
						w/ tag
					</label>
				</div>
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio4"
						name="tag"
						checked={form.has_tag === 0}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_tag: 0 })}
					/>
					<label className="form-radio-label" htmlFor="radio4">
						w/o tag
					</label>
				</div>
				<button className="btn btn-warning" onClick={() => setForm({ ...form, has_tag: null })}>
					Reset Tag
				</button>
				<br />
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio5"
						name="bag"
						checked={form.has_bag === 1}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_bag: 1 })}
					/>
					<label className="form-radio-label" htmlFor="radio5">
						w/ bag
					</label>
				</div>
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio6"
						name="bag"
						checked={form.has_bag === 0}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_bag: 0 })}
					/>
					<label className="form-radio-label" htmlFor="radio6">
						w/o bag
					</label>
				</div>
				<button className="btn btn-warning" onClick={() => setForm({ ...form, has_bag: null })}>
					Reset Bag
				</button>
				<br />
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio10"
						name="tin"
						checked={form.has_tin === 1}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_tin: 1 })}
					/>
					<label className="form-radio-label" htmlFor="radio10">
						w/ tin
					</label>
				</div>
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio11"
						name="tin"
						checked={form.has_tin === 0}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_tin: 0 })}
					/>
					<label className="form-radio-label" htmlFor="radio11">
						w/o tin
					</label>
				</div>
				<button className="btn btn-warning" onClick={() => setForm({ ...form, has_tin: null })}>
					Reset Tin
				</button>
				<br />
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio12"
						name="case"
						checked={form.has_case === 1}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_case: 1 })}
					/>
					<label className="form-radio-label" htmlFor="radio12">
						w/ case
					</label>
				</div>
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio13"
						name="case"
						checked={form.has_case === 0}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_case: 0 })}
					/>
					<label className="form-radio-label" htmlFor="radio13">
						w/o case
					</label>
				</div>
				<button className="btn btn-warning" onClick={() => setForm({ ...form, has_case: null })}>
					Reset Case
				</button>
				<br />
			</div>

			{productTxtProperties.map((item) => (
				<input
					type="text"
					className="input-st1"
					key={item.value}
					placeholder={item.label}
					name={item.value}
					value={form[item.value] ?? ""}
					onChange={inputHandle}
				/>
			))}
		</>
	);
};

const CloneProductBox = ({ form, setForm }) => {
	const inputHandle = (event) => {
		let { name, value } = event.target;
		if (name === "abv") value = strToFloat(value);
		setForm({ ...form, [name]: value });
	};

	return (
		<>
			<p className="rev-text">
				If this is your bottle please review the details below and if everything is accurate please enter a price you would like to sell for and click Submit
			</p>
			<div className="product">
				<h3 className="product-name">{form.name}</h3>
				<p>
					<span>{form.abv}% ABV</span>
					<span>{form.size}</span>
					{form.has_box !== null && <span>W/{form.has_box ? "" : "O"} Box</span>}
					{form.has_bag !== null && <span>W/{form.has_bag ? "" : "O"} Bag</span>}
					{form.has_tag !== null && <span>W/{form.has_tag ? "" : "O"} Tag</span>}
					{form.has_tin !== null && <span>W/{form.has_tin ? "" : "O"} Tin</span>}
					{form.has_case !== null && <span>W/{form.has_case ? "" : "O"} Case</span>}
					{form.lot && <span>Lot #{form.lot}</span>}
					{form.batch && <span>Batch #{form.batch}</span>}
					{form.aged && <span>{form.aged}</span>}
				</p>
			</div>
			{!isMobile() ? (
				<div className="upload-photo">
					<div className="front-photo">
						<div className="placeholder-img">
							<img src={form.images[0] ?? bottle_sample} alt="bottle_sample" />
						</div>
					</div>
					<div className="back-photo">
						<div className="placeholder-img">
							<img src={form.images[1] ?? bottle_sample} alt="bottle_sample" />
						</div>
					</div>
				</div>
			) : (
				<div className="upload-photo-mobile">
					<div className="front-photo">
						<div className="placeholder-img">
							<img src={form.images[0] ?? bottle_sample} alt="bottle_sample" />
						</div>
					</div>
					<div className="back-photo">
						<div className="placeholder-img">
							<img src={form.images[1] ?? bottle_sample} alt="bottle-sample" />
						</div>
					</div>
				</div>
			)}
			<input type="text" className="input-st1" value={form.name} readOnly />
			<input type="text" className="input-st1" placeholder="Size" name="size" value={form.size} readOnly />
			<input type="text" className="input-st1" placeholder="% ABV" name="abv" value={form.abv.length <= 0 ? "" : form.abv + "%"} onChange={inputHandle} />
			<div className="radio-wrap">
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio1"
						name="box"
						checked={form.has_box === 1}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_box: 1 })}
					/>
					<label className="form-radio-label" htmlFor="radio1">
						w/ box
					</label>
				</div>
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio2"
						name="box"
						checked={form.has_box === 0}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_box: 0 })}
					/>
					<label className="form-radio-label" htmlFor="radio2">
						w/o box
					</label>
				</div>
				<button className="btn btn-warning" onClick={() => setForm({ ...form, has_box: null })}>
					Reset Box
				</button>
				<br />
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio3"
						name="tag"
						checked={form.has_tag === 1}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_tag: 1 })}
					/>
					<label className="form-radio-label" htmlFor="radio3">
						w/ tag
					</label>
				</div>
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio4"
						name="tag"
						checked={form.has_tag === 0}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_tag: 0 })}
					/>
					<label className="form-radio-label" htmlFor="radio4">
						w/o tag
					</label>
				</div>
				<button className="btn btn-warning" onClick={() => setForm({ ...form, has_tag: null })}>
					Reset Tag
				</button>
				<br />
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio5"
						name="bag"
						checked={form.has_bag === 1}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_bag: 1 })}
					/>
					<label className="form-radio-label" htmlFor="radio5">
						w/ bag
					</label>
				</div>
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio6"
						name="bag"
						checked={form.has_bag === 0}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_bag: 0 })}
					/>
					<label className="form-radio-label" htmlFor="radio6">
						w/o bag
					</label>
				</div>
				<button className="btn btn-warning" onClick={() => setForm({ ...form, has_bag: null })}>
					Reset Bag
				</button>
				<br />
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio10"
						name="tin"
						checked={form.has_tin === 1}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_tin: 1 })}
					/>
					<label className="form-radio-label" htmlFor="radio10">
						w/ tin
					</label>
				</div>
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio11"
						name="tin"
						checked={form.has_tin === 0}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_tin: 0 })}
					/>
					<label className="form-radio-label" htmlFor="radio11">
						w/o tin
					</label>
				</div>
				<button className="btn btn-warning" onClick={() => setForm({ ...form, has_tin: null })}>
					Reset Tin
				</button>
				<br />
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio12"
						name="case"
						checked={form.has_case === 1}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_case: 1 })}
					/>
					<label className="form-radio-label" htmlFor="radio12">
						w/ case
					</label>
				</div>
				<div className="form-radio">
					<input
						className="form-radio-input"
						type="radio"
						id="radio13"
						name="case"
						checked={form.has_case === 0}
						onChange={() => {}}
						onClick={() => setForm({ ...form, has_case: 0 })}
					/>
					<label className="form-radio-label" htmlFor="radio13">
						w/o case
					</label>
				</div>
				<button className="btn btn-warning" onClick={() => setForm({ ...form, has_case: null })}>
					Reset Case
				</button>
			</div>
			{productCloneProperties.map((item) => (
				<input
					type="text"
					className="input-st1"
					key={item.value}
					placeholder={item.label}
					name={item.value}
					value={form[item.value] ?? ""}
					onChange={inputHandle}
				/>
			))}
		</>
	);
};

const ListingSubmitBox = ({ form, setForm, submitForm, bankConnection, token, reloadProfile, selectedShipping, setSelectedShipping, product }) => {
	const isIn = useRef();
	const timeout = useRef();
	const [loading, setLoading] = useState(false);
	const [openShipping, setOpenShipping] = useState(false);
	const [savedShipping, setSavedShipping] = useState([]);
	const [shippingForm, setShippingForm] = useState(initialShippingForm);
	const [mustCheck, setMustCheck] = useState(0);
	const [fees, setFees] = useState();
	const [discountPrice, setDiscountPrice] = useState();

	const submit = () => {
		if (mustCheck !== 7) {
			toast.error("You must agree with rules");
			return;
		}
		submitForm();
	};
	const inputPrice = (event) => {
		setForm({ ...form, price: event.target.value });
	};
	const inputDiscountCode = (event) => {
		setForm({ ...form, discount_code: event.target.value });
	};
	const setExpiration = (event) => {
		setForm({ ...form, expiration: event.target.value });
	};
	const shippingInputHandle = (event) => {
		let { name, value } = event.target;
		if (name === "phone_number") value = strToPhoneNumberString(value);
		else if (name === "special_instruction") value = value.substr(0, 100);
		setShippingForm({ ...shippingForm, [name]: value });
	};
	const connectBank = async (event) => {
		if (bankConnection) return;
		event.preventDefault();
		try {
			if (!isIn.current) return;
			setLoading(true);
			let result = await sellerRequestConnectBank(token);
			const newWindow = window.open(result.url, "_blank");
			const timer = setInterval(() => {
				if (!newWindow.closed) return;
				clearInterval(timer);
				reloadProfile()
					.then(() => {
						toast.success("Successfully submitted");
						setLoading(false);
					})
					.catch((err) => {
						if (isIn.current) {
							setLoading(false);
							toast.error(extractErrors(err)[0]);
						}
					});
			}, 500);
		} catch (error) {
			if (isIn.current) {
				setLoading(false);
				toast.error(extractErrors(error)[0]);
			}
		}
	};
	const submitShipping = () => {
		setLoading(true);
		buyerSaveShippingInfo(token, shippingForm)
			.then((res) => {
				if (!isIn.current) return;
				setSelectedShipping(res);
				setSavedShipping([...savedShipping, res]);
				setShippingForm(initialShippingForm);
				setOpenShipping(false);
				setLoading(false);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
				setLoading(false);
			});
	};
	const selectShipping = (item) => {
		setSelectedShipping(item);
		setOpenShipping(false);
	};
	const loadSavedShippingInfos = useCallback(() => {
		setSavedShipping(null);
		buyerGetShippingInfos(token)
			.then((res) => {
				if (!isIn.current) return;
				setSavedShipping(res);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	}, [token]);

	useEffect(() => {
		if (timeout.current) clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			const price = isNaN(parseFloat(form.price)) ? 0 : parseFloat(form.price);
			shopCalcTransactionFees("SELLER", { ...form, price, type: "BUY" })
				.then((res) => {
					if (isIn.current) setFees(res);
				})
				.catch((err) => {
					if (isIn.current) toast.error(extractErrors(err)[0]);
				});
		}, 3000);
	}, [form]);

	useEffect(() => {
		const { discount_code, price } = form;
		if (!discount_code || discount_code.trim().length <= 0 || price.toString().trim().length <= 0) {
			setDiscountPrice(null);
			return;
		}
		shopGetDiscountCode(discount_code, price)
			.then((res) => {
				setDiscountPrice(res);
			})
			.catch((err) => {
				setDiscountPrice(null);
			});
	}, [form]);

	useEffect(() => {
		loadSavedShippingInfos();
	}, [loadSavedShippingInfos]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	if (loading || !savedShipping) {
		return (
			<div className="loading">
				<NormalLoading />
			</div>
		);
	}
	return (
		<>
			<h3 className="sell-queston">How much would you like to sell your bottle for?</h3>
			<div className="askAsdDiscount">
				<input type="text" className="input-st2" placeholder="ENTER ASK PRICE" value={form.price} onChange={inputPrice} />
				<input type="text" className="input-st2" placeholder="DISCOUNT CODE" value={form.discount_code} autoComplete="off" onChange={inputDiscountCode} />
			</div>
			{product?.highest_bid?.price && form.price <= product?.highest_bid?.price ? (
				<>
					<p className="offers_explain">
						There is a current offer of ${numberToPriceString(product?.highest_bid?.price)} on this item please click
						<Link className="sell-now" to={`/seller/list-sell/${product.id}`}>
							Sell Now
						</Link>
						to complete this Sell.
					</p>
					<Link className="sell-now-btn" to={`/seller/list-sell/${product.id}`}>
						Sell Now
					</Link>
				</>
			) : (
				<>
					<table>
						<tbody>
							<tr>
								<td>{fees?.details.listing.label}</td>
								<td>-${numberToPriceString(fees?.details.listing.value)}</td>
							</tr>
							<tr>
								<td>{fees?.details.processing.label}</td>
								<td>-${numberToPriceString(fees?.details.processing.value)}</td>
							</tr>
							<tr>
								<td>{fees?.details.transaction.label}</td>
								<td>-${numberToPriceString(fees?.details.transaction.value)}</td>
							</tr>
							<tr>
								<td>{fees?.details.shipping.label}</td>
								<td>-${numberToPriceString(fees?.details.shipping.value)}</td>
							</tr>
							<tr>
								<td>Discount Applied</td>
								<td>${numberToPriceString(discountPrice?.result.value)}</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td>Total Payout</td>
								<td>
									${numberToPriceString((isNaN(parseFloat(form.price)) ? 0 : parseFloat(form.price)) - (fees?.sum ?? 0) + (discountPrice?.result.value ?? 0))}
								</td>
							</tr>
						</tfoot>
					</table>
					<div className="info">
						{!bankConnection && (
							<button className="collapsbtn sellerInfo" onClick={connectBank}>
								Add Payout Info
							</button>
						)}
						<div className="shippingInfo">
							{savedShipping.length > 0 && (
								<div className={`choosePaymentMethod collapse-content ${openShipping ? "show" : ""}`}>
									<h4>Choose Shipping Address </h4>
									{savedShipping.map((x) => (
										<p key={x.id} className={selectedShipping?.id === x.id ? "selected_item" : ""} onClick={selectShipping.bind(this, x)}>
											{x.first_name ?? ""} {x.last_name ?? ""} {x.address_1 ?? ""} {x.address_2 ?? ""} {x.city ?? ""} {x.zip_code ?? ""}
											{strToPhoneNumberFormat(x.phone_number)}
										</p>
									))}
								</div>
							)}
							<button className="collapsbtn sellerInfo" onClick={() => setOpenShipping(true)}>
								{!openShipping && selectedShipping
									? `${selectedShipping.first_name ?? ""} ${selectedShipping.last_name ?? ""} ${strToPhoneNumberFormat(selectedShipping.phone_number)} ${
											selectedShipping.address_1 ?? ""
									  } ${selectedShipping.address_2 ?? ""} ${selectedShipping.city ?? ""} ${selectedShipping.zip_code ?? ""}`
									: "Ship From Address"}
							</button>
							<div className={`collapse-content ${openShipping ? "show" : ""}`}>
								<div className="row px-2 py-3">
									<div className="col-md-6">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="First Name"
											name="first_name"
											value={shippingForm.first_name}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-md-6">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="Last Name"
											name="last_name"
											value={shippingForm.last_name}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-12">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="Street Address"
											name="address_1"
											value={shippingForm.address_1}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-12">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="Street Address Second (optional)"
											name="address_2"
											value={shippingForm.address_2}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-md-5">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="City"
											name="city"
											value={shippingForm.city}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-md-3 col-6">
										<input type="text" className="payment_input form-control" placeholder="State" value="CA" disabled />
									</div>
									<div className="col-md-4 col-6">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="Zip Code"
											name="zip_code"
											value={shippingForm.zip_code}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-md-12">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="Special instructions 100 character limit"
											name="special_instruction"
											value={shippingForm.special_instruction}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-md-12">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="Phone number"
											name="phone_number"
											value={strToPhoneNumberFormat(shippingForm.phone_number)}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
								</div>
								<button className="confirmBtn" onClick={submitShipping}>
									confirm
								</button>
							</div>
						</div>
						<div className="selectDays">
							<select className="customSelect" value={form.expiration} onChange={setExpiration}>
								<option value="15">List For 15 days</option>
								<option value="30">List For 30 days</option>
							</select>
						</div>
					</div>
					<div className="checkBoxs">
						<SellerCertifyBoxComponent value={mustCheck} setValue={setMustCheck} />
					</div>
					<button type="submit" onClick={submit}>
						SUBMIT
					</button>
				</>
			)}
		</>
	);
};

const SellerMakeProductListingPage = ({ token, profile, reloadProfile, history }) => {
	const isIn = useRef();
	const [searched, setSearched] = useState(false);
	const [keyword, setKeyword] = useState("");
	const [existingProducts, setExistingProducts] = useState();
	const [selectedProduct, setSelectedProduct] = useState();
	const [loading, setLoading] = useState(false);
	const [productForm, setProductForm] = useState(initialProductForm);
	const [listingForm, setListingForm] = useState(initialListingForm);
	const [selectedShipping, setSelectedShipping] = useState();
	const listingBox = useRef();

	const searchProductByName = () => {
		setLoading(true);
		setSelectedProduct(null);
		shopGetProducts({ keyword }, 100, 1, token)
			.then((res) => {
				if (isIn.current) {
					setExistingProducts(res.data);
					setLoading(false);
					setSearched(true);
					setListingForm(initialListingForm);
					setProductForm(initialProductForm);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	};
	const selectExistingProduct = (product) => {
		listingBox.current.scrollIntoView({ behavior: "smooth" });
		if (!product) {
			setProductForm(initialProductForm);
			setSelectedProduct(null);
			return;
		}
		setSelectedProduct(product);
		let images = product.images;
		while (images.length < 2) images.push(null);
		delete product.properties;
		setProductForm({ ...productForm, ...product, images });
	};
	const submitForm = async () => {
		if (!selectedShipping) {
			toast.error("Please input shipping from information");
			return;
		}
		setLoading(true);

		const submitNow = (link = "/seller/profile-portfolio") => {
			const form = { ...listingForm, shipping_id: selectedShipping.id };
			sellerRegisterToProduct(token, localStorage.getItem(storageItemKey), form)
				.then((res) => {
					if (isIn.current) {
						localStorage.removeItem(storageItemKey);
						toast.success("Successfully submitted");
						history.push(link);
					}
				})
				.catch((err) => {
					if (isIn.current) {
						toast.error(extractErrors(err)[0]);
						setLoading(false);
					}
				});
		};

		if (selectedProduct) {
			let isSame = true;
			for (let prop of productCloneProperties) {
				if (!isSame) break;
				const j = prop.value;
				if (productForm[j] !== selectedProduct[j]) isSame = false;
			}
			isSame &= productForm.has_box === selectedProduct.has_box;
			isSame &= productForm.has_tag === selectedProduct.has_tag;
			isSame &= productForm.has_bag === selectedProduct.has_bag;
			isSame &= productForm.has_tin === selectedProduct.has_tin;
			isSame &= productForm.has_case === selectedProduct.has_case;
			if (isSame) {
				localStorage.setItem(storageItemKey, selectedProduct.id);
			}
		}

		if (localStorage.getItem(storageItemKey)) {
			submitNow();
		} else {
			sellerCreateProduct(token, productForm)
				.then((res) => {
					localStorage.setItem(storageItemKey, res.id);
					submitNow("/seller/profile-portfolio?tab=PENDING_LISTINGS");
				})
				.catch((err) => {
					if (isIn.current) {
						toast.error(extractErrors(err)[0]);
						setLoading(false);
					}
				});
		}
	};

	useEffect(() => {
		isIn.current = true;

		return () => {
			localStorage.removeItem(storageItemKey);
			isIn.current = false;
		};
	}, []);

	return (
		<div className="seller-make-product-listing-page">
			<div className="selllerSuggest bottleSearchList">
				{loading ? (
					<div className="loading">
						<NormalLoading />
					</div>
				) : (
					<div className="wrapper">
						<ProductSearchBox keyword={keyword} setKeyword={setKeyword} searchNow={searchProductByName} />
						{searched && (
							<>
								{existingProducts && existingProducts.length > 0 ? (
									<div className="search-result">
										<div className="with-exist-product">
											<ExistProductsBox products={existingProducts} selectProduct={selectExistingProduct} />
											<div
												className="exist-product-details"
												ref={(el) => {
													listingBox.current = el;
												}}
											>
												{selectedProduct ? (
													<CloneProductBox form={productForm} setForm={setProductForm} />
												) : (
													<NewProductBox form={productForm} setForm={setProductForm} />
												)}
												<ListingSubmitBox
													token={token}
													reloadProfile={reloadProfile}
													form={listingForm}
													setForm={setListingForm}
													selectedShipping={selectedShipping}
													setSelectedShipping={setSelectedShipping}
													submitForm={submitForm}
													bankConnection={!!profile?.seller_profile?.stripe_account?.payouts_enabled}
													product={selectedProduct}
												/>
											</div>
										</div>
									</div>
								) : (
									<div className="search-result">
										<div className="with-noexist-product">
											<p className="existing-text">
												This bottle does not exist in our system. Please upload a photo of your item, including packaging if available.
											</p>
											<div className="noexist-product-details">
												<NewProductBox form={productForm} setForm={setProductForm} />
												<ListingSubmitBox
													token={token}
													reloadProfile={reloadProfile}
													form={listingForm}
													setForm={setListingForm}
													selectedShipping={selectedShipping}
													setSelectedShipping={setSelectedShipping}
													submitForm={submitForm}
													bankConnection={!!profile?.seller_profile?.stripe_account?.payouts_enabled}
												/>
											</div>
										</div>
									</div>
								)}
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
	profile: getProfile,
});

const mapDispatchToProps = (dispatch) => ({
	reloadProfile: () => dispatch(loadProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerMakeProductListingPage);
