import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import SellerCertifyBoxComponent from "../../../components/seller/seller-certify-box.component";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";
import { extractErrors, numberToPriceString, strToPhoneNumberFormat, strToPhoneNumberString } from "../../../helpers/commonHelper";
import * as AuthAction from "../../../redux/auth/auth.action";
import { getProfile, getToken } from "../../../redux/auth/auth.selector";
import {
	buyerGetShippingInfos,
	buyerSaveShippingInfo,
	sellerGetMyListingById,
	sellerRegisterToProduct,
	sellerRequestConnectBank,
	sellerUpdateMyListing,
	shopCalcTransactionFees,
	shopGetDiscountCode,
	shopGetProductDetail,
} from "../../../services/ApiService";
import "./seller-list-product.styles.scss";
import SellerListingConfirmPage from "./seller-listing-confirm-page.component";

const initialShippingForm = {
	first_name: "",
	last_name: "",
	phone_number: "",
	address_1: "",
	address_2: "",
	city: "",
	zip_code: "",
	special_instruction: "",
};

const initialListingForm = {
	price: "",
	discount_code: "",
	take_shipping_fee: true,
	expiration: 15,
};

const SellerListProductPage = ({ token, match, location, profile, loadProfile }) => {
	const isIn = useRef();
	const timeout = useRef();
	const productId = match.params.productId;
	const listingId = new URLSearchParams(location.search).get("listing");
	const [product, setProduct] = useState();
	const [loading, setLoading] = useState(false);
	const [selectedShipping, setSelectedShipping] = useState();
	const [openShipping, setOpenShipping] = useState(false);
	const [savedShipping, setSavedShipping] = useState([]);
	const [shippingForm, setShippingForm] = useState(initialShippingForm);
	const [listingForm, setListingForm] = useState(initialListingForm);
	const [mustCheck, setMustCheck] = useState(0);
	const [fees, setFees] = useState();
	const [discountPrice, setDiscountPrice] = useState();

	const inputPrice = (event) => {
		setListingForm({ ...listingForm, price: event.target.value });
	};
	const inputDiscountCode = (event) => {
		setListingForm({ ...listingForm, discount_code: event.target.value });
	};
	const setExpiration = (event) => {
		setListingForm({ ...listingForm, expiration: event.target.value });
	};
	const shippingInputHandle = (event) => {
		let { name, value } = event.target;
		if (name === "phone_number") value = strToPhoneNumberString(value);
		else if (name === "special_instruction") value = value.substr(0, 100);
		setShippingForm({ ...shippingForm, [name]: value });
	};
	const payoutBankIsConnected = () => {
		return !!profile?.seller_profile?.stripe_account?.payouts_enabled;
	};
	const connectBank = async (event) => {
		event.preventDefault();
		setLoading(true);
		try {
			if (!isIn.current) return;
			let result = await sellerRequestConnectBank(token);
			const newWindow = window.open(result.url, "_blank");
			const timer = setInterval(() => {
				if (!newWindow.closed) return;
				clearInterval(timer);
				loadProfile()
					.then(() => {
						if (isIn.current) setLoading(false);
					})
					.catch((err) => {
						if (isIn.current) {
							toast.error(extractErrors(err)[0]);
							setLoading(false);
						}
					});
			}, 500);
		} catch (error) {
			if (isIn.current) {
				setLoading(false);
				toast.error(extractErrors(error)[0]);
			}
		}
	};

	const loadMyListing = useCallback(() => {
		if (!listingId) {
			setLoading(false);
			return;
		}
		setLoading(true);
		sellerGetMyListingById(token, listingId)
			.then((res) => {
				if (!isIn.current) return;
				if (res) {
					setListingForm({ expiration: 15, price: res.price, discount_code: res.discount_code, take_shipping_fee: res.take_shipping_fee });
					setSelectedShipping(res.shipping);
				}
				setLoading(false);
			})
			.catch((err) => {
				if (!isIn.current) return;
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	}, [token, listingId]);

	const loadProduct = useCallback(() => {
		setLoading(true);
		shopGetProductDetail(productId, token)
			.then((res) => {
				if (!isIn.current) return;
				setProduct(res);
				loadMyListing();
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	}, [productId, token, loadMyListing]);

	const loadSavedShippingInfos = useCallback(() => {
		setSavedShipping(null);
		buyerGetShippingInfos(token)
			.then((res) => {
				if (!isIn.current) return;
				setSavedShipping(res);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	}, [token]);

	const submitShipping = () => {
		setLoading(true);
		buyerSaveShippingInfo(token, shippingForm)
			.then((res) => {
				if (!isIn.current) return;
				setSelectedShipping(res);
				setSavedShipping([...savedShipping, res]);
				setShippingForm(initialShippingForm);
				setOpenShipping(false);
				setLoading(false);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
				setLoading(false);
			});
	};
	const selectShipping = (item) => {
		setSelectedShipping(item);
		setOpenShipping(false);
	};

	const submitListing = () => {
		if (mustCheck !== 7) {
			toast.warning("You must agree with our terms and service policy");
			return;
		}
		if (!selectedShipping) {
			toast.warning("Missed shipping from information");
			return;
		}
		const form = { ...listingForm, shipping_id: selectedShipping.id, status: "APPROVED" };
		setLoading(true);
		if (listingId) {
			sellerUpdateMyListing(token, listingId, form)
				.then((res) => {
					localStorage.setItem(`listing_confirmed_${productId}`, JSON.stringify(res));
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				});
		} else {
			sellerRegisterToProduct(token, productId, form)
				.then((res) => {
					localStorage.setItem(`listing_confirmed_${productId}`, JSON.stringify(res));
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				});
		}
	};

	useEffect(() => {
		if (timeout.current) clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			const price = isNaN(parseFloat(listingForm.price)) ? 0 : parseFloat(listingForm.price);
			shopCalcTransactionFees("SELLER", { ...listingForm, price, type: "BUY" })
				.then((res) => {
					if (isIn.current) setFees(res);
				})
				.catch((err) => {
					if (isIn.current) toast.error(extractErrors(err)[0]);
				});
		}, 3000);
	}, [listingForm]);

	useEffect(() => {
		const { discount_code, price } = listingForm;
		if (!discount_code || discount_code.trim().length <= 0 || price.toString().trim().length <= 0) {
			setDiscountPrice(null);
			return;
		}
		shopGetDiscountCode(discount_code, price)
			.then((res) => {
				setDiscountPrice(res);
			})
			.catch((err) => {
				setDiscountPrice(null);
			});
	}, [listingForm]);

	useLayoutEffect(() => {
		loadProduct();
		loadSavedShippingInfos();
	}, [loadProduct, loadSavedShippingInfos]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	if (!product || loading || !savedShipping) {
		return (
			<div className="buyer-place-order-page">
				<div className="container">
					{loading ? (
						<div className="loading">
							<NormalLoading />
						</div>
					) : (
						<h1>Product does not exist</h1>
					)}
				</div>
			</div>
		);
	}
	if (product && localStorage.getItem(`listing_confirmed_${productId}`)) {
		return <SellerListingConfirmPage product={product} />;
	}
	return (
		<div className="seller-list-product-page">
			<div className="container ">
				<div className="bidScreenContainer">
					<div className="buyNow">
						<h2>{product.name}</h2>
						<ul className="details">
							<li>{product.size}</li>
							<li>{product.abv}% ABV</li>
							{product.has_box !== null && <li>{product.has_box ? "With" : "Without"} BOX</li>}
							{product.has_tag !== null && <li>{product.has_tag ? "With" : "Without"} TAG</li>}
							{product.has_bag !== null && <li>{product.has_bag ? "With" : "Without"} BAG</li>}
							{product.has_tin !== null && <li>{product.has_tin ? "With" : "Without"} TIN</li>}
							{product.has_case !== null && <li>{product.has_case ? "With" : "Without"} CASE</li>}
						</ul>
						<div className="product_img">
							<img src={product.images[0]} alt={product.id} />
						</div>
					</div>
					<div className="placeBid">
						<input className="bidHeading" onClick={inputPrice} placeholder="ENTER LIST PRICE" value={listingForm.price} onChange={inputPrice} />
						<div className="discount">
							<input
								className="discount-code form-condivol"
								type="text"
								placeholder="DISCOUNT CODE"
								autoComplete="off"
								value={listingForm.discount_code ?? ""}
								onChange={inputDiscountCode}
							/>
						</div>
						<div className="invoiceTable">
							{fees && (
								<table className="table table-borderless">
									<tbody>
										<tr>
											<td>{fees.details.listing.label}</td>
											<td>-${numberToPriceString(fees.details.listing.value)}</td>
										</tr>
										<tr>
											<td>{fees.details.processing.label}</td>
											<td>-${numberToPriceString(fees.details.processing.value)}</td>
										</tr>
										<tr>
											<td>{fees.details.transaction.label}</td>
											<td>-${numberToPriceString(fees.details.transaction.value)}</td>
										</tr>
										<tr>
											<td>{fees.details.shipping.label}</td>
											<td>-${numberToPriceString(fees.details.shipping.value)}</td>
										</tr>
										<tr>
											<td>DISCOUNT APPLIED</td>
											<td>${numberToPriceString(discountPrice?.result.value)}</td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<th>TOTAL PAYOUT</th>
											<th>
												$
												{numberToPriceString(
													(isNaN(parseFloat(listingForm.price)) ? 0 : parseFloat(listingForm.price)) - fees.sum + (discountPrice?.result.value ?? 0)
												)}
											</th>
										</tr>
									</tfoot>
								</table>
							)}
						</div>
						<div className="shippingInfo">
							{!payoutBankIsConnected() && (
								<button className="collapsbtn" onClick={connectBank}>
									Add Payout Info
								</button>
							)}
						</div>
						<div className="shippingInfo">
							{savedShipping.length > 0 && (
								<div className={`choosePaymentMethod collapse-content ${openShipping ? "show" : ""}`}>
									<h4>Choose Shipping Address </h4>
									{savedShipping.map((x) => (
										<p key={x.id} className={selectedShipping?.id === x.id ? "selected_item" : ""} onClick={selectShipping.bind(this, x)}>
											{x.first_name ?? ""} {x.last_name ?? ""} {x.address_1 ?? ""} {x.address_2 ?? ""} {x.city ?? ""} {x.zip_code ?? ""}
											{strToPhoneNumberFormat(x.phone_number)}
										</p>
									))}
								</div>
							)}
							<button className="collapsbtn" onClick={() => setOpenShipping(true)}>
								{!openShipping && selectedShipping
									? `${selectedShipping.first_name ?? ""} ${selectedShipping.last_name ?? ""} ${strToPhoneNumberFormat(selectedShipping.phone_number)} ${
											selectedShipping.address_1 ?? ""
									  } ${selectedShipping.address_2 ?? ""} ${selectedShipping.city ?? ""} ${selectedShipping.zip_code ?? ""}`
									: "Ship From Address"}
							</button>
							<div className={`collapse-content ${openShipping ? "show" : ""}`}>
								<div className="row px-2 py-3">
									<div className="col-md-6">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="First Name"
											name="first_name"
											value={shippingForm.first_name}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-md-6">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="Last Name"
											name="last_name"
											value={shippingForm.last_name}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-12">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="Street Address"
											name="address_1"
											value={shippingForm.address_1}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-12">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="Street Address Second (optional)"
											name="address_2"
											value={shippingForm.address_2}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-md-5">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="City"
											name="city"
											value={shippingForm.city}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-md-3 col-6">
										<input type="text" className="payment_input form-control" placeholder="State" value="CA" disabled />
									</div>
									<div className="col-md-4 col-6">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="Zip Code"
											name="zip_code"
											value={shippingForm.zip_code}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-md-12">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="Special instructions 100 character limit"
											name="special_instruction"
											value={shippingForm.special_instruction}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
									<div className="col-md-12">
										<input
											type="text"
											className="payment_input form-control"
											placeholder="Phone number"
											name="phone_number"
											value={strToPhoneNumberFormat(shippingForm.phone_number)}
											onChange={shippingInputHandle.bind(this)}
										/>
									</div>
								</div>
								<button className="confirmBtn" onClick={submitShipping}>
									confirm
								</button>
							</div>
						</div>
						<div className="selectDays">
							<select className="customSelect" value={listingForm.expiration} onChange={setExpiration}>
								<option value="15">Offer Good For 15 days</option>
								<option value="30">Offer Good For 30 days</option>
							</select>
						</div>
						<div className="checkGroup">
							<SellerCertifyBoxComponent value={mustCheck} setValue={setMustCheck} />
						</div>
						<div className="btnWrapper ">
							<button className="listBtn" onClick={submitListing}>
								LIST NOW
							</button>
						</div>
						<div className="btnWrapper ">
							<Link className="listBtn" to={`/seller/remove-listing/${listingId}`}>
								CANCEL LISTING
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
	profile: getProfile,
});

const mapDispatchToProps = (dispatch) => ({
	loadProfile: () => dispatch(AuthAction.loadProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerListProductPage);
