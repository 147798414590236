import { LOGOUT, SET_PROFILE } from "./auth.action";

const INITIAL_STATE = {
	token: null,
	name: null,
	email: null,
	phone_number: null,
	role: null,
	status: null,
	shipping_address: null,
	city: null,
	zip_code: null,
	seller_profile: null,
	isActivedSeller: false,
};

export const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_PROFILE:
			return {
				...state,
				name: action.profile.name,
				email: action.profile.email,
				phone_number: action.profile.phone_number,
				token: action.token,
				role: action.profile.role,
				status: action.profile.status,
				shipping_address: action.profile.shipping_address,
				city: action.profile.city,
				zip_code: action.profile.zip_code,
				seller_profile: action.profile.seller_profile,
				isActivedSeller: action.profile.seller_profile?.status === "APPROVED",
			};

		case LOGOUT:
			return INITIAL_STATE;

		default:
			return state;
	}
};

export default authReducer;
