import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Container } from "reactstrap";
import { createStructuredSelector } from "reselect";
import NormalLoading from "../../components/ui-kits/normal-loading.component";
import { extractErrors, numberToPriceString } from "../../helpers/commonHelper";
import { getLang } from "../../helpers/languageHelper";
import { getToken } from "../../redux/auth/auth.selector";
import { adminGetBuyerOrders } from "../../services/ApiService";
import "./admin.styles.scss";

const AdminOrdersPage = ({ token, history }) => {
	const isIn = useRef();
	const [orders, setOrders] = useState();
	const [loading, setLoading] = useState(false);
	const [sortDir, setSortDir] = useState({ status: false, date: false });

	const loadData = useCallback(() => {
		setLoading(true);
		adminGetBuyerOrders(token)
			.then((res) => {
				if (!isIn.current) return;
				setLoading(false);
				setOrders(res);
			})
			.catch((err) => {
				if (!isIn.current) return;
				setLoading(false);
				toast.error(getLang(extractErrors(err)[0]));
			});
	}, [token]);

	const goToOrderDetail = (id) => {
		history.push(`/admin/order/detail/${id}`);
	};
	const sortOrdersBy = (key) => {
		const temp = [...orders];
		const dir = sortDir[key];
		setSortDir({ ...sortDir, [key]: !dir });
		temp.sort((a, b) => (a[key] < b[key] ? (dir ? -1 : 1) : dir ? 1 : -1));
		setOrders(temp);
	};

	useEffect(() => {
		isIn.current = true;
		loadData();

		return () => {
			isIn.current = false;
		};
	}, [loadData]);

	return (
		<div className="admin-orders-page">
			<Container>
				<h3 className="subtitle">{getLang("orders_list")}</h3>
				{loading || !orders ? (
					<div className="loading">
						<NormalLoading />
					</div>
				) : (
					<>
						<table className="table table-striped mb-0 orders-table">
							<thead>
								<tr>
									<th scope="col">{getLang("buyer_name")}</th>
									<th scope="col">{getLang("buyer_email")}</th>
									<th scope="col">{getLang("product_name")}</th>
									<th scope="col">{getLang("seller_name")}</th>
									<th scope="col">{getLang("seller_email")}</th>
									<th scope="col">{getLang("price")}</th>
									<th scope="col">SHIP 1</th>
									<th scope="col" className="status" onClick={sortOrdersBy.bind(this, "status")}>
										SHIP 2
									</th>
									<th scope="col" className="date" onClick={sortOrdersBy.bind(this, "created_at")}>
										{getLang("date")}
									</th>
								</tr>
							</thead>
							<tbody>
								{orders.map((item) => (
									<tr key={item.id} onClick={goToOrderDetail.bind(this, item.id)}>
										<td>{item.buyer.name}</td>
										<td>{item.buyer.email}</td>
										<td>{item.product?.name}</td>
										<td>{item.seller.name}</td>
										<td>{item.seller.email}</td>
										<td>{numberToPriceString(item.price)}</td>
										<td>{item.admin_shipping_status ? "Delivered" : item.admin_shipping_label ? "On Shipping" : "Pending"}</td>
										<td>{item.status_shipping}</td>
										<td>{item.created_at}</td>
									</tr>
								))}
							</tbody>
						</table>
					</>
				)}
			</Container>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(AdminOrdersPage);
