import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../../redux/auth/auth.selector";
import * as ApiService from "../../../services/ApiService";
import { connect } from "react-redux";
import { getLang } from "../../../helpers/languageHelper";
import { extractErrors, numberToPriceString } from "../../../helpers/commonHelper";
import "./product-detail.styles.scss";
import add_portfolio_plus_icon from "../../../assets/images/add-portfolio-plus-icon.svg";
import remove_portfolio_minus_icon from "../../../assets/images/remove-portfolio-minus-icon.svg";
import red_down_arrow from "../../../assets/images/red-down-arrow.svg";
import green_up_arrow from "../../../assets/images/green-up-arrow.svg";
import { Link } from "react-router-dom";
import PageNotFound from "../../page-not-found.component";
import ProductRelativeProducts from "../../../components/shop/product-relative-products.component";
import ProductHistoryGraph from "./product-history-graph.component";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import greyLeftArrow from "../../../assets/images/eliqx-grey-left-arrow.png";
import greyRightArrow from "../../../assets/images/eliqx-grey-right-arrow.png";
import whiteLogo from "../../../assets/images/logo-white-with-tagline.png";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";

const middleDetails = [
	{
		label: "DISTILLERY",
		value: "distillery",
	},
	{
		label: "COMPANY",
		value: "company",
	},
	{
		label: "CATEGORY",
		value: "category",
	},
	{
		label: "SUB CATEGORY",
		value: "sub_category_1",
	},
	{
		label: "CLASSIFICATION",
		value: "classification",
	},
	{
		label: "BRAND",
		value: "brand",
	},
	{
		label: "COLLECTION",
		value: "collection",
	},
	{
		label: "SPECIFICATION",
		value: "specification",
	},
	{
		label: "RELEASE",
		value: "year_release",
	},
	{
		label: "AGED",
		value: "aged",
	},
];

const ProductDetailPage = ({ token, match }) => {
	const isIn = useRef();
	const productId = match.params.productId;
	const [product, setProduct] = useState();
	const [loading, setLoading] = useState(false);
	const [lowestSellerProduct, setLowestSellerProduct] = useState();
	const [highestBid, setHighestBid] = useState();
	const [lastSold, setLastSold] = useState();
	const [chartData, setChartData] = useState();
	const graphHistoryDiv = useRef();
	const slider = useRef();
	const [chartSlug, setChartSlug] = useState("bids");

	const loadProduct = useCallback(() => {
		setLoading(true);
		ApiService.shopGetProductDetail(productId, token, "WEEKLY")
			.then((res) => {
				if (isIn.current) {
					setLoading(false);
					if (res.seller_products && res.seller_products.length > 0) {
						res.seller_products.sort((a, b) => (parseFloat(a.price) < parseFloat(b.price) ? -1 : 1));
						setLowestSellerProduct(res.seller_products[0]);
					}
					if (res.buyer_bids && res.buyer_bids.length > 0) {
						res.buyer_bids.sort((a, b) => (parseFloat(a.price) > parseFloat(b.price) ? -1 : 1));
						const temp = res.buyer_bids.filter((x) => x.status === "PENDING");
						if (temp && temp.length > 0) setHighestBid(temp[0]);
					}
					if (res.buyer_orders && res.buyer_orders.length > 0) {
						setLastSold(res.buyer_orders[0]);
					}
					setProduct(res);
					setChartData(res.daily_prices);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(getLang(extractErrors(err)[0]));
				}
			});
	}, [productId, token]);

	const toggleWatch = () => {
		if (product.is_watched) {
			setLoading(true);
			ApiService.buyerRemoveWatchList(token, productId)
				.then((res) => {
					if (isIn.current) {
						loadProduct();
						toast.success("Removed successfully!");
					}
				})
				.catch((err) => {
					if (isIn.current) {
						setLoading(false);
						toast.error(extractErrors(err)[0]);
					}
				});
		} else {
			setLoading(true);
			ApiService.buyerAddWatchList(token, productId)
				.then((res) => {
					if (isIn.current) {
						loadProduct();
						toast.success("Added successfully!");
					}
				})
				.catch((err) => {
					if (isIn.current) {
						setLoading(false);
						toast.error(extractErrors(err)[0]);
					}
				});
		}
	};
	const viewHistory = (slug) => {
		setChartSlug(slug);
		graphHistoryDiv.current.scrollIntoView({ behavior: "smooth" });
	};

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
		loadProduct();
	}, [loadProduct]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	const salesPercent = lowestSellerProduct && lastSold ? parseInt((parseFloat(lowestSellerProduct.price) / parseFloat(lastSold.price) - 1.0) * 100.0) : 0;

	return (
		<div className="product-detail-page">
			{loading ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : !product ? (
				<PageNotFound />
			) : (
				<div className="product-container">
					<div className="product-name">
						<div className="product-title-left">
							<h1 className="product-title">{product.name}</h1>
							<h5 className="product-variables">{product.size}</h5>
							<h5 className="product-variables">{product.abv}% ABV</h5>
							{product.has_box !== null && <h5 className="product-variables">{product.has_box ? "With" : "Without"} BOX</h5>}
							{product.has_tag !== null && <h5 className="product-variables">{product.has_tag ? "With" : "Without"} TAG</h5>}
							{product.has_bag !== null && <h5 className="product-variables">{product.has_bag ? "With" : "Without"} BAG</h5>}
							{product.has_tin !== null && <h5 className="product-variables">{product.has_tin ? "With" : "Without"} TIN</h5>}
							{product.has_case !== null && <h5 className="product-variables">{product.has_case ? "With" : "Without"} CASE</h5>}
						</div>
						<div className="product-title-right">
							{token && (
								<Link to="#" onClick={toggleWatch} replace={true}>
									{product.is_watched ? (
										<>
											<img className="add-portfolio-plus" src={remove_portfolio_minus_icon} alt="minus-icon" />
											<p className="cc-watch">unfollow</p>
										</>
									) : (
										<>
											<img className="add-portfolio-plus" src={add_portfolio_plus_icon} alt="plus-icon" />
											<p className="cc-watch">follow</p>
										</>
									)}
								</Link>
							)}
						</div>
					</div>
					<div className="product-bottle">
						<div className="product-carousel">
							<Link to="#" className="product-rotate-button" onClick={() => slider.current.slideNext()} replace={true}>
								<img src={greyLeftArrow} alt="eliqx-grey-left-arrow.png" />
							</Link>
							<Swiper
								spaceBetween={1}
								slidesPerView={"auto"}
								onSwiper={(swiper) => {
									slider.current = swiper;
								}}
							>
								{product.images.map((img, i) => (
									<SwiperSlide key={i}>
										<img className="bottle-main" src={img} alt={product.name} />
									</SwiperSlide>
								))}
							</Swiper>
							<Link to="#" className="product-rotate-button" onClick={() => slider.current.slidePrev()} replace={true}>
								<img src={greyRightArrow} alt="eliqx-grey-right-arrow.png" />
							</Link>
						</div>
						<p className="cc-img-disclaimer">*not actual bottle shown</p>
						<div className="product-list-price">
							${numberToPriceString(lowestSellerProduct?.price)}
							<br />
							<span className="product-page-current-price">lowest list price</span>
						</div>
						<div className="price-change">
							<img className="price-change-down cc-not-active" src={red_down_arrow} alt="red_down-arrow" style={{ opacity: salesPercent >= 0 ? 0.27 : 1 }} />
							<img className="price-change-up" src={green_up_arrow} alt="green_up_arrow" style={{ opacity: salesPercent >= 0 ? 1 : 0.27 }} />
							<div className={`price-change-percent ${salesPercent >= 0 ? "cc-green" : "cc-red"}`}>
								{salesPercent}
								<span>%</span>
							</div>
						</div>
					</div>
					<div className="action-btns">
						<Link to={token ? `/buyer/place-bid/${productId}` : "/login"} className="action-btn bid-btn">
							make offer
						</Link>
						<Link
							to={lowestSellerProduct ? (token ? `/buyer/place-order/${productId}` : "/login") : "#"}
							className="action-btn buy-btn"
							style={{ opacity: lowestSellerProduct ? 1 : 0.7 }}
							replace={!lowestSellerProduct}
						>
							<span className="buy-btn-rt">buy</span> | <span className="buy-btn-lt">${numberToPriceString(lowestSellerProduct?.price)}</span>
						</Link>
					</div>
					<div className="view-stats">
						<div className="view-details">
							<Link className="stats-link" to="#" onClick={viewHistory.bind(this, "bids")} replace={true}>
								highest offer <br /> ${numberToPriceString(highestBid?.price)}
							</Link>
						</div>
						<div className="view-details">
							<Link className="stats-link" to="#" onClick={viewHistory.bind(this, "orders")} replace={true}>
								last sold for <br /> ${numberToPriceString(lastSold?.price)}
							</Link>
						</div>
						<div className="view-details">
							<Link className="stats-link" to="#" onClick={viewHistory.bind(this, "listings")} replace={true}>
								available listings <br /> {product?.seller_products?.length ?? 0}
							</Link>
						</div>
					</div>
					<div className="product-vars">
						{middleDetails
							.filter((x) => !!product[x.value])
							.map((item) => (
								<p key={item.value} className="var-item">
									<span className="var-title">{item.label}</span>
									<span> </span>
									<Link to={`/shop/products?${item.value}=${product[item.value]}`}>
										<span className="var-name">{product[item.value]}</span>
									</Link>
								</p>
							))}
					</div>
					<div className="product-tasting-notes">
						{product.description ? (
							<>
								<h5 className="tasting-title">rare bottle description</h5>
								<p className="tasting-notes">{product.description}</p>
							</>
						) : (
							<img src={whiteLogo} alt="white-logo" className="no-description-white-logo" />
						)}
					</div>
				</div>
			)}
			<div>
				<ProductRelativeProducts products={product?.similar_products} />
			</div>
			{chartData && (
				<div
					ref={(el) => {
						graphHistoryDiv.current = el;
					}}
				>
					<ProductHistoryGraph
						product={product}
						chartData={chartData}
						selectedLabel={chartSlug}
						setSelectedLabel={setChartSlug}
						bids={product?.buyer_bids.filter((x) => x.status === "PENDING")}
						orders={product?.buyer_orders}
						listings={product?.seller_products}
					/>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(ProductDetailPage);
