import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import { Container, FormGroup, Input, Label, Row } from "reactstrap";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../redux/auth/auth.selector";
import { toast } from "react-toastify";
import { adminCreateProduct, shopGetProductDetail } from "../../services/ApiService";
import { extractErrors } from "../../helpers/commonHelper";
import { getLang } from "../../helpers/languageHelper";
import ImageUploader from "react-images-upload";
import "react-tabs/style/react-tabs.css";
import defaultProductImage from "../../assets/images/product-single.jpg";
import "./admin.styles.scss";
import TwoRadioButtonLine from "../../components/ui-kits/two-radio-button-line.component";
import NormalLoading from "../../components/ui-kits/normal-loading.component";

const settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
};

const product_slider = {
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 1,
};

const formFields = [
	"name",
	"size",
	"abv",
	"description",
	"has_box",
	"has_tag",
	"has_bag",
	"has_tin",
	"has_case",
	"batch",
	"lot",
	"distillery",
	"company",
	"category",
	"sub_category_1",
	"classification",
	"brand",
	"collection",
	"specification",
	"year_release",
	"aged",
	"sku",
	"weight",
	"width",
	"length",
	"height",
];

const AdminProductAddPage = ({ history, location, token }) => {
	const isIn = useRef();
	const refId = new URLSearchParams(location.search).get("ref");

	const [loading, setLoading] = useState(false);
	const [imageSrc, setImageSrc] = useState([defaultProductImage]);
	const [selectedImgId, setSelectedImgId] = useState(0);
	const [form, setForm] = useState(formFields);

	const loadData = useCallback(() => {
		if (!refId || refId === undefined) return;
		setLoading(true);
		shopGetProductDetail(refId, token)
			.then((res) => {
				if (isIn.current) {
					setLoading(false);
					let temp = {};
					formFields.forEach((x) => (temp[x] = res[x]));
					setForm(temp);
					setImageSrc(res.images);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					toast.error(getLang(extractErrors(err[0])));
					setLoading(false);
				}
			});
	}, [token, refId]);
	const formInputHandle = (id, event) => {
		setForm({ ...form, [id]: event.target.value });
	};
	const imageChange = (images) => {
		try {
			const reader = new FileReader();
			reader.readAsDataURL(images[images.length - 1]);
			reader.onloadend = (e) => {
				let temp = [...imageSrc];
				temp[selectedImgId] = reader.result;
				setImageSrc(temp);
			};
		} catch (error) {
			toast.error("This image is not available to upload");
		}
	};
	const addImage = (images) => {
		try {
			const reader = new FileReader();
			reader.readAsDataURL(images[images.length - 1]);
			reader.onloadend = (e) => {
				let temp = [...imageSrc, reader.result];
				setImageSrc(temp);
			};
		} catch (error) {
			toast.error("This image is not available to upload");
		}
	};
	const removeImage = () => {
		if (imageSrc.length <= 1) {
			toast.warning(getLang("empty_image_not_allowed"));
			return;
		}
		let temp = [...imageSrc];
		temp.splice(selectedImgId, 1);
		setImageSrc(temp);
		if (selectedImgId >= temp.length) setSelectedImgId(0);
	};
	const submitCreate = () => {
		setLoading(true);
		adminCreateProduct(token, { ...form, images: imageSrc })
			.then((res) => {
				history.replace(`/admin/product/update/${res.id}`);
			})
			.catch((err) => {
				setLoading(false);
				toast.error(getLang(extractErrors(err)[0]));
			});
	};

	useEffect(() => {
		loadData();
	}, [loadData]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="admin-product-add-page">
			<div className="site-content">
				<div className="content-wrapper section-ptb">
					<Container>
						{loading ? (
							<div className="loading">
								<NormalLoading />
							</div>
						) : (
							<section>
								<div className="product-content-top single-product single-product-edit">
									<Row>
										<div className="product-top-left col-xl-5 col-md-6">
											<div className="product-top-left-inner">
												<div className="ciyashop-product-images">
													<div className="ciyashop-product-images-wrapper ciyashop-gallery-style-default ciyashop-gallery-thumb_position-bottom ciyashop-gallery-thumb_vh-horizontal">
														<div className="ciyashop-product-gallery ciyashop-product-gallery--with-images slick-carousel">
															<div className="remove-image-button" onClick={removeImage}>
																<i className="fa fa-trash"></i>
															</div>
															<Slider {...settings} className="ciyashop-product-gallery__wrapper popup-gallery">
																<div className="ciyashop-product-gallery__image">
																	<ImageUploader
																		withIcon={false}
																		buttonText=""
																		onChange={imageChange}
																		imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
																		maxFileSize={5242880}
																	/>
																	<img src={imageSrc[selectedImgId] ?? ""} className="img-fluid" alt="slider1" />
																</div>
															</Slider>
															<div className="ciyashop-product-thumbnails">
																<Slider {...product_slider} className="ciyashop-product-thumbnails__wrapper">
																	{imageSrc.map((image, index) => (
																		<div key={index}>
																			<div className="ciyashop-product-thumbnail__image">
																				<div onClick={() => setSelectedImgId(index)}>
																					<img src={image} className="img-fluid" alt={index} />
																				</div>
																			</div>
																		</div>
																	))}
																	<div>
																		<div className="ciyashop-product-thumbnail__image">
																			<div className="d-flex justify-content-center image-content align-items-center">
																				<div>
																					<img src={defaultProductImage} className="img-fluid" alt="add" />
																					<ImageUploader
																						buttonText=""
																						onChange={addImage}
																						withPreview
																						withIcon={false}
																						maxFileSize={5242880}
																						imgExtension={[".jpg", ".png", ".gif", ".jpeg"]}
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																</Slider>
															</div>
														</div>
														<div className="clearfix" />
													</div>
												</div>
											</div>
										</div>
										<div className="product-top-right col-xl-7 col-md-6">
											<div className="product-top-right-inner">
												<div className="summary entry-summary">
													<FormGroup className="edit-icon">
														<Input
															type="text"
															className="form-control product_title"
															placeholder={getLang("product_name")}
															value={form.name ?? ""}
															onChange={formInputHandle.bind(this, "name")}
														/>
													</FormGroup>
													<FormGroup className="edit-icon">
														<Input
															type="textarea"
															className="form-control"
															rows="3"
															placeholder={getLang("product_description")}
															value={form.description ?? ""}
															onChange={formInputHandle.bind(this, "description")}
														/>
													</FormGroup>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("size")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("size")}
																value={form.size ?? ""}
																onChange={formInputHandle.bind(this, "size")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("abv")} (%)</Label>
															<Input
																type="number"
																className="form-control"
																placeholder={getLang("abv")}
																value={form.abv ?? ""}
																onChange={formInputHandle.bind(this, "abv")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<TwoRadioButtonLine
															label_1="with Box"
															label_2="without Box"
															val={form.has_box}
															setVal={(val) => setForm({ ...form, has_box: val })}
														/>
													</div>
													<div className="small-fields">
														<TwoRadioButtonLine
															label_1="with Tag"
															label_2="without Tag"
															val={form.has_tag}
															setVal={(val) => setForm({ ...form, has_tag: val })}
														/>
													</div>
													<div className="small-fields">
														<TwoRadioButtonLine
															label_1="with Bag"
															label_2="without Bag"
															val={form.has_bag}
															setVal={(val) => setForm({ ...form, has_bag: val })}
														/>
													</div>
													<div className="small-fields">
														<TwoRadioButtonLine
															label_1="with Tin"
															label_2="without Tin"
															val={form.has_tin}
															setVal={(val) => setForm({ ...form, has_tin: val })}
														/>
													</div>
													<div className="small-fields">
														<TwoRadioButtonLine
															label_1="with Case"
															label_2="without Case"
															val={form.has_case}
															setVal={(val) => setForm({ ...form, has_case: val })}
														/>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("batch")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("batch")}
																value={form.batch ?? ""}
																onChange={formInputHandle.bind(this, "batch")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("lot")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("lot")}
																value={form.lot ?? ""}
																onChange={formInputHandle.bind(this, "lot")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("distillery")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("distillery")}
																value={form.distillery ?? ""}
																onChange={formInputHandle.bind(this, "distillery")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("company")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("company")}
																value={form.company ?? ""}
																onChange={formInputHandle.bind(this, "company")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("category")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("category")}
																value={form.category ?? ""}
																onChange={formInputHandle.bind(this, "category")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">Sub category</Label>
															<Input
																type="text"
																className="form-control"
																placeholder="Sub category"
																value={form.sub_category_1 ?? ""}
																onChange={formInputHandle.bind(this, "sub_category_1")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("classification")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("classification")}
																value={form.classification ?? ""}
																onChange={formInputHandle.bind(this, "classification")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("brand")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("brand")}
																value={form.brand ?? ""}
																onChange={formInputHandle.bind(this, "brand")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("collection")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("collection")}
																value={form.collection ?? ""}
																onChange={formInputHandle.bind(this, "collection")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("specification")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("specification")}
																value={form.specification ?? ""}
																onChange={formInputHandle.bind(this, "specification")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">Release</Label>
															<Input
																type="text"
																className="form-control"
																placeholder="Release"
																value={form.year_release ?? ""}
																onChange={formInputHandle.bind(this, "year_release")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("aged")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("aged")}
																value={form.aged ?? ""}
																onChange={formInputHandle.bind(this, "aged")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("sku")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("sku")}
																value={form.sku ?? ""}
																onChange={formInputHandle.bind(this, "sku")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("weight")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("weight")}
																value={form.weight ?? ""}
																onChange={formInputHandle.bind(this, "weight")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("width")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("width")}
																value={form.width ?? ""}
																onChange={formInputHandle.bind(this, "width")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("length")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("length")}
																value={form.length ?? ""}
																onChange={formInputHandle.bind(this, "length")}
															/>
														</div>
													</div>
													<div className="small-fields">
														<div className="inline">
															<Label className="title">{getLang("height")}</Label>
															<Input
																type="text"
																className="form-control"
																placeholder={getLang("height")}
																value={form.height ?? ""}
																onChange={formInputHandle.bind(this, "height")}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Row>
								</div>
								<div className="col-md-12">
									<div className="buttons">
										<button className="btn btn-primary mb-2 mr-2" onClick={submitCreate}>
											{getLang("create")}
										</button>
										<button className="btn btn-warning mb-2" onClick={() => history.goBack()}>
											{getLang("cancel")}
										</button>
									</div>
								</div>
							</section>
						)}
					</Container>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(AdminProductAddPage);
