import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import { createStructuredSelector } from "reselect";
import { extractErrors } from "../../../helpers/commonHelper";
import { getToken } from "../../../redux/auth/auth.selector";
import { buyerGetShippingInfos, sellerCreateOnCallPickup } from "../../../services/ApiService";
import SellerProfileMenu from "./seller-profile-menu.component";
import { Link } from "react-router-dom";
import "./seller-profile.styles.scss";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";

const OnCallPickupRequestModal = ({ shipping, close, onCallRequest }) => {
	const confirm = () => {
		onCallRequest(shipping);
		close();
	};
	return (
		<Modal isOpen={true} className="request-confirm-modal modal-login modal-dialog-centered auth-modal">
			<p className="confirm-message">
				Please confirm that your bottle is ready for pickup. If you need to edit your pickup address or instructions please click
				<Link className="here" to="/seller/profile-profile">
					here
				</Link>
				to edit
			</p>
			<div className="shipping-div">
				<span>
					{shipping.first_name} {shipping.last_name}
				</span>
				<br />
				<span>{shipping.address_1}</span>
				<br />
				{shipping.address_2 && (
					<>
						<span>{shipping.address_2}</span>
						<br />
					</>
				)}
				<span>
					{shipping.city} CA {shipping.zip_code}
				</span>
				<br />
				{shipping.special_instruction && <span>{shipping.special_instruction}</span>}
			</div>
			<div className="button-div">
				<button className="confirm" onClick={confirm.bind(this)}>
					Confirm
				</button>
				<button className="cancel" onClick={() => close()}>
					Cancel
				</button>
			</div>
		</Modal>
	);
};

const SellerOnCallPickupPage = ({ token }) => {
	const [loading, setLoading] = useState(false);
	const [shippings, setShippings] = useState([]);
	const [selectedShipping, setSelectedShipping] = useState();

	const loadShippings = useCallback(() => {
		setLoading(true);
		buyerGetShippingInfos(token)
			.then((res) => {
				setShippings(res.filter((x) => x.is_pickup === 1));
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	}, [token]);

	const createOnCallPickup = (shipping) => {
		setLoading(true);
		sellerCreateOnCallPickup(token, { shipping_id: shipping.id })
			.then((res) => {
				setLoading(false);
				toast.success("Requested successfully!");
			})
			.catch((err) => {
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	};

	useEffect(() => {
		loadShippings();
	}, [loadShippings]);

	return (
		<div className="seller-on-call-pickup-page">
			<div className="dashboard">
				<SellerProfileMenu />
				<div className="dashboard-main">
					<h5 className="sub-label">Your bottle is packaged and ready for pickup?</h5>
					{selectedShipping && (
						<OnCallPickupRequestModal shipping={selectedShipping} close={() => setSelectedShipping(null)} onCallRequest={createOnCallPickup} />
					)}
					{loading ? (
						<div className="loading">
							<NormalLoading />
						</div>
					) : (
						shippings &&
						shippings.map((item) => (
							<div className="item" key={item.id}>
								<div className="item-row">
									<div className="shipping-div">
										<span>
											{item.first_name} {item.last_name}
										</span>
										<br />
										<span>{item.address_1}</span>
										<br />
										{item.address_2 && (
											<>
												<span>{item.address_2}</span>
												<br />
											</>
										)}
										<span>
											{item.city} CA {item.zip_code}
										</span>
										<br />
										{item.special_instruction && <span>{item.special_instruction}</span>}
									</div>
									<div className="button-div">
										<button onClick={() => setSelectedShipping(item)}>Request Pickup For Tomorrow</button>
									</div>
								</div>
							</div>
						))
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(SellerOnCallPickupPage);
