import React, { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import NormalLoading from "../../components/ui-kits/normal-loading.component";
import { extractErrors } from "../../helpers/commonHelper";
import { loadProfile } from "../../redux/auth/auth.action";
import { authResetPassword } from "../../services/ApiService";

const ResetPasswordPage = ({ location, match, loadToken }) => {
	const userId = match.params.userId;
	const ref_code = new URLSearchParams(location.search).get("ref");
	const [code, setCode] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [loading, setLoading] = useState(false);

	const codeInputHandle = (event) => {
		setCode(event.target.value);
	};
	const passwordInputHandle = (event) => {
		setPassword(event.target.value);
	};
	const passwordConfirmationInputHandle = (event) => {
		setPasswordConfirm(event.target.value);
	};

	const submitForm = (event) => {
		event.preventDefault();
		if (password !== passwordConfirm) {
			toast.error("Password confirmation mismatch");
			return;
		}
		let form = {
			user_id: userId,
			password,
		};
		if (ref_code) form.ref_code = ref_code;
		else form.code = code;

		setLoading(true);
		authResetPassword(form)
			.then((res) => {
				if (res.access_token) {
					localStorage.setItem("token", res.access_token);
					window.location.href = window.location.origin;
				} else {
					setLoading(false);
					toast.error("Something went wrong!");
				}
			})
			.catch((err) => {
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	};

	return (
		<div className="reset-password-page">
			<h1>Please reset your password</h1>
			{loading ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<form method="POST" onSubmit={submitForm}>
					{!ref_code && <input type="text" className="code" placeholder="Security code" value={code} onChange={codeInputHandle} />}
					<input
						type="password"
						autoComplete="new-password"
						className="password"
						placeholder="New password"
						value={password}
						onChange={passwordInputHandle.bind(this)}
					/>
					<input
						type="password"
						autoComplete="new-password"
						className="password-confirm"
						placeholder="Confirm password"
						value={passwordConfirm}
						onChange={passwordConfirmationInputHandle.bind(this)}
					/>
					<button className="btn btn-submit" type="submit">
						Submit
					</button>
				</form>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	loadToken: loadProfile,
});

export default connect(mapStateToProps)(ResetPasswordPage);
