import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import { extractErrors, strToPhoneNumberFormat, strToPhoneNumberString } from "../../../helpers/commonHelper";
import { getProfile, getToken } from "../../../redux/auth/auth.selector";
import { Link } from "react-router-dom";
import {
	authEmailVerifyRequest,
	authGetSettings,
	authSmsVerifyRequest,
	authUpdatePassword,
	authUpdateProfile,
	authUpdateTwoFactorAuth,
} from "../../../services/ApiService";
import SellerProfileMenu from "./seller-profile-menu.component";
import securityLogo from "../../../assets/images/eliqx-security.png";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";

const initialForm = {
	old_password: "",
	password: "",
	password_confirmation: "",
};

const SellerAccountSecurityPage = ({ token, profile }) => {
	const isIn = useRef();
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const inputHandle = (event) => {
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
	};
	const [twoFactor, setTwoFactor] = useState();
	const [code, setCode] = useState("");
	const [sid, setSid] = useState();
	const [phoneNumber, setPhoneNumber] = useState(profile?.phone_number);

	const submitTwoFactor = () => {
		if (!!twoFactor && !code) {
			toast.warning("Please input verify code");
			return;
		}
		const form = { two_factor_auth: twoFactor, code, sid };
		setLoading(true);

		const updateNow = () => {
			authUpdateTwoFactorAuth(token, form)
				.then((res) => {
					if (isIn.current) {
						toast.success("Updated successfully");
						setCode("");
						loadSetting();
					}
				})
				.catch((err) => {
					if (isIn.current) {
						toast.error(extractErrors(err)[0]);
						setLoading(false);
					}
				});
		};

		if (twoFactor === "SMS") {
			authUpdateProfile(token, { phone_number: phoneNumber, phone_number_verify_code: code })
				.then((res) => {
					updateNow();
				})
				.catch((err) => {
					if (isIn.current) {
						toast.error(extractErrors(err)[0]);
						setLoading(false);
					}
				});
		} else {
			updateNow();
		}
	};
	const sendCode = () => {
		if (twoFactor === "SMS") {
			setLoading(true);
			authSmsVerifyRequest(phoneNumber)
				.then((res) => {
					toast.success("Verify code sent to your phone!");
					setLoading(false);
				})
				.catch((err) => {
					toast.error(extractErrors(err)[0]);
					setLoading(false);
				});
		} else if (twoFactor === "EMAIL") {
			setLoading(true);
			authEmailVerifyRequest(profile.email)
				.then((res) => {
					setSid(res.sid);
					toast.success("Verify code sent to your email box");
					setLoading(false);
				})
				.catch((err) => {
					toast.error(extractErrors(err)[0]);
					setLoading(false);
				});
		} else {
			toast.error("Invalid operation");
		}
	};
	const handleRadioChange = (val) => {
		setTwoFactor(val);
	};
	const loadSetting = useCallback(() => {
		setLoading(true);
		authGetSettings(token)
			.then((res) => {
				if (isIn.current) {
					setTwoFactor(res?.two_factor_auth);
					setLoading(false);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [token]);

	const submitForm = () => {
		if (form.password !== form.password_confirmation) {
			toast.error("Password confirm mismatch");
			return;
		}
		setLoading(true);
		authUpdatePassword(token, form)
			.then((res) => {
				if (isIn.current) {
					setLoading(false);
					toast.success("Updated successfully");
					setForm(initialForm);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	};

	useLayoutEffect(() => {
		loadSetting();
	}, [loadSetting]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="seller-account-security-page">
			<div className="dashboard">
				<SellerProfileMenu />
				<div className="dashboard-main">
					<div className="security">
						<div className="page-title">
							<h2>
								<img src={securityLogo} alt="security-logo" />
								SECURITY
							</h2>
						</div>
						<div className="title">
							<h4>Security Manager</h4>
						</div>
						{loading ? (
							<div className="loading">
								<NormalLoading />
							</div>
						) : (
							<>
								<form action="" className="change-password">
									<h5>Change Password</h5>
									<input
										type="password"
										placeholder="Current Password"
										autoComplete="new-password"
										name="old_password"
										value={form.old_password}
										onChange={inputHandle}
									/>
									<input type="password" placeholder="New Password" autoComplete="new-password" name="password" value={form.password} onChange={inputHandle} />
									<input
										type="password"
										placeholder="Confirm New Password"
										autoComplete="new-password"
										name="password_confirmation"
										value={form.password_confirmation}
										onChange={inputHandle}
									/>
									<button type="button" onClick={submitForm}>
										SAVE
									</button>
									<Link className="cancel" to="#" replace={true}>
										cancel
									</Link>
								</form>
								<div className="tfAuth">
									<h5>Enable Two Factor Authentication</h5>
									<p>
										We take protecting your information seriously. Please toggle on if you would like to enable an extra layer of security. When ON, this
										feature will send a one time code to your email or phone to verify that the person logging in is actually you.
									</p>
									<div className="radio">
										<input
											className="radio-input"
											type="radio"
											name="tfCode"
											id="sms"
											checked={twoFactor === "SMS"}
											onChange={handleRadioChange.bind(this, "SMS")}
										/>
										<label className="radio-label" htmlFor="sms">
											send code via SMS
										</label>
										<input
											type="text"
											placeholder="Your phone number"
											value={strToPhoneNumberFormat(phoneNumber)}
											onChange={(e) => setPhoneNumber(strToPhoneNumberString(e.target.value))}
										/>
										<input type="text" placeholder="Enter SMS Code" value={code} onChange={(e) => setCode(e.target.value)} />
										{twoFactor === "SMS" && (
											<button className="btn send-code-btn" onClick={sendCode}>
												Send Code
											</button>
										)}
									</div>
									<div className="radio">
										<input
											className="radio-input"
											type="radio"
											name="tfCode"
											id="email"
											checked={twoFactor === "EMAIL"}
											onChange={handleRadioChange.bind(this, "EMAIL")}
										/>
										<label className="radio-label" htmlFor="email">
											send code via email
										</label>
										<input type="text" placeholder="Enter Code" value={code} onChange={(e) => setCode(e.target.value)} />
										{twoFactor === "EMAIL" && (
											<button className="btn send-code-btn" onClick={sendCode}>
												Send Code
											</button>
										)}
									</div>
									<div className="switchbutton">
										<input type="checkbox" id="sw" checked={!!twoFactor} onChange={() => setTwoFactor(null)} />
										<label htmlFor="sw"></label>
										<span></span>
									</div>
									<div className="pt-1 ml-3 two-factor">
										<button type="button" onClick={submitTwoFactor}>
											Save
										</button>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
	profile: getProfile,
});

export default connect(mapStateToProps)(SellerAccountSecurityPage);
