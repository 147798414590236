import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Container, Col } from "reactstrap";
import * as ApiService from "../../services/ApiService";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { extractErrors, numberToPriceString } from "../../helpers/commonHelper";
import { Link } from "react-router-dom";
import { getLang } from "../../helpers/languageHelper";
import "./admin.styles.scss";
import ProductMainDetailBox from "../../components/shop/product-main-detail-box.component";
import NormalLoading from "../../components/ui-kits/normal-loading.component";

const AdminProductDetailPage = ({ token, match }) => {
	const isIn = useRef();
	const productId = match.params.productId;
	const [product, setProduct] = useState();
	const [loading, setLoading] = useState(false);
	const [selectedSellerProduct, setSelectedSellerProduct] = useState();
	const loadProduct = useCallback(() => {
		setLoading(true);
		ApiService.adminGetProductDetail(productId, token)
			.then((res) => {
				if (isIn.current) {
					setLoading(false);
					setProduct(res);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(getLang(extractErrors(err)[0]));
				}
			});
	}, [productId, token]);

	const approveRejectSellerProduct = (seller_product_id, status) => {
		setLoading(true);
		ApiService.adminUpdateSellerProductStatus(token, seller_product_id, status)
			.then((res) => {
				setSelectedSellerProduct(null);
				toast.success(getLang("updated_successfully"));
				loadProduct();
			})
			.catch((err) => {
				setLoading(false);
				toast.error(getLang(extractErrors(err)[0]));
			});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		loadProduct();
	}, [loadProduct]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="admin-product-detail-page">
			{product ? (
				<div className="site-content">
					<div className="content-wrapper" style={{ marginTop: "5rem" }}>
						<Container>
							{loading ? (
								<div className="loading">
									<NormalLoading />
								</div>
							) : (
								<section>
									<div className="product-content-top single-product single-product-edit">
										<div className="clone-button">
											<Link to={`/admin/product/add?ref=${productId}`} className="btn btn-primary" style={{ float: "right" }}>
												Clone
											</Link>
										</div>
										<ProductMainDetailBox product={product} />
										<Row className="skus">
											<Col md={8} className="seller-column py-5 px-10">
												<table className="table table-striped">
													<thead>
														<tr>
															<th scope="col">{getLang("seller_name")}</th>
															<th scope="col">{getLang("asked_price")}</th>
															<th scope="col">{getLang("distributor")}</th>
															<th scope="col">{getLang("invoice_number")}</th>
															<th scope="col">{getLang("status")}</th>
															<th scope="col">{getLang("registered_date")}</th>
														</tr>
													</thead>
													<tbody>
														{product.seller_products &&
															product.seller_products.map((item) => (
																<tr key={item.id} onClick={() => setSelectedSellerProduct(item)}>
																	<td>{item.seller.name}</td>
																	<td>${numberToPriceString(item.price)}</td>
																	<td>{item.distributor}</td>
																	<td>{item.invoice_number}</td>
																	<td>{item.status}</td>
																	<td>{item.created_at}</td>
																</tr>
															))}
													</tbody>
												</table>
											</Col>
											{selectedSellerProduct && (
												<Col md={4} className="variants py-5">
													<table className="table info mb-5">
														<tbody>
															<tr>
																<th scope="col">{getLang("seller_email")}</th>
																<td>{selectedSellerProduct.seller.email}</td>
															</tr>
															<tr>
																<th scope="col">{getLang("seller_phone_number")}</th>
																<td>{selectedSellerProduct.seller.phone_number}</td>
															</tr>
															<tr>
																<th>{getLang("tax_rate")}</th>
																<td>{selectedSellerProduct.seller.tax}%</td>
															</tr>
															<tr>
																<th>{getLang("discount_code")}</th>
																<td>{selectedSellerProduct.discount_code}</td>
															</tr>
															<tr>
																<th>{selectedSellerProduct.status}</th>
																<td className="action-button">
																	{selectedSellerProduct.status === "APPROVED" ? (
																		<div className="btn btn-danger" onClick={approveRejectSellerProduct.bind(this, selectedSellerProduct.id, "REJECTED")}>
																			{getLang("reject")}
																		</div>
																	) : (
																		<div className="btn btn-success" onClick={approveRejectSellerProduct.bind(this, selectedSellerProduct.id, "APPROVED")}>
																			{getLang("approve")}
																		</div>
																	)}
																</td>
															</tr>
														</tbody>
													</table>
												</Col>
											)}
										</Row>
									</div>
								</section>
							)}
						</Container>
					</div>
					<Row>
						<div className="col-12">
							<div className="mobile-menu" id="mobileMenu" />
						</div>
					</Row>
				</div>
			) : (
				!loading && <h1>Product Not found</h1>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(AdminProductDetailPage);
