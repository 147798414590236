import React from "react";
import "./legal-pages.styles.scss";

const SellerGuidePage = () => (
	<div className="seller-guide-page">
		<div className="cc-container">
			<h2>seller's guide</h2>
			<h3>eliqX is:</h3>
			<ul className="cc-feat-list">
				<li>a community of liquor enthusiasts looking for the rarest bottles.</li>
				<li>an online marketplace that allows customers from across the state to find your rare inventory.</li>
				<li>a “stock market” for rare bottles of liquor.</li>
			</ul>
			<div className="cc-buyer-step-container">
				<h3 className="cc-buyer-step">
					step 1: create a <strong>listing</strong>
				</h3>
				<p className="cc-buyer-steps">
					You can create a <strong>listing</strong> from the profile menu or your dashboard. You can create a listing for an existing item or submit an item
					taht we don't have in our database yet.
				</p>
				<p className="cc-buyer-steps">You can:</p>
				<p className="cc-buyer-steps-details">
					1) If the item already exists in our database, you can specify variations of that item (for example: the specific ABV of a barrel proof release, or
					does it come with a box or tag or both or neither).
				</p>
				<p className="cc-buyer-steps-details">
					2) If you want to submit an item that we don't have in our database, you must submit a picture of the item and fill out a description of the product.
					eliqx will review the item and determine if a listing will be created. (this usually happens within a day).
				</p>
				<p className="cc-buyer-steps">
					Payout information and shipping options must be provided at this time. Once a bottle is listed, you may receive notifications for supply and demand
					trends on that bottle.
				</p>
			</div>
			<div className="cc-buyer-step-container">
				<h3 className="cc-buyer-step">
					step 2: <strong>listing</strong> sold or accept an <strong>offer</strong>
				</h3>
				<p className="cc-buyer-steps">
					Your <strong>listing</strong> may sell for the price you specified at any time, or you can choose to accept the current highest <strong>offer</strong>
					for your <strong>listing</strong> at any time (you may want to accept an <strong>offer</strong> for a higher payout). Once either of these happen the
					shipping process begins. Please note: failure to fulfill a sold listing for any reason will result in penalties. Please see the seller user agreement
					for details.
				</p>
			</div>
			<div className="cc-buyer-step-container">
				<h3 className="cc-buyer-step">step 3: shipping process</h3>
				<p className="cc-buyer-steps">Here are your steps in the shipping process:</p>
				<p className="cc-buyer-steps-details">
					1) Because eliqx knows that not all retailers will have proper shipping/packing material on hand, it will be shipped to you. A shipping box that is
					bottle specific will be delivered to your specified address within 2 business days.
				</p>
				<p className="cc-buyer-steps-details">2) Package the rare bottle into the shipping box.</p>
				<ul className="cc-feat-list">
					<li>
						open and take out the contents. Inside the shipping box you will find the following packing material:
						<br />
						- new shipping label to the buyer,
						<br />
						- bottle tray(s)
						<br />
						- bubble wrap (if needed)
						<br />
						- tape strips
						<br />- packing peanuts and/or air cushions (if needed).
					</li>
					<li>adhere the new shipping label over the old shipping label,</li>
					<li>Use packing material to securely package the rare bottle into the shipping box.</li>
					<li>Reseal the shipping box. tape strips have been provided for your convenience.</li>
				</ul>
				<p className="cc-buyer-steps-details">
					Alternatively: you can opt to use your own shipping material to expedite the process. When using your own shipping material you will print out the
					shipping label from the “Sold” section of the “Sell Orders” tab in your profile page without waiting for the eliqX shipping box to show up.
					<br />
					<strong>please note</strong>: the eliqX shipping box and packing material will still be shipped to you.
				</p>
				<p className="cc-buyer-steps-details">
					3) Schedule next day pick up from your “Pickup Request” tab in your profile. You have 2 business days upon receipt of the shipping box to have the
					rare bottle picked up for delivery to the buyer.
				</p>
			</div>
			<div className="cc-buyer-step-container">
				<h3 className="cc-buyer-step">step 4: payout</h3>
				<p className="cc-buyer-steps">
					Your payout happens when our shipping partner has scanned in the package for delivery. This process is automated and will process the payout to the
					specified “payout info” in your profile.
				</p>
			</div>
		</div>
	</div>
);

export default SellerGuidePage;
