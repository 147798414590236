import React from "react";
import logo from "../../assets/images/order-confirm-grey-logo.svg";
import "./legal-pages.styles.scss";

const LegalPrivacyPolicyPage = () => (
	<div className="legal-privacy-policy-page">
		<div className="legal-container ">
			<div className="legal-logo">
				<img className="legal-img" src={logo} alt="eliqx grey logo" />
				<h2 className="cc-tagline">electronic liquor exchange</h2>
			</div>
			<div className="legal-container">
				<h1 className="legal-title">privacy policy</h1>
				<p className="legal-body">
					This Privacy Policy describes Eliqx’s practices of information collection, use and sharing. Eliqx’s website and mobile application services
					(“Service”) is subject to this Privacy Policy and Terms of Service.
				</p>
				<p className="legal-body">
					The Service is for persons 21 years of age or older. By using the Service you consent that you are 21 years or age or older. If you are under 21 years
					of age do not send us any information.
				</p>
				<p className="legal-section-title">1) What is done with your information</p>
				<p className="legal-body">
					When you make an offer for something from the Service, as part of the buying and selling process, we collect the personal information you give us,
					such as your name, address, phone number, and email address. We use this information to complete your transaction, and for our own research into
					consumer’s buying habits. This information may be collected with a cookie. Your browser can be set to block cookies, but some features of the Service
					may not work. We also store your purchase history to allow us to assist you in case you need to access a customer service representative.
					<br />
					<br />
					When you browse the Service, we also automatically collect some electronic network information, such as your computer’s internet protocol (IP)
					address, your browser and operating system. In addition, we may also store information such as the referrer that sent you to our website (if
					applicable), the landing page you found, and the length of time you spend on our site.
					<br />
					<br />
					Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates. You may opt out of
					these emails at any time by using the Unsubscribe link at the bottom of the email.
					<br />
					<br />
					We never sell your information to any third parties.
				</p>
				<p className="legal-section-title">2) Consent</p>
				<p className="legal-body">
					When you provide us with personal information to complete a transaction, create an account, verify your credit card, place an order, arrange for a
					delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only.
					<br />
					<br />
					If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your express consent, or provide
					you with an opportunity to say no.
					<br />
					<br />
					If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of
					your information, at anytime, by contacting us at <a href="mailto:support@eliqx.io">support@eliqx.io</a>
				</p>
				<p className="legal-section-title">3) Disclosure</p>
				<p className="legal-body">
					We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service. We also disclose your personal
					information to our service providers, as necessary, such as FedEx, UPS or GLS in order to have your item shipped to you. When we provide your personal
					information to our third party shippers, we disclose your name and address. We do not sell any customer information to any third parties.
				</p>
				<p className="legal-section-title">4) Third-party services</p>
				<p className="legal-body">
					In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform
					the services they provide to us.
					<br />
					<br />
					However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in
					respect to the information we are required to provide to them for your purchase-related transactions.
					<br />
					<br />
					For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be
					handled by these providers.
					<br />
					<br />
					Once you leave our Service or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our
					website’s Terms of Service.
					<br />
					<br />
					When you click on links in our Service, they may direct you away from our site. We are not responsible for the privacy practices of other sites and
					encourage you to read their privacy statements.
				</p>
				<p className="legal-section-title">5) Security</p>
				<p className="legal-body">
					To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost,
					misused, accessed, disclosed, altered or destroyed.
					<br />
					<br />
					If you provide us with your credit card information, the information is encrypted using secure socket layer technology (SSL) and stored with a AES-256
					encryption. Although no method of transmission over the Internet or electronic storage is 100% secure, we follow all PCI-DSS requirements and
					implement additional generally accepted industry standards.
				</p>
				<p className="legal-section-title">6) Changes to this privacy policy</p>
				<p className="legal-body">
					We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect
					immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that
					you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
					<br />
					<br />
					If our Service is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell
					products to you.
				</p>
				<p className="legal-section-title">7) California Consumer Privacy Act</p>
				<p className="legal-body">
					Pursuant to Section 1798.100 et seq. of the California Civil Code, beginning January 1, 2020 residents of California have certain rights with respect
					to their personal information. California residents may request the categories of personal information we collect for that consumer, categories of
					sources from which the personal information is collected, the business or commercial purposes for collecting that personal information, the categories
					of third parties with which we share that personal information, the categories of personal information we share with third parties for a business
					purpose, and the specific pieces of personal information we have for that consumer. In addition, residents of California may request that we delete
					any personal information that we may have for that California resident. To request the information we have stored or shared about you, or to request
					that we delete your personal information, please contact us at <a href="mailto:support@eliqx.io">support@eliqx.io</a>
				</p>
			</div>
		</div>
	</div>
);

export default LegalPrivacyPolicyPage;
