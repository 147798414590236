import React, { useCallback, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import "./seller-sell-now-confirmed.styles.scss";
import logo from "../../../assets/images/order-confirm-grey-logo.svg";

const SellerSellNowConfirmedPage = ({ history, product }) => {
	const [order, setOrder] = useState();

	const removeStorage = useCallback(() => {
		localStorage.removeItem(`sell_now_confirmed_${product.id}`);
	}, [product]);

	const goToDashboard = () => {
		history.push("/seller/profile-portfolio?tab=SOLD");
	};

	useEffect(() => {
		const item = localStorage.getItem(`sell_now_confirmed_${product.id}`);
		setOrder(JSON.parse(item));
	}, [product]);

	useEffect(() => {
		window.document.onclick = () => {
			removeStorage();
		};
	}, [removeStorage]);

	return (
		<div className="seller-sell-now-confirmed-page">
			<div className="container ">
				<div className="orderLogoContainer">
					<img className="orderLogo" src={logo} alt="grey-logo" />
				</div>
				<div className="orderDetailContainer">
					<h1>sold!</h1>
					<Link className="orderNumber" to="/" onClick={removeStorage}>
						#{order?.seller_product.id}
					</Link>
					<p className="orderThanks">Thank you for selling on our platform.</p>
					<p className="orderInfo">Here's what happens next:</p>
					<ul className="sellSteps">
						<li>We will send you a shipping box</li>
						<li>Carefully pack your bottle</li>
						<li>Request GLS package pickup or drop off at a GLS</li>
					</ul>
					<Link className="orderFaq" to="/seller/faq" onClick={removeStorage}>
						FAQ
					</Link>
					<button className="buyerDashboard" type="button" name="button" onClick={goToDashboard}>
						seller dashboard
					</button>
				</div>
			</div>
		</div>
	);
};

export default withRouter(SellerSellNowConfirmedPage);
