import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import SellerCertifyBoxComponent from "../../../components/seller/seller-certify-box.component";
import SellerPriceLimitExceedComponent from "../../../components/seller/seller-price-limit-exceed.component";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";
import { extractErrors, numberToPriceString, strToPhoneNumberFormat, strToPhoneNumberString } from "../../../helpers/commonHelper";
import { getToken } from "../../../redux/auth/auth.selector";
import {
	buyerGetShippingInfos,
	buyerSaveShippingInfo,
	sellerAcceptBid,
	sellerRegisterToProduct,
	shopCalcTransactionFees,
	shopGetDiscountCode,
	shopGetProductDetail,
} from "../../../services/ApiService";
import SellerSellNowConfirmedPage from "../seller-accept-bid/seller-sell-now-confirmed-page.component";
import "./seller-list-sell-page.styles.scss";

const initialShippingForm = {
	first_name: "",
	last_name: "",
	phone_number: "",
	address_1: "",
	address_2: "",
	city: "",
	zip_code: "",
	special_instruction: "",
};

const SellerListSellPage = ({ token, match, location }) => {
	const isIn = useRef();
	const timeout = useRef();
	const productId = match.params.productId;
	const bidId = new URLSearchParams(location.search).get("bid");
	const [loading, setLoading] = useState(false);
	const [product, setProduct] = useState();
	const [selectedBid, setSelectedBid] = useState();
	const [fees, setFees] = useState();
	const [discountCode, setDiscountCode] = useState("");
	const [discountPrice, setDiscountPrice] = useState();
	const takeShippingFee = true;
	const [mustCheck, setMustCheck] = useState(0);
	const [openShipping, setOpenShipping] = useState(false);
	const [selectedShipping, setSelectedShipping] = useState();
	const [savedShipping, setSavedShipping] = useState([]);
	const [shippingForm, setShippingForm] = useState(initialShippingForm);

	const shippingInputHandle = (event) => {
		let { name, value } = event.target;
		if (name === "phone_number") value = strToPhoneNumberString(value);
		else if (name === "special_instruction") value = value.substr(0, 100);
		setShippingForm({ ...shippingForm, [name]: value });
	};
	const loadProduct = useCallback(() => {
		setLoading(true);
		shopGetProductDetail(productId, token)
			.then((res) => {
				if (!isIn.current) return;
				setProduct(res);
				if (res.buyer_bids) {
					if (bidId) {
						setSelectedBid(res.buyer_bids.find((x) => x.id === bidId));
					} else {
						res.buyer_bids.sort((a, b) => (a.price > b.price ? -1 : 1));
						const temp = res.buyer_bids.filter((x) => x.status === "PENDING");
						if (temp && temp.length > 0) setSelectedBid(temp[0]);
					}
				}
				if (res.my_products && res.my_products.length > 0) {
					res.my_products.sort((a, b) => (a.price < b.price ? -1 : 1));
					setDiscountCode(res.my_products[0].discount_code);
				}
				setLoading(false);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	}, [productId, token, bidId]);

	const submitSellNow = () => {
		if (!selectedBid) {
			toast.error("No selected bid");
			return;
		}
		if (mustCheck !== 7) {
			toast.error("You must agree with our terms and service policy");
			return;
		}
		if (!selectedShipping) {
			toast.error("Please input shipping from location");
			return;
		}
		const listingForm = {
			price: selectedBid.price,
			shipping_id: selectedShipping.id,
			take_shipping_fee: takeShippingFee,
			expiration: 15,
			discount_code: discountCode,
		};
		setLoading(true);
		sellerRegisterToProduct(token, productId, listingForm)
			.then((res) => {
				sellerAcceptBid(token, selectedBid.id, {
					bid_price: selectedBid.price,
					discount_code: discountCode,
					take_shipping_fee: takeShippingFee,
				})
					.then((res) => {
						localStorage.setItem(`sell_now_confirmed_${productId}`, JSON.stringify(res));
						setLoading(false);
					})
					.catch((err) => {
						if (isIn.current) {
							if (err.error === "sell_now_price_limit_exceed") {
								localStorage.setItem(`sell_now_price_limit_exceed_${productId}`, new Date().getTime());
								setLoading(false);
							} else {
								loadProduct();
								toast.error(extractErrors(err)[0]);
							}
						}
					});
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	};
	const loadSavedShippingInfos = useCallback(() => {
		setSavedShipping(null);
		buyerGetShippingInfos(token)
			.then((res) => {
				if (!isIn.current) return;
				setSavedShipping(res);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	}, [token]);

	const selectShipping = (item) => {
		setSelectedShipping(item);
		setOpenShipping(false);
	};
	const submitShipping = () => {
		setLoading(true);
		buyerSaveShippingInfo(token, shippingForm)
			.then((res) => {
				if (!isIn.current) return;
				setSelectedShipping(res);
				setSavedShipping([...savedShipping, res]);
				setShippingForm(initialShippingForm);
				setOpenShipping(false);
				setLoading(false);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
				setLoading(false);
			});
	};

	useLayoutEffect(() => {
		loadProduct();
		loadSavedShippingInfos();
	}, [loadProduct, loadSavedShippingInfos]);

	useEffect(() => {
		if (timeout.current) clearTimeout(timeout.current);
		if (!selectedBid) return;
		timeout.current = setTimeout(() => {
			shopCalcTransactionFees("SELLER", { price: selectedBid.price, type: "BID", take_shipping_fee: takeShippingFee })
				.then((res) => {
					if (isIn.current) setFees(res);
				})
				.catch((err) => {
					if (isIn.current) toast.warning(extractErrors(err)[0]);
				});
		}, 3000);
	}, [selectedBid, takeShippingFee]);

	useEffect(() => {
		if (!selectedBid) return;
		shopGetDiscountCode(discountCode, selectedBid?.price)
			.then((res) => {
				setDiscountPrice(res);
			})
			.catch((err) => {
				setDiscountPrice(null);
			});
	}, [selectedBid, discountCode]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	if (!product || loading) {
		return (
			<div className="seller-list-sell-page">
				<div className="container">
					{loading ? (
						<div className="loading">
							<NormalLoading />
						</div>
					) : (
						<h1>Product does not exist</h1>
					)}
				</div>
			</div>
		);
	}
	if (localStorage.getItem(`sell_now_confirmed_${productId}`)) {
		return <SellerSellNowConfirmedPage product={product} />;
	}
	return (
		<div className="seller-list-sell-page">
			<div className="container ">
				<div className="bidScreenContainer">
					<div className="productImage">
						<img src={product.images[0]} alt={product.id} />
					</div>
					<div className="sellNow">
						<div className="top-headline">
							<div className="heading d-flex justify-content-between">
								<h2>{product.name}</h2>
								<h2>${numberToPriceString(selectedBid?.price)}</h2>
							</div>
							<ul className="details">
								<li>{product.size}</li>
								<li>{product.abv}% ABV</li>
								{product.has_box !== null && <li>{product.has_box ? "With" : "Without"} BOX</li>}
								{product.has_tag !== null && <li>{product.has_tag ? "With" : "Without"} TAG</li>}
								{product.has_bag !== null && <li>{product.has_bag ? "With" : "Without"} BAG</li>}
								{product.has_tin !== null && <li>{product.has_tin ? "With" : "Without"} TIN</li>}
								{product.has_case !== null && <li>{product.has_case ? "With" : "Without"} CASE</li>}
							</ul>
						</div>
						{localStorage.getItem(`sell_now_price_limit_exceed_${productId}`) ? (
							<SellerPriceLimitExceedComponent productId={productId} />
						) : selectedBid ? (
							<>
								<div className="discount mt-3">
									<input
										className="discount-code form-condivol"
										type="text"
										placeholder="DISCOUNT CODE"
										value={discountCode ?? ""}
										onChange={(e) => setDiscountCode(e.target.value)}
									/>
								</div>
								<div className="invoiceTable">
									<table className="table table-borderless">
										<tbody>
											{fees && (
												<>
													<tr>
														<td>{fees.details.listing.label}</td>
														<td>-${numberToPriceString(fees.details.listing.value)}</td>
													</tr>
													<tr>
														<td>{fees.details.processing.label}</td>
														<td>-${numberToPriceString(fees.details.processing.value)}</td>
													</tr>
													<tr>
														<td>{fees.details.transaction.label}</td>
														<td>-${numberToPriceString(fees.details.transaction.value)}</td>
													</tr>
													<tr>
														<td>{fees.details.shipping.label}</td>
														<td>-${numberToPriceString(fees.details.shipping.value)}</td>
													</tr>
												</>
											)}
											<tr>
												<td>DISCOUNT APPLIED</td>
												<td>${numberToPriceString(discountPrice?.result.value)}</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<th>TOTAL PAYOUT</th>
												<th>
													$
													{numberToPriceString(
														(selectedBid?.price ?? 0) -
															(fees?.sum ?? 0) +
															(isNaN(parseFloat(discountPrice?.result.value)) ? 0 : parseFloat(discountPrice?.result.value))
													)}
												</th>
											</tr>
										</tfoot>
									</table>
								</div>
								<div className="shippingInfo">
									{savedShipping && savedShipping.length > 0 && (
										<div className={`choosePaymentMethod collapse-content ${openShipping ? "show" : ""}`}>
											<h4>Choose Shipping Address </h4>
											{savedShipping.map((x) => (
												<p key={x.id} className={selectedShipping?.id === x.id ? "selected_item" : ""} onClick={selectShipping.bind(this, x)}>
													{x.first_name ?? ""} {x.last_name ?? ""} {strToPhoneNumberFormat(x.phone_number)} {x.address_1 ?? ""} {x.address_2 ?? ""}
													{x.city ?? ""} {x.zip_code ?? ""}
												</p>
											))}
										</div>
									)}
									<button className="collapsbtn" onClick={() => setOpenShipping(true)}>
										{!openShipping && selectedShipping
											? `${selectedShipping.first_name ?? ""} ${selectedShipping.last_name ?? ""} ${strToPhoneNumberFormat(selectedShipping.phone_number)} ${
													selectedShipping.address_1 ?? ""
											  } ${selectedShipping.address_2 ?? ""} ${selectedShipping.city ?? ""} ${selectedShipping.zip_code ?? ""}`
											: "Ship From Address"}
									</button>
									<div className={`collapse-content ${openShipping ? "show" : ""}`}>
										<div className="row px-2 py-3">
											<div className="col-md-6">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Full Name"
													name="first_name"
													value={shippingForm.first_name ?? ""}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-md-6">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Full Name"
													name="last_name"
													value={shippingForm.last_name ?? ""}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-12">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Street Address"
													name="address_1"
													value={shippingForm.address_1 ?? ""}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-12">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Street Address Second (optional)"
													name="address_2"
													value={shippingForm.address_2 ?? ""}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-md-5">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="City"
													name="city"
													value={shippingForm.city ?? ""}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-md-3 col-6">
												<input type="text" className="payment_input form-control" placeholder="State" value="CA" disabled />
											</div>
											<div className="col-md-4 col-6">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Zip Code"
													name="zip_code"
													value={shippingForm.zip_code ?? ""}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-md-12">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Special instructions 100 character limit"
													name="special_instruction"
													value={shippingForm.special_instruction ?? ""}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-md-12">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Phone number"
													name="phone_number"
													value={strToPhoneNumberFormat(shippingForm.phone_number)}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
										</div>
										<button className="confirmBtn" onClick={submitShipping}>
											confirm
										</button>
									</div>
								</div>
								<div className="checkGroup">
									<SellerCertifyBoxComponent value={mustCheck} setValue={setMustCheck} />
								</div>
								<div className="btnWrapper ">
									<button className="listBtn" onClick={submitSellNow}>
										SELL NOW
									</button>
								</div>
							</>
						) : (
							<h1 className="text-center text-white mt-6">Bidder does not exist</h1>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(SellerListSellPage);
