import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { Row, Col, Container, Nav, NavItem, Button } from "reactstrap";
import { createStructuredSelector } from "reselect";
import BuyerOrders from "../../components/buyer/buyer-orders.component";
import NormalLoading from "../../components/ui-kits/normal-loading.component";
import { extractErrors, isValidPhoneNumber, strToPhoneNumberFormat, strToPhoneNumberString } from "../../helpers/commonHelper";
import { getLang } from "../../helpers/languageHelper";
import * as AuthAction from "../../redux/auth/auth.action";
import { getProfile, getToken } from "../../redux/auth/auth.selector";
import { authSmsVerifyRequest, authUpdatePassword, sellerRequestConnectBank } from "../../services/ApiService";
import "./account-page.styles.scss";

const IntroBar = () => (
	<div className="inner-intro">
		<Container>
			<Row className="intro-title align-items-center">
				<Col md={6} className="text-left">
					<div className="intro-title-inner">
						<strong>{getLang("my_account")}</strong>
					</div>
				</Col>
				<Col md={6} className="text-right">
					<ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
						<li className="home">
							<span>
								<Link className="bread-link bread-home" to="/">
									{getLang("home")}
								</Link>
							</span>
						</li>
						<li>
							<span>{getLang("my_account")}</span>
						</li>
					</ul>
				</Col>
			</Row>
		</Container>
	</div>
);

const SideBar = ({ logout, activedTab, setActivedTab, role }) => (
	<Fragment>
		<Col lg={3}>
			<div className="woocommerce-MyAccount-navigation text-left">
				<Nav className="flex-column">
					<NavItem>
						<Link
							to="#"
							className={`${activedTab === "profile" ? "active" : ""} nav-link nav-custo AccountProfile`}
							onClick={() => setActivedTab("profile")}
							replace={true}
						>
							<i className="fa fa-user-o"></i> {getLang("profile")}
						</Link>
					</NavItem>
					<NavItem>
						<Link
							to="#"
							className={`${activedTab === "security" ? "active" : ""} nav-link nav-custo Address`}
							onClick={() => setActivedTab("security")}
							replace={true}
						>
							<i className="fa fa-map-marker"></i> {getLang("security")}
						</Link>
					</NavItem>
					{role === "BUYER" && (
						<NavItem>
							<Link
								to="#"
								className={`${activedTab === "orders" ? "active" : ""} nav-link nav-custo Orders`}
								onClick={() => setActivedTab("orders")}
								replace={true}
							>
								<i className="fa fa-tachometer"></i> {getLang("my_orders")}
							</Link>
						</NavItem>
					)}
					<NavItem>
						<Link to="#" className={`nav-link  nav-custo SavedCards`} replace={true}>
							<i className="fa fa-credit-card"></i> {getLang("saved_cards")}
						</Link>
					</NavItem>
					<NavItem>
						<button className="btn btn-link" onClick={logout}>
							<i className="fa fa-sign-out"></i> {getLang("log_out")}
						</button>
					</NavItem>
				</Nav>
			</div>
		</Col>
	</Fragment>
);

const SecurityBox = ({ token }) => {
	const [errors, setErrors] = useState();
	const [success, setSuccess] = useState();
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		old_password: "",
		password: "",
		password_confirmation: "",
	});
	const isIn = useRef();
	const inputHandle = (event) => {
		setErrors(null);
		setSuccess(null);
		const { name, value } = event.target;
		setForm({
			...form,
			[name]: value,
		});
	};
	const submit = (event) => {
		event.preventDefault();
		setErrors(null);
		setSuccess(null);
		if (form.password !== form.password_confirmation) {
			setErrors([getLang("password_confirmation_mismatch")]);
			return;
		}
		setLoading(true);
		authUpdatePassword(token, form)
			.then(() => {
				if (isIn.current) {
					setLoading(false);
					setSuccess(getLang("updated_successfully"));
					setForm({
						old_password: "",
						password: "",
						password_confirmation: "",
					});
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					setErrors(extractErrors(err));
				}
			});
	};
	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<Col lg={9} className="mt-4 mt-lg-0">
			<Row>
				<Col lg={12}>
					<div className="woocommerce-Address woocommerce-Address-edit text-left">
						<div className="woocommerce-Address-title">
							<h5 className="mb-0">{getLang("my_security")}</h5>
						</div>
						<div className="woocommerce-Address-info mt-4">
							{loading ? (
								<div className="loading">
									<NormalLoading />
								</div>
							) : (
								<form onSubmit={submit}>
									{errors &&
										errors.map((err, i) => (
											<div key={i} className="alert alert-danger">
												{getLang(err)}
											</div>
										))}
									{success && <div className="alert alert-success">{getLang(success)}</div>}
									<div className="form-group">
										<label>{getLang("current_password")}</label>
										<input
											type="password"
											className="form-control"
											placeholder={getLang("current_password")}
											required
											name="old_password"
											value={form.old_password}
											onChange={inputHandle}
										/>
									</div>
									<div className="form-group">
										<label>{getLang("new_password")}</label>
										<input
											type="password"
											className="form-control"
											placeholder={getLang("new_password")}
											required
											name="password"
											value={form.password}
											onChange={inputHandle}
										/>
									</div>
									<div className="form-group">
										<label>{getLang("confirm_password")}</label>
										<input
											type="password"
											className="form-control"
											placeholder={getLang("confirm_password")}
											required
											name="password_confirmation"
											value={form.password_confirmation}
											onChange={inputHandle}
										/>
									</div>
									<Button type="submit" className="btn btn-primary">
										{getLang("save")}
									</Button>
								</form>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</Col>
	);
};

const ProfileBox = ({ token, profile, submitForm, loadProfile }) => {
	const isIn = useRef();
	const [loading, setLoading] = useState(false);
	const [smsLoading, setSmsLoading] = useState(false);
	const [form, setForm] = useState({
		name: profile.name ?? "",
		phone_number: profile.phone_number ?? "",
		phone_number_verify_code: null,
	});
	const [sellerProfile, setSellerProfile] = useState({
		store_name: profile.seller_profile?.store_name ?? "",
		liquor_lic_num: profile.seller_profile?.liquor_lic_num ?? "",
		sellers_permit: profile.seller_profile?.sellers_permit ?? "",
		business_address: profile.seller_profile?.business_address ?? "",
		tax: profile.seller_profile?.tax ?? "",
	});
	const getBankName = () => {
		if (!profile.seller_profile || !profile.seller_profile.stripe_account) return "NONE";
		const stripe_account = profile.seller_profile.stripe_account;
		return `${stripe_account.business_profile?.mcc} ${stripe_account.status.toUpperCase()}`;
	};

	const inputHandle = (event) => {
		const { name, value } = event.target;
		if (name === "phone_number") {
			setForm({ ...form, [name]: strToPhoneNumberString(value) });
		} else {
			setForm({ ...form, [name]: value });
		}
	};
	const sellerInputHandle = (event) => {
		const { name, value } = event.target;
		setSellerProfile({ ...sellerProfile, [name]: value });
	};
	const submit = (event) => {
		event.preventDefault();
		if (!isValidPhoneNumber(form.phone_number)) {
			toast.error(getLang("phone_number_is_invalid"));
			return;
		}
		setLoading(true);
		const formData = { ...form };
		if (form.phone_number_verify_code == null) delete formData.phone_number;
		if (profile.isActivedSeller) formData.seller_profile = sellerProfile;
		submitForm(formData)
			.then(() => {
				if (isIn.current) {
					setLoading(false);
					toast.success(getLang("updated_successfully"));
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	};
	const smsVerifyRequest = (event) => {
		event.preventDefault();
		if (!isValidPhoneNumber(form.phone_number)) {
			toast.error(getLang("phone_number_is_invalid"));
			return;
		}
		setSmsLoading(true);
		authSmsVerifyRequest(form.phone_number)
			.then((res) => {
				setSmsLoading(false);
				setForm({ ...form, phone_number_verify_code: "" });
			})
			.catch((err) => {
				setSmsLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	};
	const connectBank = async (event) => {
		event.preventDefault();
		setLoading(true);
		try {
			let result = await sellerRequestConnectBank(token);
			const newWindow = window.open(result.url, "_blank");
			const timer = setInterval(() => {
				if (!newWindow.closed) return;
				clearInterval(timer);
				loadProfile()
					.then(() => {
						setLoading(false);
					})
					.catch((err) => {
						toast.error(extractErrors(err)[0]);
						setLoading(false);
					});
			}, 500);
		} catch (error) {
			setLoading(false);
			toast.error(extractErrors(error)[0]);
		}
	};

	useLayoutEffect(() => {
		setForm({
			name: profile.name ?? "",
			phone_number: profile.phone_number ?? "",
			phone_number_verify_code: null,
		});
		setSellerProfile({
			store_name: profile.seller_profile?.store_name ?? "",
			liquor_lic_num: profile.seller_profile?.liquor_lic_num ?? "",
			sellers_permit: profile.seller_profile?.sellers_permit ?? "",
			business_address: profile.seller_profile?.business_address ?? "",
			tax: profile.seller_profile?.tax ?? "",
		});
	}, [profile]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	if (loading) {
		return (
			<div className="loading">
				<NormalLoading />
			</div>
		);
	}
	return (
		<Col lg={9} className="mt-4 mt-lg-0">
			<form onSubmit={submit}>
				<Row>
					<Col md={profile.isActivedSeller ? 6 : 12}>
						<div className="woocommerce-Address woocommerce-Address-edit text-left">
							<div className="woocommerce-Address-title">
								<h5 className="mb-0">{getLang("my_profile")}</h5>
							</div>
							<div className="woocommerce-Address-info mt-4">
								<div>
									<div className="form-group">
										<label>{getLang("name")}</label>
										<input type="text" className="form-control" placeholder={getLang("name")} required name="name" value={form.name} onChange={inputHandle} />
									</div>
									<div className="form-group">
										<label>{getLang("email_address")}</label>
										<input type="email" className="form-control" placeholder={getLang("email")} disabled value={profile.email} />
									</div>
									<div className="form-group">
										<label>{getLang("phone_number")}</label>
										<div className="col-md-12 pl-0 pr-0">
											<div className="row justify-space-between">
												<div className="col-md-9">
													<input
														type="text"
														className="form-control"
														placeholder={getLang("phone_number")}
														required
														name="phone_number"
														value={strToPhoneNumberFormat(form.phone_number)}
														onChange={inputHandle}
													/>
												</div>
												<div className="col-md-3">
													{smsLoading ? (
														<Loader type="Puff" color="#04d39f" height="45" width="45" />
													) : (
														<button className="btn btn-primary" onClick={smsVerifyRequest} disabled={form.phone_number_verify_code != null}>
															{getLang("verify")}
														</button>
													)}
												</div>
											</div>
										</div>
									</div>
									{form.phone_number_verify_code != null && (
										<div className="form-group">
											<label>{getLang("phone_number_verify_code")} </label>
											<input
												type="text"
												className="form-control"
												placeholder={getLang("phone_number_verify_code")}
												required
												name="phone_number_verify_code"
												value={form.phone_number_verify_code}
												onChange={inputHandle}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</Col>
					{profile.isActivedSeller && (
						<Col md={6}>
							<div className="woocommerce-Address woocommerce-Address-edit text-left">
								<div className="woocommerce-Address-title">
									<h5 className="mb-0">{getLang("my_seller_profile")}</h5>
								</div>
								<div className="woocommerce-Address-info mt-4">
									<div className="form-group">
										<label>{getLang("store_name")}</label>
										<input
											type="text"
											className="form-control"
											placeholder={getLang("store_name")}
											required
											name="store_name"
											value={sellerProfile.store_name}
											onChange={sellerInputHandle}
										/>
									</div>

									<div className="form-group">
										<label>{getLang("liquor_lic_number")}</label>
										<input
											type="number"
											className="form-control"
											placeholder={getLang("liquor_lic_number")}
											required
											name="liquor_lic_num"
											value={sellerProfile.liquor_lic_num}
											onChange={sellerInputHandle}
										/>
									</div>
									<div className="form-group">
										<label>{getLang("sellers_permit")}</label>
										<input
											type="text"
											className="form-control"
											placeholder={getLang("sellers_permit")}
											required
											name="sellers_permit"
											value={sellerProfile.sellers_permit}
											onChange={sellerInputHandle}
										/>
									</div>
									<div className="form-group">
										<label>{getLang("business_address")}</label>
										<input
											type="text"
											className="form-control"
											placeholder={getLang("business_address")}
											required
											name="business_address"
											value={sellerProfile.business_address}
											onChange={sellerInputHandle}
										/>
									</div>
									<div className="form-group">
										<div className="seller-bank">
											<div className="current-connected-bank btn-link">{getBankName()}</div>
											<button className="btn-primary" onClick={connectBank}>
												Connect My Bank
											</button>
										</div>
									</div>
									<div className="form-group">
										<label>{getLang("tax")}</label>
										<input
											type="text"
											className="form-control"
											placeholder={`${getLang("tax")} (%)`}
											required
											name="tax"
											value={sellerProfile.tax}
											onChange={sellerInputHandle}
										/>
									</div>
								</div>
							</div>
						</Col>
					)}
					<Col md={1} className="mt-3 mb-3">
						<Button type="submit" className="btn btn-primary">
							{getLang("save")}
						</Button>
					</Col>
				</Row>
			</form>
		</Col>
	);
};

const AccountPage = ({ token, profile, updateProfile, loadProfile, logout }) => {
	const [activedTab, setActivedTab] = useState("profile");

	if (!token) {
		return <Redirect to="/" />;
	}
	return (
		<div className="account-page">
			<IntroBar />
			<div className="section-ptb">
				<Container>
					<Row>
						<SideBar logout={logout} activedTab={activedTab} setActivedTab={setActivedTab} role={profile.role} />
						{activedTab === "profile" && <ProfileBox token={token} profile={profile} submitForm={updateProfile} loadProfile={loadProfile} />}
						{activedTab === "security" && <SecurityBox token={token} />}
						{activedTab === "orders" && profile.role === "BUYER" && <BuyerOrders />}
					</Row>
				</Container>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
	profile: getProfile,
});

const mapDispatchToProps = (dispatch) => ({
	updateProfile: (form) => dispatch(AuthAction.updateProfile(form)),
	logout: () => dispatch(AuthAction.logout()),
	loadProfile: () => dispatch(AuthAction.loadProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
