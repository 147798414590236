import React from "react";
import "./age-gateway-page.styles.scss";
import ageLogo from "./age-verificaiton-desktop-copy-eliqx-logo-black-yellow-B8284058-E1B3-46C3-A47E-80F2264477F8@2x.png";
import ageBackground from "./age-verificaiton-desktop-copy-eliqx-ageverify-desktop-trimmed-AE417ACF-9E42-4CA4-8D24-A45FF20FA80E.png";
import { isAged } from "../../helpers/commonHelper";

const AgeGatewayPage = () => {
	const closeWindow = () => {
		window.close();
	};
	const agreeAged = () => {
		isAged(true);
		window.location.reload();
	};
	if (window.location.href !== window.origin.toString() + "/") {
		window.location.href = "/";
	}

	return (
		<div className="age-gateway-page">
			<div className="container-center-horizontal">
				<div className="ageverificaitondesktopcopy screen" style={{ backgroundImage: `url(${ageBackground})` }}>
					<div className="verification-div-C61RwL">
						<img className="eliqx-logo-black-yellow-kkbr1F" src={ageLogo} alt="age-logo" />
						<div className="are-you-over-21-kkbr1F">
							Are you over
							<br />
							21?
						</div>
						<div className="flex-row-kkbr1F">
							<div className="buttons">
								<button className="button" onClick={closeWindow}>
									NO
								</button>
								<button className="button" onClick={agreeAged}>
									YES
								</button>
							</div>
						</div>
						<div className="please-verify-your-a-kkbr1F">Please verify your age to access eliqx</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AgeGatewayPage;
