import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import BuyerProfileMenu from "./buyer-profile-menu.component";
import "./buyer-profile.styles.scss";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { buyerGetMyBids, buyerGetOrders } from "../../../services/ApiService";
import { toast } from "react-toastify";
import { extractErrors, numberToPriceString } from "../../../helpers/commonHelper";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";

const getItemNameDiv = (product) => (
	<span>
		<u className="cc-portfolio-main">{product.name}</u>
		<br />
		<u>{product.size}</u>
		<u>{product.abv}% ABV</u>
		{product.has_box !== null && <u>{product.has_box ? "With" : "Without"} BOX</u>}
		{product.has_tag !== null && <u>{product.has_tag ? "With" : "Without"} TAG</u>}
		{product.has_bag !== null && <u>{product.has_bag ? "With" : "Without"} BAG</u>}
		{product.has_tin !== null && <u>{product.has_tin ? "With" : "Without"} TIN</u>}
		{product.has_case !== null && <u>{product.has_case ? "With" : "Without"} CASE</u>}
	</span>
);

const TopTabs = ({ tab, path }) => (
	<div className="tabs">
		<Link className={`tab-item ${tab === "OPEN_BIDS" ? "active" : ""}`} to={`${path}?tab=OPEN_BIDS`}>
			OPEN OFFERS
		</Link>
		<Link className={`tab-item ${tab === "PURCHASED" ? "active" : ""}`} to={`${path}?tab=PURCHASED`}>
			ORDER STATUS
		</Link>
		<Link className={`tab-item ${tab === "MY_BOTTLES" ? "active" : ""}`} to={`${path}?tab=MY_BOTTLES`}>
			PORTFOLIO
		</Link>
	</div>
);

const MyBottlesBox = ({ token }) => {
	const isIn = useRef();
	const [loading, setLoading] = useState(false);
	const [orders, setOrders] = useState();

	const loadOrders = useCallback(() => {
		setLoading(true);
		buyerGetOrders(token)
			.then((res) => {
				if (isIn.current) {
					setOrders(res);
					setLoading(false);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [token]);

	useEffect(() => {
		loadOrders();
	}, [loadOrders]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="myBottles">
			{loading || !orders ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<table className="table">
					<thead>
						<tr>
							<th>ITEM NAME</th>
							<th>ORDER DATE</th>
							<th>PURCHASE PRICE</th>
							<th>LAST SOLD</th>
							<th>HIGH OFFER</th>
						</tr>
					</thead>
					<tbody>
						{orders.map((item) => (
							<tr key={item.id}>
								<td data-th="ITEM NAME">
									{!!item.product ? (
										<Link to={`/shop/product/${item.product.id}`}>
											<span>{getItemNameDiv(item.product)}</span>
										</Link>
									) : (
										<>Product does not exist</>
									)}
								</td>
								<td data-th="DATE PURCHASED">
									<span>{item.created_at.split(" ")[0]}</span>
								</td>
								<td data-th="PRICE">
									<span>${numberToPriceString(item.price)}</span>
								</td>
								<td data-th="LAST SOLD">
									<span>
										<u>${numberToPriceString(item?.product?.last_sold?.price)}</u>
									</span>
								</td>
								<td data-th="HIGH BID">
									<span>
										<u>${numberToPriceString(item?.product?.highest_bid?.price)}</u>
									</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};

const OpenBidsBox = ({ token }) => {
	const isIn = useRef();
	const [bids, setBids] = useState();
	const [loading, setLoading] = useState(false);

	const loadBids = useCallback(() => {
		setLoading(true);
		buyerGetMyBids(token)
			.then((res) => {
				if (isIn.current) {
					setBids(res.filter((x) => x.status === "PENDING" || x.status === "EXPIRED"));
					setLoading(false);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [token]);

	useEffect(() => {
		loadBids();
	}, [loadBids]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="openBids">
			{loading || !bids ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<table className="table">
					<thead>
						<tr>
							<th>ITEM NAME</th>
							<th>EXPIRES</th>
							<th>OFFERS</th>
							<th>BUY NOW</th>
						</tr>
					</thead>
					<tbody>
						{bids.map((item) => (
							<tr key={item.id}>
								<td data-th="ITEM NAME">
									<Link to={`/shop/product/${item.product.id}`}>
										<span>{getItemNameDiv(item.product)}</span>
									</Link>
								</td>
								<td data-th="EXPIRES">
									<span>{item.expired_at}</span>
								</td>
								<td data-th="BIDS">
									<span className="ming">
										<Link to={`/buyer/place-bid/${item.product.id}?bid=${item.id}`}>
											<u>${numberToPriceString(item.price)}</u>
										</Link>
										<Link to={`/buyer/place-bid/${item.product.id}?bid=${item.id}`} className="edit">
											<i className="icon-cc-pencil"></i>
										</Link>
									</span>
								</td>
								<td data-th="BUY NOW" className="buttons">
									<span className="orange-soda">
										<u>${numberToPriceString(item.product.min_price)}</u>
										{item.status === "PENDING" ? (
											<Link
												to={item.product.min_price ? `/buyer/place-order/${item.product.id}` : "#"}
												className={item.product.min_price ? "button buy-now" : "button buy-now disable"}
											>
												BUY NOW
											</Link>
										) : (
											<Link to={`/buyer/place-bid/${item.product.id}?bid=${item.id}`} className="button buy-now">
												RENEW OFFER
											</Link>
										)}
										<Link to={`/buyer/remove-bid/${item.id}`} className="delete">
											<i className="icon-cc-trash"></i>
										</Link>
									</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};

const PurchasedBox = ({ token }) => {
	const isIn = useRef();
	const [loading, setLoading] = useState(false);
	const [orders, setOrders] = useState();

	const loadOrders = useCallback(() => {
		setLoading(true);
		buyerGetOrders(token)
			.then((res) => {
				if (isIn.current) {
					res.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
					setOrders(res);
					setLoading(false);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [token]);

	useEffect(() => {
		loadOrders();
	}, [loadOrders]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="purchased">
			{loading || !orders ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<table className="table">
					<thead>
						<tr>
							<th>ITEM NAME</th>
							<th>DATE</th>
							<th>ORDER#</th>
							<th>PRICE</th>
							<th>STATUS</th>
						</tr>
					</thead>
					<tbody>
						{orders.map((item) => (
							<tr key={item.id}>
								<td data-th="ITEM NAME">
									{!!item.product ? (
										<Link to={`/shop/product/${item.product.id}`}>
											<span>{getItemNameDiv(item.product)}</span>
										</Link>
									) : (
										<>Product does not exist</>
									)}
								</td>
								<td data-th="DATE">
									<span>{item.created_at.split(" ")[0]}</span>
								</td>
								<td data-th="ORDER #">
									<span>#{item.id}</span>
								</td>
								<td data-th="PRICE">
									<span>${numberToPriceString(item.price)}</span>
								</td>
								<td data-th="STATUS" className="status">
									<u>
										<a href={item.track_shipping_url ?? "#"} target="_blank" rel="noopener noreferrer">
											{item.status_shipping}
										</a>
									</u>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};

const BuyerPortfolioPage = ({ token, location }) => {
	const tab = new URLSearchParams(location.search).get("tab") ?? "OPEN_BIDS";

	return (
		<div className="buyer-portfolio-page">
			<div className="dashboard">
				<BuyerProfileMenu />
				<div className="dashboard-main">
					<TopTabs tab={tab} path={location.pathname} />
					{tab === "OPEN_BIDS" && <OpenBidsBox token={token} />}
					{tab === "MY_BOTTLES" && <MyBottlesBox token={token} />}
					{tab === "PURCHASED" && <PurchasedBox token={token} />}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(BuyerPortfolioPage);
