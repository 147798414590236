import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import { extractErrors, numberToPriceString } from "../../helpers/commonHelper";
import { getToken } from "../../redux/auth/auth.selector";
import { getSearchKeyword } from "../../redux/shop/shop.selector";
import { shopGetProducts } from "../../services/ApiService";
import NormalLoading from "../ui-kits/normal-loading.component";
import "./shop.styles.scss";

const PackageBox = ({ product }) => (
	<div className="product-details">
		<p className="product-detail">{product.size}</p>
		<p className="product-detail">{product.abv}% ABV</p>
		{product.has_box !== null && <p className="product-detail">{product.has_box ? "With" : "Without"} BOX</p>}
		{product.has_tag !== null && <p className="product-detail">{product.has_tag ? "With" : "Without"} TAG</p>}
		{product.has_bag !== null && <p className="product-detail">{product.has_bag ? "With" : "Without"} BAG</p>}
		{product.has_tin !== null && <p className="product-detail">{product.has_tin ? "With" : "Without"} TIN</p>}
		{product.has_case !== null && <p className="product-detail">{product.has_case ? "With" : "Without"} CASE</p>}
	</div>
);

const HomepageAllProducts = ({ token, keyword }) => {
	const [products, setProducts] = useState();
	const [loading, setLoading] = useState(false);
	const isIn = useRef();

	const loadData = useCallback(() => {
		setLoading(true);
		shopGetProducts({ keyword, status: "APPROVED" }, 15, 1, token)
			.then((res) => {
				if (!isIn.current) return;
				setLoading(false);
				setProducts(res.data);
			})
			.catch((err) => {
				if (!isIn.current) return;
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	}, [keyword, token]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="homepage-all-products">
			<div className="carousel-title">
				<h2 className="category-title">newest listings</h2>
				<p className="category-see-all">
					<Link to="/shop/products">see all</Link>
				</p>
			</div>
			{loading || !products ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<div className="all-products-row">
					{products.map((product) => (
						<div className="product-card" key={product.id}>
							<div className="product-card-link">
								<Link to={`/shop/product/${product.id}`}>
									<img className="product-img" src={product.images[0]} alt="eliqx rare spirits product profile" />
								</Link>
								<div className="product-info">
									<Link to={`/shop/product/${product.id}`}>
										<h2 className="product-name">{product.name}</h2>
									</Link>
									<PackageBox product={product} />
									<div className="product-price-block">
										<Link to={`/shop/product/${product.id}`}>
											<span className="product-listing-current-price">${numberToPriceString(product.min_price)}</span>
										</Link>
										<span className="product-listing-status">Lowest Listing</span>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
	keyword: getSearchKeyword,
});

export default connect(mapStateToProps)(HomepageAllProducts);
