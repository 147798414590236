import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { getIsActivedSeller, getRole, getToken } from "../../redux/auth/auth.selector";
import "./shop.styles.scss";
import "./shop.styles.css";
import logoBlue from "../../assets/images/eliqx-logo-blue.svg";
import { toggleHeaderProfileMenuShow } from "../../redux/setting/setting.action";
import { getHeaderProfileMenuShow } from "../../redux/setting/setting.selector";
import { logout } from "../../redux/auth/auth.action";

const buyerItems = [
	{
		link: "/buyer/profile-portfolio",
		label: "dashboard",
	},
	{
		link: "/buyer/profile-profile",
		label: "profile",
	},
	{
		link: "/buyer/profile-portfolio?tab=PURCHASED",
		label: "your orders",
	},
	{
		link: "/buyer/account-settings",
		label: "settings",
	},
];

const sellerItems = [
	{
		link: "/seller/profile-portfolio",
		label: "dashboard",
	},
	{
		link: "/seller/make-product-listing",
		label: "create listing",
	},
	{
		link: "/seller/profile-profile",
		label: "billing info",
	},
	{
		link: "/seller/profile-portfolio?tab=SOLD",
		label: "sell orders",
	},
	{
		link: "/seller/buyer-portfolio?tab=PURCHASED",
		label: "buy orders",
	},
	{
		link: "/seller/account-settings",
		label: "settings",
	},
];

const HeaderProfileMenu = ({ token, profileMenuShow, history, toggleProfileMenu, logout, role, isActivedSeller }) => {
	const logoutClick = () => {
		toggleProfileMenu(false);
		logout().then(() => history.replace("/login"));
	};
	const routes = role === "ADMIN" ? [] : isActivedSeller ? sellerItems : buyerItems;

	if (!profileMenuShow) return <div></div>;
	return (
		<div className="header-profile-menu" onMouseLeave={() => toggleProfileMenu(false)}>
			<div className="menu-container">
				<div className="menu-content">
					<h4 className="menu-title">profile</h4>
					<ul className="menu-items">
						{!token && (
							<li className="menu-item">
								<Link className="cc-login" to="/login" onClick={() => toggleProfileMenu(false)}>
									login
								</Link>
							</li>
						)}
						{token && role === "ADMIN" && (
							<li className="menu-item">
								<Link to="/admin/dashboard" onClick={() => toggleProfileMenu(false)}>
									admin dashboard
								</Link>
							</li>
						)}
						{token &&
							routes.map((item, i) => (
								<li className="menu-item" key={i}>
									<Link to={item.link} onClick={() => toggleProfileMenu(false)}>
										{item.label}
									</Link>
								</li>
							))}
						{token && (
							<li className="menu-item">
								<Link className="cc-logout" to="#" onClick={logoutClick} replace={true}>
									logout
								</Link>
							</li>
						)}
					</ul>
					<Link to="#" replace={true}>
						<img className="menu-logo-center" src={logoBlue} alt="menu-logo-center" />
					</Link>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
	profileMenuShow: getHeaderProfileMenuShow,
	role: getRole,
	isActivedSeller: getIsActivedSeller,
});

const mapDispatchToProps = (dispatch) => ({
	toggleProfileMenu: (val = null) => dispatch(toggleHeaderProfileMenuShow(val)),
	logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderProfileMenu));
