import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import "./App.css";
import "./Vendor.js";
import * as AuthAction from "./redux/auth/auth.action";
import { getIsActivedSeller, getProfile, getRole, getToken } from "./redux/auth/auth.selector";

import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import HomePage from "./pages/home/home-page.component";
import Loader from "react-loader-spinner";

import LoginRegisterPage from "./pages/login-register/login-register-page.component";
import CallbackAuthorization from "./pages/login-register/call-back-authorization-page.component";
import AccountPage from "./pages/account/account-page.component";
import PageNotFound from "./pages/page-not-found.component";
import EmailVerifyPage from "./pages/login-register/email-verify-page.component";

import BuyerPlaceBidPage from "./pages/buyer/place-bid/buyer-place-bid-page.component";
import BuyerPlaceOrderPage from "./pages/buyer/place-order/buyer-place-order-page.component";
import BrowseProductsPage from "./pages/shop/browse-products/browse-products-page.component";
import ProductDetailPage from "./pages/shop/product-detail/product-detail-page.component";
import BuyerPortfolioPage from "./pages/buyer/buyer-profile/buyer-portfolio-page.component";
import BuyerProfilePage from "./pages/buyer/buyer-profile/buyer-profile-page.component";
import BuyerAccountSecurityPage from "./pages/buyer/buyer-profile/buyer-account-security-page.component";
import BuyerAccountSettingsPage from "./pages/buyer/buyer-profile/buyer-account-settings-page.component";
import BuyerRemoveBidPage from "./pages/buyer/place-bid/buyer-remove-bid-page.component";
import BuyerProfileWatchListPage from "./pages/buyer/buyer-profile/buyer-profile-watchlist-page.component";
import BuyerRegisterSellerProfilePage from "./pages/buyer/buyer-register-seller-profile/buyer-register-seller-profile-page.component";
import BuyerFaqPage from "./pages/legal/buyer-faq-page.component";

import SellerListProductPage from "./pages/seller/seller-list-product/seller-list-product-page.component";
import SellerAcceptBidPage from "./pages/seller/seller-accept-bid/seller-accept-bid-page.component";
import SellerProfilePortfolioPage from "./pages/seller/seller-profile/seller-profile-portfolio-page.component";
import SellerProfilePage from "./pages/seller/seller-profile/seller-profile-page.component";
import SellerAccountSettingsPage from "./pages/seller/seller-profile/seller-account-settings-page.component";
import SellerProfileWatchListPage from "./pages/seller/seller-profile/seller-profile-watchlist-page.component";
import SellerRemoveListingPage from "./pages/seller/seller-list-product/seller-remove-listing-page.component";
import SellerProfileBuyPortfolioPage from "./pages/seller/seller-profile/seller-porfile-buy-portfolio-page.component";
import SellerMakeProductListingPage from "./pages/seller/seller-make-product-listing/seller-make-product-listing-page.component";
import SellerFaqPage from "./pages/legal/seller-faq-page.component";
import SellerAccountSecurityPage from "./pages/seller/seller-profile/seller-account-security-page.component";
import SellerOnCallPickupPage from "./pages/seller/seller-profile/seller-on-call-pickup-page.component";
import SellerListSellPage from "./pages/seller/seller-list-sell/seller-list-sell-page.component";
import SellerLegalTermsOfUse from "./pages/seller/seller-legal-terms-of-use/seller-legal-terms-of-use.component";

import AdminHeader from "./components/admin/admin-header.component";
import AdminDashboardPage from "./pages/admin/admin-dashboard-page.component";
import AdminOrdersPage from "./pages/admin/admin-orders-page.component";
import AdminBidsPage from "./pages/admin/admin-bids-page.component";
import AdminUsersPage from "./pages/admin/admin-users-page.component";
import AdminProductsPage from "./pages/admin/admin-products-page.component";
import AdminProductUpdatePage from "./pages/admin/admin-product-update-page.component";
import AdminProductAddPageComponent from "./pages/admin/admin-product-add-page.component";
import AdminProductDetailPage from "./pages/admin/admin-product-detail-page.component";
import AdminUserDetailPage from "./pages/admin/admin-user-detail-page.component";
import AdminOrderDetailPage from "./pages/admin/admin-order-detail-page.component";
import AdminDiscountCodesPage from "./pages/admin/admin-discount-codes-page.component";
import AdminSiteSettingsPage from "./pages/admin/admin-site-settings-page.component";
import AdminAddressesPage from "./pages/admin/admin-address/admin-addresses-page.component";

import LegalPrivacyPolicyPage from "./pages/legal/legal-privacy-policy-page.component";
import LegalRefundPolicyPage from "./pages/legal/legal-refund-policy-page.component";
import LegalTermsOfUsePage from "./pages/legal/legal-terms-of-use-page.component";
import LegalAboutPage from "./pages/legal/legal-about-page.component";
import ContactUsPage from "./pages/legal/contact-us-page.component";
import BuyerGuidePage from "./pages/legal/buyer-guide-page.component";
import SellerGuidePage from "./pages/legal/seller-guide-page.component";
import SellerRegisterConfirmPage from "./pages/legal/seller-register-confirm-page.component";

import AgeGatewayPage from "./pages/age-gateway/age-gateway-page.component";
import { isAged, isPassedProtection } from "./helpers/commonHelper";
import ForgotPasswordPage from "./pages/forgot-reset-password/forgot-password-page.component";
import ResetPasswordPage from "./pages/forgot-reset-password/reset-password-page.component";

import PasswordGatewayPage from "./pages/age-gateway/password-gateway-page.component";

const SellerRoutes = [
	<Route key="seller-list-product" exact path="/seller/list-product/:productId" component={SellerListProductPage} />,
	<Route key="seller-remove-listing" exact path="/seller/remove-listing/:listingId" component={SellerRemoveListingPage} />,
	<Route key="seller-accept-bid" exact path="/seller/accept-bid/:productId" component={SellerAcceptBidPage} />,
	<Route key="seller-list-sell" exact path="/seller/list-sell/:productId" component={SellerListSellPage} />,
	<Route key="seller-profile-portfolio" exact path="/seller/profile-portfolio" component={SellerProfilePortfolioPage} />,
	<Route key="seller-profile" exact path="/seller/profile-profile" component={SellerProfilePage} />,
	<Route key="seller-account-settings" exact path="/seller/account-settings" component={SellerAccountSettingsPage} />,
	<Route key="seller-profile-watchlist" exact path="/seller/profile-watchlist" component={SellerProfileWatchListPage} />,
	<Route key="seller-profile-buy-portfolio" exact path="/seller/buyer-portfolio" component={SellerProfileBuyPortfolioPage} />,
	<Route key="seller-make-product-listing" exact path="/seller/make-product-listing" component={SellerMakeProductListingPage} />,
	<Route key="seller-account-security" exact path="/seller/account-security" component={SellerAccountSecurityPage} />,
	<Route key="seller-faq" exact path="/seller/faq" component={SellerFaqPage} />,
	<Route key="seller-on-call-pickup" exact path="/seller/profile-on-call-pickup" component={SellerOnCallPickupPage} />,
	<Route key="seller-guide" exact path="/seller/guide" component={SellerGuidePage} />,
	<Route key="seller-legal-terms-of-use" exact path="/seller/legal/terms-of-use" component={SellerLegalTermsOfUse} />,
];

const BuyerRoutes = [
	<Route key="buyer-place-bid" exact path="/buyer/place-bid/:productId" component={BuyerPlaceBidPage} />,
	<Route key="buyer-remove-bid" exact path="/buyer/remove-bid/:bidId" component={BuyerRemoveBidPage} />,
	<Route key="buyer-place-order" exact path="/buyer/place-order/:productId" component={BuyerPlaceOrderPage} />,
	<Route key="buyer-profile-portfolio" exact path="/buyer/profile-portfolio" component={BuyerPortfolioPage} />,
	<Route key="buyer-profile-profile" exact path="/buyer/profile-profile" component={BuyerProfilePage} />,
	<Route key="buyer-account-security" exact path="/buyer/account-security" component={BuyerAccountSecurityPage} />,
	<Route key="buyer-account-settings" exact path="/buyer/account-settings" component={BuyerAccountSettingsPage} />,
	<Route key="buyer-profile-watchlist" exact path="/buyer/profile-watchlist" component={BuyerProfileWatchListPage} />,
	<Route key="email-verify-link" exact path="/email-verify-link/:link" component={EmailVerifyPage} />,
	<Route key="buyer-register-seller-profile" exact path="/buyer/register-seller-profile" component={BuyerRegisterSellerProfilePage} />,
	<Route key="buyer-seller-register-confirm" exact path="/buyer/seller-register-confirm" component={SellerRegisterConfirmPage} />,
];

const AdminRoutes = [
	<Route key="admin-dashboard" exact path="/admin/dashboard" component={AdminDashboardPage} />,
	<Route key="admin-orders" exact path="/admin/orders" component={AdminOrdersPage} />,
	<Route key="admin-order-detail" exact path="/admin/order/detail/:orderId" component={AdminOrderDetailPage} />,
	<Route key="admin-bids" exact path="/admin/bids" component={AdminBidsPage} />,
	<Route key="admin-users" exact path="/admin/users" component={AdminUsersPage} />,
	<Route key="admin-users" exact path="/admin/user/detail/:userId" component={AdminUserDetailPage} />,
	<Route key="admin-products" exact path="/admin/products" component={AdminProductsPage} />,
	<Route key="admin-product-skus" exact path="/admin/product/detail/:productId" component={AdminProductDetailPage} />,
	<Route key="admin-product-detail" exact path="/admin/product/update/:productId" component={AdminProductUpdatePage} />,
	<Route key="admin-product-add" exact path="/admin/product/add" component={AdminProductAddPageComponent} />,
	<Route key="admin-discount-codes" exact path="/admin/discount_codes" component={AdminDiscountCodesPage} />,
	<Route key="admin-site-settings" exact path="/admin/site-settings" component={AdminSiteSettingsPage} />,
	<Route key="admin-addresses" exact path="/admin/addresses" component={AdminAddressesPage} />,
];

const LegalRoutes = [
	<Route key="legal-privacy-policy" exact path="/legal/privacy-policy" component={LegalPrivacyPolicyPage} />,
	<Route key="legal-refund-policy" exact path="/legal/return-policy" component={LegalRefundPolicyPage} />,
	<Route key="legal-terms-of-use" exact path="/legal/terms-of-use" component={LegalTermsOfUsePage} />,
	<Route key="buyer-faq" exact path="/buyer/faq" component={BuyerFaqPage} />,
	<Route key="legal-about" exact path="/legal/about" component={LegalAboutPage} />,
	<Route key="contact-us" exact path="/legal/contact-us" component={ContactUsPage} />,
	<Route key="shop-product-detail" exact path="/shop/product/:productId" component={ProductDetailPage} />,
	<Route key="shop-products" exact path="/shop/products" component={BrowseProductsPage} />,
	<Route key="buyer-guide" exact path="/buyer/guide" component={BuyerGuidePage} />,
];

const GuestRoutes = [
	<Route key="forgot-password" exact path="/forgot-password" component={ForgotPasswordPage} />,
	<Route key="reset-password" exact path="/:userId/reset-password" component={ResetPasswordPage} />,
];

const HeaderSection = ({ pathname }) => {
	if (pathname.split("/")[1] === "admin") {
		return <AdminHeader />;
	} else {
		return <Header />;
	}
};

const App = ({ location, role, loadProfile, isActivedSeller, profile, token }) => {
	const [appLoading, setAppLoading] = useState(false);

	useEffect(() => {
		setAppLoading(true);
		loadProfile().then(() => {
			setAppLoading(false);
		});
	}, [loadProfile]);

	if (!isPassedProtection()) {
		return <PasswordGatewayPage />;
	}
	if (appLoading) {
		return (
			<div className="App">
				<div id="preloader">
					<Loader type="Puff" color="#04d39f" height="100" width="100" />
				</div>
			</div>
		);
	}
	if (!isAged()) {
		return <AgeGatewayPage />;
	}

	return (
		<div className="App">
			<HeaderSection pathname={location.pathname} />

			<Switch>
				<Route exact path="/" component={HomePage} />
				<Route exact path="/authorization-handle-callback" component={CallbackAuthorization} />
				<Route exact path="/login" component={LoginRegisterPage} />
				<Route exact path="/my-account" component={AccountPage} />
				{profile?.status === "ACTIVED" && BuyerRoutes}
				{isActivedSeller && SellerRoutes}
				{role === "ADMIN" && AdminRoutes}
				{LegalRoutes}
				{!token && GuestRoutes}
				<Route exact component={PageNotFound} />
			</Switch>

			<Footer />
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	role: getRole,
	isActivedSeller: getIsActivedSeller,
	profile: getProfile,
	token: getToken,
});

const mapDispatchToProps = (dispatch) => ({
	loadProfile: () => dispatch(AuthAction.loadProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
