import React, { useCallback, useEffect, useState, useLayoutEffect, useRef } from "react";
import { connect } from "react-redux";
import { getToken } from "../../redux/auth/auth.selector";
import { Col, Container, Row } from "reactstrap";
import { createStructuredSelector } from "reselect";
import "./admin.styles.scss";
import { adminGetSiteSettings, adminUpdateSiteSetting } from "../../services/ApiService";
import { toast } from "react-toastify";
import { extractErrors, strToFloat } from "../../helpers/commonHelper";

const strToPercent = (str) => {
	return strToFloat(str) + "%";
};

const strToFixed = (str) => {
	return "$" + strToFloat(str);
};

const strToNum = (str, isPercent = false) => {
	let result = parseFloat(strToFloat(str));
	if (isPercent) result /= 100;
	return result;
};

const isPercentField = (key) => {
	return (
		key === "seller_transaction_bid_fee" ||
		key === "buyer_service_fee" ||
		key === "buyer_insurance_fee" ||
		key === "seller_processing_fee" ||
		key === "seller_transaction_buy_fee"
	);
};

const AdminSiteSettingsPage = ({ token }) => {
	const isIn = useRef();
	const [settings, setSettings] = useState();

	const inputHandle = (event) => {
		let { name, value } = event.target;
		if (isPercentField(name)) value = strToPercent(value);
		else value = strToFixed(value);
		setSettings({ ...settings, [name]: value });
	};
	const saveSettings = () => {
		let form = [];
		for (let k in settings) {
			const value = isPercentField(k) ? strToNum(settings[k], true) : strToNum(settings[k], false);
			form.push({ key: k, value });
		}
		adminUpdateSiteSetting(token, form)
			.then((res) => {
				toast.success("Updated successfully");
				loadSettings();
			})
			.catch((err) => {
				toast.error(extractErrors(err)[0]);
			});
	};
	const loadSettings = useCallback(() => {
		adminGetSiteSettings(token)
			.then((res) => {
				let form = {};
				for (let k in res) {
					let value = strToFloat(res[k]);
					if (isPercentField(k)) value = (parseFloat(value) * 100).toFixed(2) + "%";
					else value = "$" + value;
					form[k] = value;
				}
				setSettings(form);
			})
			.catch((err) => {
				toast.error(extractErrors(err)[0]);
			});
	}, [token]);

	useLayoutEffect(() => {
		loadSettings();
	}, [loadSettings]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="admin-site-settings-page">
			<Container>
				<Col md={12}>
					<h2 className="order-title">Site Settings</h2>
					<Row style={{ justifyContent: "space-around" }}>
						<Col md={6}>
							{settings && (
								<table className="table table-bordered">
									<tbody>
										<tr>
											<th>Buyer Service fee</th>
											<td>
												<input type="text" name="buyer_service_fee" value={settings.buyer_service_fee} onChange={inputHandle.bind(this)} />
											</td>
										</tr>
										<tr>
											<th>Buyer Shipping & Handling</th>
											<td>
												<input type="text" name="buyer_shipping_fee" value={settings.buyer_shipping_fee} onChange={inputHandle.bind(this)} />
											</td>
										</tr>
										<tr>
											<th>Buyer Insurance fee</th>
											<td>
												<input type="text" name="buyer_insurance_fee" value={settings.buyer_insurance_fee} onChange={inputHandle.bind(this)} />
											</td>
										</tr>
									</tbody>
								</table>
							)}
						</Col>
						<Col md={6}>
							{settings && (
								<table className="table table-bordered">
									<tbody>
										<tr>
											<th>Seller Listing fee</th>
											<td>
												<input type="text" name="seller_listing_fee" value={settings.seller_listing_fee} onChange={inputHandle.bind(this)} />
											</td>
										</tr>
										<tr>
											<th>Seller Processing fee</th>
											<td>
												<input type="text" name="seller_processing_fee" value={settings.seller_processing_fee} onChange={inputHandle.bind(this)} />
											</td>
										</tr>
										<tr>
											<th>Seller Transaction fee (when sell to buyer)</th>
											<td>
												<input type="text" name="seller_transaction_buy_fee" value={settings.seller_transaction_buy_fee} onChange={inputHandle.bind(this)} />
											</td>
										</tr>
										<tr>
											<th>Seller Transaction fee (when sell to bidder)</th>
											<td>
												<input type="text" name="seller_transaction_bid_fee" value={settings.seller_transaction_bid_fee} onChange={inputHandle.bind(this)} />
											</td>
										</tr>
										<tr>
											<th>Seller Shipping fee With Package</th>
											<td>
												<input
													type="text"
													name="seller_shipping_fee_with_package"
													value={settings.seller_shipping_fee_with_package}
													onChange={inputHandle.bind(this)}
												/>
											</td>
										</tr>
										<tr>
											<th>Seller Shipping fee Without Package</th>
											<td>
												<input
													type="text"
													name="seller_shipping_fee_without_package"
													value={settings.seller_shipping_fee_without_package}
													onChange={inputHandle.bind(this)}
												/>
											</td>
										</tr>
										<tr>
											<th>Minimum listing price</th>
											<td>
												<input
													type="text"
													name="seller_minimum_listing_price"
													value={settings.seller_minimum_listing_price}
													onChange={inputHandle.bind(this)}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							)}
						</Col>
					</Row>
					<button onClick={saveSettings}>Save</button>
				</Col>
			</Container>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(AdminSiteSettingsPage);
