import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import { extractErrors, numberToPriceString } from "../../../helpers/commonHelper";
import { getToken } from "../../../redux/auth/auth.selector";
import { buyerGetMyBidById, buyerSubmitBid, buyerUpdateMyBid, shopCalcTransactionFees, shopGetProductDetail } from "../../../services/ApiService";
import BuyerBuyNowComponent from "./buyer-buy-now.component";
import BuyerMultiBidConfirmPage from "./buyer-multi-bid-confirm-page.component";
import BuyerSaveCardForm from "../../../components/buyer/buyer-save-card-form/buyer-save-card-form.component";
import BuyerSaveShippingForm from "../../../components/buyer/buyer-save-shipping-form/buyer-save-shipping-form.component";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";
import "./buyer-place-bid-page.styles.scss";

const BuyerPlaceBidPage = ({ token, match, location, history }) => {
	const isIn = useRef();
	const timeout = useRef();
	const productId = match.params.productId;
	const bidId = new URLSearchParams(location.search).get("bid");
	const [product, setProduct] = useState();
	const [loading, setLoading] = useState(false);
	const [bidPrice, setBidPrice] = useState("");
	const [bidLength, setBidLength] = useState(30);
	const [fees, setFees] = useState();
	const [onShippingInsurance, setOnShippingInsurance] = useState(false);
	const [onSalesService, setOnSalesService] = useState(false);
	const [selectedCard, setSelectedCard] = useState();
	const [selectedShipping, setSelectedShipping] = useState();
	const [listing, setListing] = useState();

	const inputBidPrice = (event) => {
		setBidPrice(event.target.value);
	};
	const bidLengthChange = (event) => {
		setBidLength(event.target.value);
	};
	const goToBuyNow = () => {
		history.push(`/buyer/place-order/${productId}`);
	};

	const loadMyBid = useCallback(() => {
		if (!bidId) {
			setLoading(false);
			return;
		}
		setLoading(true);
		buyerGetMyBidById(token, bidId)
			.then((res) => {
				if (!isIn.current) return;
				if (res) {
					setBidPrice(res.price);
					setSelectedCard(res.billing);
					setSelectedShipping(res.shipping);
				}
				setLoading(false);
			})
			.catch((err) => {
				if (isIn.current) return;
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	}, [token, bidId]);

	const loadProduct = useCallback(() => {
		setLoading(true);
		shopGetProductDetail(productId, token, null, true)
			.then((res) => {
				if (!isIn.current) return;
				setProduct(res);
				if (res.seller_products) {
					res.seller_products.sort((a, b) => (a.price < b.price ? -1 : 1));
					if (res.seller_products.length > 0) setListing(res.seller_products[0]);
				}
				loadMyBid();
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	}, [productId, token, loadMyBid]);

	const submitBid = () => {
		if (!selectedCard || !selectedShipping) {
			toast.error("Please select payment method, and shipping info");
			return;
		}
		const form = {
			price: bidPrice,
			bid_length: bidLength,
			billing_id: selectedCard.id,
			shipping_id: selectedShipping.id,
			status: "PENDING",
		};
		setLoading(true);
		if (bidId) {
			buyerUpdateMyBid(token, bidId, form)
				.then((res) => {
					localStorage.setItem(`bid_confirmed_${productId}`, JSON.stringify(res));
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				});
		} else {
			buyerSubmitBid(token, productId, form)
				.then((res) => {
					localStorage.setItem(`bid_confirmed_${productId}`, JSON.stringify(res));
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				});
		}
	};

	useLayoutEffect(() => {
		loadProduct();
	}, [loadProduct]);

	useEffect(() => {
		if (timeout.current) clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			const price = isNaN(parseFloat(bidPrice)) ? 0 : parseFloat(bidPrice);
			shopCalcTransactionFees("BUYER", { price })
				.then((res) => {
					if (isIn.current) setFees(res);
				})
				.catch((err) => {
					if (isIn.current) toast.error(extractErrors(err)[0]);
				});
		}, 3000);
	}, [bidPrice]);

	useLayoutEffect(() => loadProduct(), [loadProduct]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	if (product && localStorage.getItem(`bid_confirmed_${product.id}`)) {
		return <BuyerMultiBidConfirmPage product={product} />;
	}
	if (!isNaN(parseFloat(bidPrice)) && product && listing && parseFloat(listing.price) <= parseFloat(bidPrice)) {
		return <BuyerBuyNowComponent token={token} product={product} listing={listing} goToBuyNow={goToBuyNow} />;
	}
	return (
		<div className="buyer-place-bid-page">
			{loading || !product || !fees ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<div className="container ">
					<div className="bidScreenContainer">
						<div className="buyNow">
							<h2>{product.name}</h2>
							<ul className="details">
								<li>{product.size}</li>
								<li>{product.abv}% ABV</li>
								{product.has_box !== null && <li>{product.has_box ? "With" : "Without"} BOX</li>}
								{product.has_tag !== null && <li>{product.has_tag ? "With" : "Without"} TAG</li>}
								{product.has_bag !== null && <li>{product.has_bag ? "With" : "Without"} BAG</li>}
								{product.has_tin !== null && <li>{product.has_tin ? "With" : "Without"} TIN</li>}
								{product.has_case !== null && <li>{product.has_case ? "With" : "Without"} CASE</li>}
								{product.batch !== null && <li>{product.batch}</li>}
								{product.lot !== null && <li>{product.lot}</li>}
							</ul>
							<div className="product_img">
								<img src={product.images[0]} alt={product.id} />
							</div>
							<div className="btnWrapper">
								<button className="buyBtn" onClick={goToBuyNow}>
									<span className="buy-btn-rt">buy now</span> | <span className="buy-btn-lt">${numberToPriceString(listing?.price)}</span>
								</button>
							</div>
						</div>
						<div className="placeBid">
							<input className="bidHeading" placeholder="ENTER OFFER" value={bidPrice} onChange={inputBidPrice} />
							<div className="invoiceTable">
								<table className="table table-borderless">
									<tbody>
										<tr>
											<td>
												Shipping
												<div className="tooltip-popup">
													<i className="icon-cc-info" onMouseEnter={() => setOnSalesService(true)} onMouseLeave={() => setOnSalesService(false)}></i>
													{onSalesService && (
														<>
															<div className="popup">
																<table>
																	<tbody>
																		<tr>
																			<td>
																				<small>{fees.details.shipping.label}</small>
																			</td>
																			<td>
																				<small>${numberToPriceString(fees.details.shipping.value)}</small>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<small>{fees.details.insurance.label}</small>
																			</td>
																			<td>
																				<small>${numberToPriceString(fees.details.insurance.value)}</small>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</>
													)}
												</div>
											</td>
											<td>${numberToPriceString(parseFloat(fees?.details.shipping.value) + parseFloat(fees?.details.insurance.value))}</td>
										</tr>
										<tr>
											<td>
												Taxes & Fees
												<div className="tooltip-popup">
													<i className="icon-cc-info" onMouseEnter={() => setOnShippingInsurance(true)} onMouseLeave={() => setOnShippingInsurance(false)}></i>
													{onShippingInsurance && (
														<>
															<div className="popup">
																<table>
																	<tbody>
																		<tr>
																			<td>
																				<small>{fees.details.service_fee.label}</small>
																			</td>
																			<td>
																				<small>${numberToPriceString(fees.details.service_fee.value)}</small>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<small>{fees.details.sales_tax.label}</small>
																			</td>
																			<td>
																				<small>${numberToPriceString(fees.details.sales_tax.value)}</small>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</>
													)}
												</div>
												<br /> <small>estimated sales tax</small>
											</td>
											<td>${numberToPriceString(parseFloat(fees?.details.service_fee.value) + parseFloat(fees?.details.sales_tax.value))}</td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<th>TOTAL</th>
											<th>${numberToPriceString((isNaN(parseFloat(bidPrice)) ? 0 : parseFloat(bidPrice)) + parseFloat(fees?.sum))}</th>
										</tr>
									</tfoot>
								</table>
							</div>
							<BuyerSaveCardForm selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
							<BuyerSaveShippingForm selectedShipping={selectedShipping} setSelectedShipping={setSelectedShipping} />
							<div className="selectDays">
								<select className="customSelect" value={bidLength} onChange={bidLengthChange}>
									<option value="15">Offer Good For 15 days</option>
									<option value="30">Offer Good For 30 days</option>
								</select>
								<span className="icon-cc-down-arrow"></span>
							</div>
							<div className="btnWrapper ">
								<button className="bitBtn" onClick={submitBid}>
									MAKE OFFER
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(BuyerPlaceBidPage);
