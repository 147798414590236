import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import video from "../../assets/video/eliqx-learn-more-hp.mp4";

const HomepageVideoBanner = () => (
	<Link to="/buyer/guide" className="homepage-video-banner">
		<ReactPlayer className="react-player" width="100%" height="100%" playing url={video} loop muted />
	</Link>
);

export default HomepageVideoBanner;
