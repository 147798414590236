import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { createStructuredSelector } from "reselect";
import NormalLoading from "../../components/ui-kits/normal-loading.component";

import { extractErrors } from "../../helpers/commonHelper";
import { getLang } from "../../helpers/languageHelper";
import { signWithToken } from "../../redux/auth/auth.action";
import { getToken } from "../../redux/auth/auth.selector";
import { authSignWithToken } from "../../services/ApiService";

const PhoneNumberVerifyCodeBox = ({ error, loading, submit }) => {
	const [code, setCode] = useState("");
	const inputHandle = (event) => {
		setCode(event.target.value);
	};

	if (loading) {
		return (
			<div style={{ justifyContent: "center", minHeight: "650px" }}>
				<Loader type="Puff" color="#04d39f" height="100" width="100" style={{ marginTop: "30px" }} />
			</div>
		);
	}
	return (
		<div style={{ minHeight: "650px" }}>
			{error && <h1 style={{ color: "red", marginTop: "100px" }}>{getLang(error)}</h1>}
			<div className="row mt-5 mb-5" style={{ justifyContent: "center" }}>
				<input type="text" id="code" style={{ width: "120px" }} placeholder="Your code" value={code} onChange={inputHandle} />
				<button className="btn btn-primary" style={{ width: "100px", marginTop: 0, marginLeft: 20 }} onClick={() => submit(code)}>
					{getLang("verify")}
				</button>
			</div>
		</div>
	);
};

const CallbackAuthorization = ({ authToken, location, loginWithToken }) => {
	const token = new URLSearchParams(location.search).get("token");
	const error = new URLSearchParams(location.search).get("error");
	const [err, setErr] = useState(error);
	const [loading, setLoading] = useState(false);
	const [showCode, setShowCode] = useState(false);
	const signNow = (code) => {
		setLoading(true);
		loginWithToken(token, code)
			.then((res) => {})
			.catch((error) => {
				setErr(extractErrors(error)[0]);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (token) {
			authSignWithToken(token)
				.then((res) => {
					if (res.status_code === 1) {
						setLoading(false);
						setShowCode(true);
					} else {
						loginWithToken(token).catch((err) => {
							setErr(extractErrors(err)[0]);
							setLoading(false);
						});
					}
				})
				.catch((error) => {
					setLoading(false);
					setErr(extractErrors(error)[0]);
				});
		}
	}, [token, loginWithToken]);

	if (authToken) {
		return <Redirect to="/buyer/profile-profile" />;
	}
	if (showCode) {
		return (
			<div>
				<PhoneNumberVerifyCodeBox error={err} loading={loading} submit={signNow} />
			</div>
		);
	}
	return (
		<div style={{ minHeight: "650px" }}>
			{err ? (
				<h1 style={{ color: "red", marginTop: "100px" }}>{getLang(err)}</h1>
			) : (
				<div className="loading">
					<NormalLoading />
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	authToken: getToken,
});

const mapDispatchToProps = (dispatch) => ({
	loginWithToken: (token, code = null) => dispatch(signWithToken(token, code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallbackAuthorization);
