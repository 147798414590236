export const TOGGLE_HEADER_PROFILE_MENU_SHOW = "TOGGLE_HEADER_PROFILE_MENU_SHOW";
export const SET_HEADER_PROFILE_MENU_SHOW = "SET_HEADER_PROFILE_MENU_SHOW";
export const TOGGLE_HEADER_MAIN_MENU_SHOW = "TOGGLE_HEADER_MAIN_MENU_SHOW";
export const SET_HEADER_MAIN_MENU_SHOW = "SET_HEADER_MAIN_MENU_SHOW";

export const toggleHeaderProfileMenuShow = (val = null) => {
	if (val === null) {
		return { type: TOGGLE_HEADER_PROFILE_MENU_SHOW };
	} else {
		return { type: SET_HEADER_PROFILE_MENU_SHOW, val };
	}
};

export const toggleHeaderMainMenuShow = (val = null) => {
	if (val === null) {
		return { type: TOGGLE_HEADER_MAIN_MENU_SHOW };
	} else {
		return { type: SET_HEADER_MAIN_MENU_SHOW, val };
	}
};
