import { SET_SEARCH_KEYWORD } from "./shop.action";

const INITIAL_STATE = {
	searchKeyword: "",
};

export const shopReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_SEARCH_KEYWORD:
			return {
				...state,
				searchKeyword: action.keyword,
			};

		default:
			return state;
	}
};

export default shopReducer;
