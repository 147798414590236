import React from "react";
import { Link } from "react-router-dom";
import logoBlue from "../../assets/images/eliqx-logo-blue.svg";
import "./shop.styles.scss";
import "./shop.styles.css";
import { createStructuredSelector } from "reselect";
import { getHeaderMainMenuShow } from "../../redux/setting/setting.selector";
import { toggleHeaderMainMenuShow } from "../../redux/setting/setting.action";
import { connect } from "react-redux";
import { getIsActivedSeller } from "../../redux/auth/auth.selector";

const HeaderMainMenu = ({ isActivedSeller, menuShow, toggleMenu }) => {
	if (!menuShow) return <div></div>;
	return (
		<div className="header-main-menu" onMouseLeave={() => toggleMenu(false)}>
			<div className="menu-container">
				<div className="menu-content">
					<h4 className="menu-title">menu</h4>
					<ul className="menu-items">
						<li className="menu-item">
							<Link to="/" onClick={() => toggleMenu(false)}>
								home
							</Link>
						</li>
						<li className="menu-item">
							<Link to="/legal/about" onClick={() => toggleMenu(false)}>
								about
							</Link>
						</li>
						<li className="menu-item">
							<Link to="/buyer/faq" onClick={() => toggleMenu(false)}>
								buyer faq
							</Link>
						</li>
						{isActivedSeller && (
							<>
								<li className="menu-item">
									<Link to="/seller/faq" onClick={() => toggleMenu(false)}>
										seller faq
									</Link>
								</li>
								<li className="menu-item">
									<Link to="/seller/guide" onClick={() => toggleMenu(false)}>
										seller info
									</Link>
								</li>
							</>
						)}
						<li className="menu-item">
							<Link to="/legal/contact-us" onClick={() => toggleMenu(false)}>
								contact us
							</Link>
						</li>
					</ul>
					<Link to="#" replace={true}>
						<img className="menu-logo-center" src={logoBlue} alt="menu-logo-center" />
					</Link>
					<div className="cc-fine-print-menu">
						<ul className="menu-items">
							<li className="menu-item">
								<Link to="/legal/terms-of-use" onClick={() => toggleMenu(false)}>
									terms of use
								</Link>
							</li>
							<li className="menu-item">
								<Link to="/legal/return-policy" onClick={() => toggleMenu(false)}>
									refund policy
								</Link>
							</li>
							<li className="menu-item">
								<Link to="/legal/privacy-policy" onClick={() => toggleMenu(false)}>
									privacy policy
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	menuShow: getHeaderMainMenuShow,
	isActivedSeller: getIsActivedSeller,
});

const mapDispatchToProps = (dispatch) => ({
	toggleMenu: (val = null) => dispatch(toggleHeaderMainMenuShow(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMainMenu);
