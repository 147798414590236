import React from "react";
import logo from "../../assets/images/order-confirm-grey-logo.svg";
import "./legal-pages.styles.scss";

const SellerFaqPage = () => (
	<div className="seller-faq-page">
		<div className="legal-container ">
			<div className="legal-logo">
				<img className="legal-img" src={logo} alt="eliqx grey logo" />
				<h2 className="cc-tagline">electronic liquor exchange</h2>
			</div>
			<div className="legal-container">
				<h1 className="legal-title">seller FAQ</h1>
				<p className="legal-section-title">Can I sell rare bottles on eliqx?</p>
				<p className="legal-body">
				You can only sell rare bottles on eliqx if you are a state licensed alcohol retailer and follow all relevant alcoholic beverage laws.
				</p>
				<p className="legal-section-title">In which states can I use eliqx to help sell rare bottles?</p>
				<p className="legal-body">
				eliqx is currently only accepting retailers in California.  Most retail licenses are able to sell using a format consistent with eliqx.  Please check with your local governing entity if you are unsure
				</p>
				<p className="legal-section-title">How do OFFERS work?</p>
				<p className="legal-body">
				If you see an OFFER that you are willing to accept for the same bottle that you have for sale, you can SELL that item immediately and initiate the shipping process.   If you think the highest OFFER is too low, you are welcome to CREATE LISTING (post an asking price) for whatever you think your rare bottle is worth above the highest OFFER price, and choose how long your LISTING is available for.  Once a LISTING is accepted the transaction proceeds and the shipping process begins.
				</p>
				<p className="legal-section-title">What's the difference between LISTING SOLD and SELL NOW?</p>
				<p className="legal-body">
				When you list a bottle for sale and someone decides to pay your asking price, that is a LISTING SOLD.  When you list a bottle to sell and decide that you want to accept the current highest bid, that is a SELL NOW. These two functions have different fee pricing.  The fees for SELL NOW are typically less than the fees for LISTING SOLD.
				</p>
				<p className="legal-section-title">Do I have to pay sales tax on the rare bottle transaction?</p>
				<p className="legal-body">
				No. In California, sales tax is collected by eliqx and paid to the state by eliqx.
				</p>
				<p className="legal-section-title">How does the delivery work?</p>
				<p className="legal-body">
				Once a buyer and a seller have agreed on a price, the transaction proceeds and the shipping process begins.
				<br/>
				PART 1: In order to ship spirits, proper packaging must be used.  Proper packing material is shipped to the specified SHIP TO address in your profile.  The proper packing material may take 2 days for delivery.
				<br/>
				PART 2: Once you have received the proper packing material, open the package without damaging it. Inside the package there will be packing material specific to the bottle being sold and a shipping label. Take out the new shipping label and tape. Carefully package the rare bottle into the shipping box. Seal the box then apply the new shipping label over the old one. When the bottle is packed and ready for shipping, request it to be picked up by our shipping partner from our application or website from the Pick Up Request tab in your profile. 
				</p>
				<p className="legal-section-title">I have my own proper packaging material and want to send out the bottle ASAP. Can I do that?</p>
				<p className="legal-body">
				Yes, if you do not want to wait for our packaging material to be shipped to you, you can print out and use a copy of the shipping label that was emailed to you, pack up the bottle, apply the shipping label and request the pick up of your package through our website or application with our shipping partner.  In all cases, the sooner a packaged rare bottle is scanned in to ship, the sooner a payout is made.
				</p>
				<p className="legal-section-title">When do I get my payout?</p>
				<p className="legal-body">
				The payout is initiated once the rare bottle has been packed, shipped and scanned in by our shipping partner. 
				</p>
				<p className="legal-section-title">What if a rare bottle sells on eliqx, but i don't have it anymore because I just sold it to a customer at my store?</p>
				<p className="legal-body">
				Once a rare bottle is listed on eliqx it may be purchased at any time.  If someone accepts an asking price for your rare bottle listing and you cannot fulfill the order, you will still be charged all the fees related to the sale regardless, and may be subject to suspension or termination of your account.  We ask that all rare bottles listed on the eliqx site be exclusively for sale on eliqx for the selected listing length.  Keep in mind, a listing can be taken down at any time.  If you have a live customer in your store to sell your rare bottle, make sure you take down the eliqx listing immediately to avoid penalties.
				</p>
				<p className="legal-section-title">How do I sell a rare bottle that I don’t see listed in the database?</p>
				<p className="legal-body">
				If a bottle is not listed in the eliqx database yet you can still submit the listing.  Once that is done, eliqx will determine if that rare bottle can be added to the listings and contact you.
				</p>				
			</div>
		</div>
	</div>
);

export default SellerFaqPage;
