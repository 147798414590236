import React, { useEffect, useRef, useState } from "react";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../../redux/auth/auth.selector";
import { connect } from "react-redux";
import sign_logo from "../../../assets/images/eliqx-logo-all-grey.svg";
import "./buyer-register-seller-profile-page.styles.scss";
import { toast } from "react-toastify";
import { buyerSubmitSellerProfile } from "../../../services/ApiService";
import { extractErrors } from "../../../helpers/commonHelper";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";

const initialForm = {
	store_name: "",
	business_address: "",
	zip_code: "",
	liquor_lic_type: "",
	liquor_lic_num: "",
};

const BuyerRegisterSellerProfilePage = ({ token, history }) => {
	const isIn = useRef();
	const [form, setForm] = useState(initialForm);
	const [mustCheck, setMustCheck] = useState(false);
	const [loading, setLoading] = useState(false);

	const inputHandle = (event) => {
		let { name, value } = event.target;
		if (name === "liquor_lic_type") value = value.replace(/[^0-9]/g, "").substr(0, 2);
		else if (name === "liquor_lic_num") value = value.replace(/[^0-9]/g, "");
		setForm({ ...form, [name]: value });
	};
	const submitForm = (event) => {
		event.preventDefault();
		if (!mustCheck) {
			toast.warning("You must agree with our terms & conditions");
			return;
		}
		setLoading(true);
		buyerSubmitSellerProfile(token, form)
			.then((res) => {
				if (isIn.current) {
					toast.success("Successfully submitted! We will review your profile soon");
					history.push("/buyer/seller-register-confirm");
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	};

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="buyer-register-seller-profile-page">
			<div className="eliqx-signup-modal">
				{loading ? (
					<div className="loading">
						<NormalLoading />
					</div>
				) : (
					<div className="signup-form">
						<div className="signup-logo">
							<img src={sign_logo} alt="logo" />
						</div>
						<h3 className="cc-apply-h3">apply to be a seller</h3>
						<form name="store registration" onSubmit={submitForm}>
							<label htmlFor="storename"></label>
							<input
								className="cc-signup-input-box"
								type="text"
								name="store_name"
								id="storename"
								placeholder="store name"
								value={form.store_name}
								onChange={inputHandle.bind(this)}
							/>
							<label htmlFor="storeaddress"></label>
							<input
								className="cc-signup-input-box"
								type="text"
								name="business_address"
								id="storeaddress"
								placeholder="store address"
								onChange={inputHandle.bind(this)}
							/>
							<input className="cc-signup-input-box" type="text" name="zip_code" id="zip_code" placeholder="zip code" onChange={inputHandle.bind(this)} />
							<label htmlFor="liquorlictype"></label>
							<input
								className="cc-signup-input-box"
								type="text"
								name="liquor_lic_type"
								id="liquorlictype"
								placeholder="2 digit liq lic type"
								value={form.liquor_lic_type}
								onChange={inputHandle.bind(this)}
							/>
							<label htmlFor="liquorlic"></label>
							<input
								className="cc-signup-input-box"
								type="text"
								name="liquor_lic_num"
								id="liquorlic"
								placeholder="liquor license #"
								value={form.liquor_lic_num}
								onChange={inputHandle.bind(this)}
							/>
							<input type="checkbox" id="c1" checked={mustCheck} onChange={() => {}} onClick={() => setMustCheck(!mustCheck)} />
							<label htmlFor="c1" className="cc-checkbox">
								I am a state licensed retailer for alcoholic beverages
							</label>
							<button className="cc-register-btn" type="submit">
								Apply Now
							</button>
						</form>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(BuyerRegisterSellerProfilePage);
