import { applyMiddleware, combineReducers, createStore } from "redux";
import ReduxThunk from "redux-thunk";
import logger from "redux-logger";

import authReducer from "./auth/auth.reducer";
import settingReducer from "./setting/setting.reducer";
import shopReducer from "./shop/shop.reducer";

import { ENV } from "../env";

const rootReducer = combineReducers({
	auth: authReducer,
	setting: settingReducer,
	shop: shopReducer,
});

const middleWares = ENV === "DEVELOPMENT" ? [ReduxThunk, logger] : [ReduxThunk];

export const store = createStore(rootReducer, applyMiddleware(...middleWares));

export default store;
