import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import { createStructuredSelector } from "reselect";
import NormalLoading from "../../components/ui-kits/normal-loading.component";
import { extractErrors, numberToPriceString, strToPhoneNumberFormat } from "../../helpers/commonHelper";
import { getLang } from "../../helpers/languageHelper";
import { getToken } from "../../redux/auth/auth.selector";
import { adminGetFailedTransactions, adminGetUsers, shopGetProducts } from "../../services/ApiService";
import "./admin.styles.scss";

const AdminDashboardPage = ({ token, history }) => {
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState();
	const [products, setProducts] = useState();
	const [failedTransactions, setFailedTransactions] = useState([]);

	const loadData = useCallback(() => {
		setLoading(true);
		let loaded = 0;
		adminGetUsers(token, { seller_status: "PENDING" })
			.then((res) => {
				loaded |= 1;
				if (loaded === 3) setLoading(false);
				setUsers(res);
			})
			.catch((err) => {
				loaded |= 1;
				if (loaded === 3) setLoading(false);
				toast.error(getLang(extractErrors(err)[0]));
			});

		shopGetProducts({ status: "PENDING" }, 50, 1, token)
			.then((res) => {
				loaded |= 2;
				if (loaded === 3) setLoading(false);
				setProducts(res.data);
			})
			.catch((err) => {
				loaded |= 2;
				if (loaded === 3) setLoading(false);
				toast.error(getLang(extractErrors(err)[0]));
			});

		adminGetFailedTransactions(token)
			.then((res) => setFailedTransactions(res))
			.catch((err) => toast.error(getLang(extractErrors(err)[0])));
	}, [token]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	const failedBuyers = failedTransactions.filter((x) => x.type === "BUY"),
		failedSellers = failedTransactions.filter((x) => x.type === "SELL");

	return (
		<div className="admin-dashboard-page">
			<div className="site-content">
				<div className="content-wrapper section-ptb">
					<Container>
						{loading ? (
							<div className="loading">
								<NormalLoading />
							</div>
						) : (
							<>
								{failedTransactions.length > 0 && (
									<Row>
										<Col md={6}>
											<h1>
												High Value Failed Transactions
												<br />
												buy now
											</h1>
											<table className="table table-striped transactions-table">
												<thead>
													<tr>
														<th scope="col">Buyer Name</th>
														<th scope="col">Product Name</th>
														<th scope="col">Listing Price</th>
														<th scope="col">Date&Time Attempted</th>
														<th scope="col">Buyer Email</th>
														<th scope="col">Buyer Phone</th>
													</tr>
												</thead>
												<tbody>
													{failedBuyers.map((item) => (
														<tr key={item.id}>
															<td>{item.user.name}</td>
															<td>{item.product.name}</td>
															<td>{numberToPriceString(item.price)}</td>
															<td>{item.created_at}</td>
															<td>{item.user.email}</td>
															<td>{item.user.phone_number}</td>
														</tr>
													))}
												</tbody>
											</table>
										</Col>
										<Col md={6}>
											<h1>
												High Value Failed Transactions
												<br />
												sell now
											</h1>
											<table className="table table-striped transactions-table">
												<thead>
													<tr>
														<th scope="col">Buyer Name</th>
														<th scope="col">Product Name</th>
														<th scope="col">Listing Price</th>
														<th scope="col">Date of Offer</th>
														<th scope="col">Buyer Email</th>
														<th scope="col">Buyer Phone</th>
													</tr>
												</thead>
												<tbody>
													{failedSellers.map((item) => (
														<tr key={item.id}>
															<td>{item.user.name}</td>
															<td>{item.product.name}</td>
															<td>{numberToPriceString(item.price)}</td>
															<td>{item.created_at}</td>
															<td>{item.user.email}</td>
															<td>{item.user.phone_number}</td>
														</tr>
													))}
												</tbody>
											</table>
										</Col>
									</Row>
								)}
								<Row>
									<Col md={6}>
										<h1>Pending Sellers</h1>
										<table className="table table-striped users-table">
											<thead>
												<tr>
													<th scope="col">{getLang("name")}</th>
													<th scope="col">{getLang("email")}</th>
													<th scope="col">{getLang("store_name")}</th>
													<th scope="col">{getLang("city")}</th>
													<th scope="col">{getLang("phone_number")}</th>
												</tr>
											</thead>
											<tbody>
												{users &&
													users.map((user) => (
														<tr key={user.id} onClick={() => history.push(`/admin/user/detail/${user.id}`)}>
															<td>{user.name}</td>
															<td>{user.email}</td>
															<td>{user.seller_profile.store_name}</td>
															<td>{user.city}</td>
															<td>{strToPhoneNumberFormat(user.phone_number)}</td>
														</tr>
													))}
											</tbody>
										</table>
									</Col>
									<Col md={6}>
										<h1>Pending Products</h1>
										<table className="table table-striped products-table">
											<thead>
												<tr>
													<th scope="col">{getLang("image")}</th>
													<th scope="col">{getLang("product_info")}</th>
												</tr>
											</thead>
											<tbody>
												{products &&
													products.map((product) => (
														<tr key={product.id}>
															<td className="product-image" onClick={() => history.push(`/admin/product/update/${product.id}`)}>
																<img src={product.images[0]} alt={product.name} />
															</td>
															<td className="product-info">
																<div className="product-name">
																	<div className="label">{getLang("product_name")}:</div>
																	<div>{product.name}</div>
																</div>
																<div className="product-size">
																	<div className="label">{getLang("size")}:</div>
																	<div>{product.size}</div>
																</div>
																<div className="author-name">
																	<div className="label">{getLang("author_name")}:</div>
																	<div>{product.user.name}</div>
																</div>
																<div className="author-email">
																	<div className="label">{getLang("author_email")}:</div>
																	<div>{product.user.email}</div>
																</div>
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</Col>
								</Row>
							</>
						)}
					</Container>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(AdminDashboardPage);
