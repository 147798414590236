import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import "./buyer-save-stripe-card.styles.scss";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { buyerSaveBillingCard, shopGetSystemSettings } from "../../../services/ApiService";
import { toast } from "react-toastify";
import { extractErrors } from "../../../helpers/commonHelper";
import { Modal } from "reactstrap";
import credit_card_png from "../../../assets/images/credit-card-payments-logos.png";
import stripe_logo_png from "../../../assets/images/stripe-logo-purple.png";
import NormalLoading from "../../ui-kits/normal-loading.component";

const CheckoutForm = ({ stripe, elements, token, callback, closeForm }) => {
	const isIn = useRef();
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!stripe || !elements) return;
		try {
			const cardElement = elements.getElement(CardElement);
			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: "card",
				card: cardElement,
			});
			if (error) {
				if (isIn.current) {
					toast.error(error.message);
				}
				return;
			}
			if (isIn.current) {
				setLoading(true);
				const result = await buyerSaveBillingCard(token, paymentMethod);
				if (callback) callback(result);
			}
		} catch (err) {
			if (isIn.current) {
				toast.error(extractErrors(err)[0]);
			}
		}
		if (isIn.current) setLoading(false);
	};

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	if (loading || !stripe || !elements) {
		return (
			<div className="loading">
				<NormalLoading />
			</div>
		);
	}
	return (
		<form onSubmit={handleSubmit.bind(this)}>
			<CardElement
				options={{
					style: {
						base: {
							fontSize: "16px",
							color: "#424770",
							"::placeholder": {
								color: "#aab7c4",
							},
						},
						invalid: {
							color: "#9e2146",
						},
					},
				}}
			/>
			<button type="submit" disabled={!stripe}>
				Submit
			</button>
			<button type="button" onClick={closeForm}>
				Cancel
			</button>
		</form>
	);
};

const BuyerSaveStripeCard = ({ token, callback, closeForm }) => {
	const isIn = useRef();
	const [loading, setLoading] = useState(false);
	const [paymentKey, setPaymentKey] = useState();

	useLayoutEffect(() => {
		setLoading(true);
		shopGetSystemSettings("payment=1")
			.then((res) => {
				if (isIn.current) {
					setLoading(false);
					setPaymentKey(res.payment_api_key);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, []);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	if (!token) {
		return <div className="error">Invalid Token</div>;
	}
	if (loading || !paymentKey) {
		return (
			<div className="loading">
				<NormalLoading />
			</div>
		);
	}
	const stripePromise = loadStripe(paymentKey);

	return (
		<Modal isOpen={true} className="modal-login modal-dialog-centered auth-modal">
			<div className="buyer-save-stripe-card">
				<div className="save-card-header">
					<img src={credit_card_png} alt="credit-card-png" />
				</div>
				<Elements stripe={stripePromise}>
					<ElementsConsumer>
						{({ elements, stripe }) => <CheckoutForm elements={elements} stripe={stripe} token={token} callback={callback} closeForm={closeForm} />}
					</ElementsConsumer>
				</Elements>
				<div className="save-card-footer">
					<p>Credit card info stored securely by stripe</p>
					<div>
						<p>Powered by</p>
						<img src={stripe_logo_png} alt="stripe-logo" />
					</div>
				</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(BuyerSaveStripeCard);
