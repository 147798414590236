import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SellerProfileMenu from "./seller-profile-menu.component";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { sellerGetMyListings, sellerGetMySells, sellerRequestShipping, shopGetSystemSettings } from "../../../services/ApiService";
import { toast } from "react-toastify";
import { extractErrors, numberToPriceString, openPrintImage } from "../../../helpers/commonHelper";
import "./seller-profile.styles.scss";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";

const getItemNameDiv = (product) => (
	<span>
		<u className="cc-portfolio-main">{product.name}</u>
		<br />
		<u className="cc-portfolio-product-details">{product.size}</u>
		<u className="cc-portfolio-product-details">{product.abv}% ABV</u>
		{product.has_box !== null && <u>{product.has_box ? "With" : "Without"} BOX</u>}
		{product.has_tag !== null && <u>{product.has_tag ? "With" : "Without"} TAG</u>}
		{product.has_bag !== null && <u>{product.has_bag ? "With" : "Without"} BAG</u>}
		{product.has_tin !== null && <u>{product.has_tin ? "With" : "Without"} TIN</u>}
		{product.has_case !== null && <u>{product.has_case ? "With" : "Without"} CASE</u>}
	</span>
);

const StatusLabel = {
	PENDING: "PROCESSING",
	ON_SHIPPING: "IN TRANSIT",
	FAILED: "FAILED",
	UNDER_REVIEW: "IN REVIEW",
	COMPLETED: "COMPLETED",
};

const TabsBox = ({ tab, path }) => (
	<div className="tabs">
		<Link className={`tab-item ${tab === "OPEN_LISTINGS" ? "active" : ""}`} to={`${path}?tab=OPEN_LISTINGS`}>
			OPEN LISTINGS
		</Link>
		<Link className={`tab-item ${tab === "PENDING_LISTINGS" ? "active" : ""}`} to={`${path}?tab=PENDING_LISTINGS`}>
			PENDING LISTINGS
		</Link>
		<Link className={`tab-item ${tab === "SOLD" ? "active" : ""}`} to={`${path}?tab=SOLD`}>
			SOLD
		</Link>
	</div>
);

const PendingListingsBox = ({ token }) => {
	const isIn = useRef();
	const [loading, setLoading] = useState(false);
	const [listings, setListings] = useState();

	const loadListings = useCallback(() => {
		setLoading(true);
		sellerGetMyListings(token, "PENDING")
			.then((res) => {
				if (isIn.current) {
					setListings(res);
					setLoading(false);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [token]);

	useEffect(() => {
		loadListings();
	}, [loadListings]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="pending-listings">
			{loading || !listings ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<table className="table">
					<thead>
						<tr>
							<th>ITEM NAME</th>
							<th>DATE LISTED</th>
							<th>PRICE</th>
							<th>STATUS</th>
						</tr>
					</thead>
					<tbody>
						{listings.map((item) => (
							<tr key={item.id}>
								<td data-th="ITEM NAME">
									<Link to={`/shop/product/${item.product.id}`}>
										<span>{getItemNameDiv(item.product)}</span>
									</Link>
								</td>
								<td data-th="DATE LISTED">
									<span>{item.created_at.split(" ")[0]}</span>
								</td>
								<td data-th="PRICE">
									<span className="ming">
										<u>${numberToPriceString(item.price)}</u>
									</span>
								</td>
								<td data-th="STATUS" className="status">
									{item.status === "PENDING" ? (
										<p className="in-review">
											IN REVIEW <br />
											may take up to 24 hours to approve
										</p>
									) : (
										<span className="active">{item.status}</span>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};

const OpenListingsBox = ({ token }) => {
	const isIn = useRef();
	const [loading, setLoading] = useState(false);
	const [listings, setListings] = useState();
	const [sortDir, setSortDir] = useState({
		item_name: false,
		expires: false,
		price: false,
	});
	const [saveAmount, setSaveAmount] = useState(0);

	const sortListings = (key) => {
		const dir = sortDir[key];
		const temp = [...listings];
		switch (key) {
			case "item_name":
				temp.sort((a, b) => (a.product.name < b.product.name ? (dir ? -1 : 1) : dir ? 1 : -1));
				break;
			case "expires":
				temp.sort((a, b) => (new Date(a.expired_at) < new Date(b.expired_at) ? (dir ? -1 : 1) : dir ? 1 : -1));
				break;
			case "price":
				temp.sort((a, b) => (new Date(a.price) < new Date(b.price) ? (dir ? -1 : 1) : dir ? 1 : -1));
				break;
			default:
				break;
		}
		setSortDir({ ...sortDir, [key]: !dir });
		setListings(temp);
	};

	const loadListings = useCallback(() => {
		setLoading(true);
		sellerGetMyListings(token, "APPROVED,EXPIRED")
			.then((res) => {
				if (isIn.current) {
					setListings(res);
					setLoading(false);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
		shopGetSystemSettings("public=1")
			.then((res) => {
				if (isIn.current) setSaveAmount(res.seller_buy_bid_save_fee ?? 0);
			})
			.catch(() => {});
	}, [token]);

	useEffect(() => {
		loadListings();
	}, [loadListings]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="open-listings">
			{loading || !listings ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<table className="table">
					<thead>
						<tr>
							<th onClick={sortListings.bind(this, "item_name")}>ITEM NAME</th>
							<th onClick={sortListings.bind(this, "expires")}>EXPIRES</th>
							<th onClick={sortListings.bind(this, "price")}>LIST PRICE</th>
							<th>HIGH OFFER</th>
							<th>EARN {saveAmount}% MORE</th>
						</tr>
					</thead>
					<tbody>
						{listings.map((item) => (
							<tr key={item.id}>
								<td data-th="ITEM NAME">
									<Link to={`/shop/product/${item.product.id}`}>
										<span>{getItemNameDiv(item.product)}</span>
									</Link>
								</td>
								<td data-th="EXPIRES">
									<span>
										<u>{item.expired_at}</u>
										<u>{item.expiration} days left</u>
									</span>
								</td>
								<td data-th="ASKS">
									<span className="orange-soda">
										<u>${numberToPriceString(item.price)}</u>
										<Link to={`/seller/list-product/${item.product.id}?listing=${item.id}`} className="edit">
											<i className="icon-cc-pencil"></i>
										</Link>
									</span>
								</td>
								<td data-th="HIGH BID">
									<span className="ming">
										<u>${numberToPriceString(item.highest_bid?.price)}</u>
									</span>
								</td>
								<td data-th={`EARN ${saveAmount}% MORE`} className="buttons">
									<span>
										{item.status === "APPROVED" ? (
											<Link to={`/seller/accept-bid/${item.product.id}`} className="button sale-now">
												SELL MY BOTTLE
											</Link>
										) : (
											<Link to={`/seller/list-product/${item.product.id}?listing=${item.id}`} className="button sale-now">
												RENEW LISTING
											</Link>
										)}
										<Link to={`/seller/remove-listing/${item.id}`} className="delete">
											<i className="icon-cc-trash"></i>
										</Link>
									</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};

const PurchasedBox = ({ token }) => {
	const isIn = useRef();
	const [loading, setLoading] = useState(false);
	const [orders, setOrders] = useState(false);

	const loadOrders = useCallback(() => {
		setLoading(true);
		sellerGetMySells(token)
			.then((res) => {
				if (isIn.current) {
					setLoading(false);
					setOrders(res);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [token]);

	const makeShipment = (order, event) => {
		event.preventDefault();
		if (order.status === "PENDING") {
			setLoading(true);
			sellerRequestShipping(token, order.id)
				.then((res) => {
					if (isIn.current) {
						setLoading(false);
						loadOrders();
					}
				})
				.catch((err) => {
					if (isIn.current) {
						setLoading(false);
						toast.error(extractErrors(err)[0]);
					}
				});
		} else {
			openPrintImage(order.shipping_label);
		}
	};

	useEffect(() => {
		loadOrders();
	}, [loadOrders]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="sold">
			{loading || !orders ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<table className="table">
					<thead>
						<tr>
							<th>ITEM NAME</th>
							<th>ORDER DATE</th>
							<th>ORDER#</th>
							<th>PRICE</th>
							<th>STATUS</th>
							<th>SHIPPING</th>
						</tr>
					</thead>
					<tbody>
						{orders.map((item) => (
							<tr key={item.id}>
								<td data-th="ITEM NAME">
									<Link to={`/shop/product/${item.product.id}`}>
										<span>{getItemNameDiv(item.product)}</span>
									</Link>
								</td>
								<td data-th="DATE">
									<span>{item.created_at.split(" ")[0]}</span>
								</td>
								<td data-th="ORDER #">
									<span>#{item.id}</span>
								</td>
								<td data-th="PRICE">
									<span>${numberToPriceString(item.price)}</span>
								</td>
								<td data-th="STATUS" className="status">
									<u>
										<a href={item.track_shipping_url ?? "#"} target="_blank" rel="noopener noreferrer">
											<span className="active">{StatusLabel[item.status]}</span>
										</a>
									</u>
								</td>
								<td data-th="SHIPPING">
									<span>
										<Link to="#" className="button buy-now" onClick={makeShipment.bind(this, item)} replace={true}>
											{item.status === "PENDING" ? "REQUEST SHIPPING NOW" : "DOWNLOAD SHIPPING LABEL"}
										</Link>
									</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};

const SellerProfilePortfolioPage = ({ token, location }) => {
	const tab = new URLSearchParams(location.search).get("tab") ?? "OPEN_LISTINGS";

	return (
		<div className="seller-profile-portfolio-page">
			<div className="dashboard">
				<SellerProfileMenu />
				<div className="dashboard-main">
					<TabsBox tab={tab} path={location.pathname} />
					{tab === "OPEN_LISTINGS" && <OpenListingsBox token={token} />}
					{tab === "SOLD" && <PurchasedBox token={token} />}
					{tab === "PENDING_LISTINGS" && <PendingListingsBox token={token} />}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(SellerProfilePortfolioPage);
