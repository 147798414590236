import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../../redux/auth/auth.selector";
import logo from "../../../assets/images/eliqx-logo-all-grey.svg";
import "./seller-remove-listing-page.styles.scss";
import { sellerGetMyListingById, sellerRemoveListing } from "../../../services/ApiService";
import { toast } from "react-toastify";
import { extractErrors } from "../../../helpers/commonHelper";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";

const SellerRemoveListingPage = ({ token, match, history }) => {
	const listingId = match.params.listingId;
	const isIn = useRef();
	const [loading, setLoading] = useState();
	const [listing, setListing] = useState();

	const removeListing = useCallback(() => {
		setLoading(true);
		sellerRemoveListing(token, listingId)
			.then((res) => {
				setLoading(false);
				history.replace("/seller/profile-portfolio");
			})
			.catch((err) => {
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	}, [token, listingId, history]);

	const loadListing = useCallback(() => {
		setLoading(true);
		sellerGetMyListingById(token, listingId)
			.then((res) => {
				if (isIn.current) {
					setListing(res);
					setLoading(false);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [listingId, token]);

	useEffect(() => {
		loadListing();
	}, [loadListing]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="seller-remove-listing-page">
			{loading || !listing ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<div className="custom-modal">
					<div className="wrap">
						<img src={logo} alt="grey-logo" />
						<h2>Please Confirm Cancellation</h2>
						<p>
							Listings can be cancelled as long as they have not been successfully executed on the exchange. Please confirm below by clicking on Cancel Listing.
							Alternatively, listing prices can be edited. Click on Edit Listing to change your List price.
						</p>
						<div className="buttons">
							<Link to="#" onClick={removeListing} replace={true}>
								CANCEL LISTING
							</Link>
							<Link to={`/seller/list-product/${listing.product_id}?listing=${listingId}`}>EDIT LISTING</Link>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(withRouter(SellerRemoveListingPage));
