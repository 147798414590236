import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { extractErrors, numberToPriceString, strToPhoneNumberFormat, strToPhoneNumberString } from "../../../helpers/commonHelper";
import { buyerGetBillingCards, buyerGetShippingInfos, buyerSaveShippingInfo, buyerSubmitOrder } from "../../../services/ApiService";
import BuyerSaveStripeCard from "../../../components/buyer/buyer-save-stripe-card/buyer-save-stripe-card.component";
import "./buyer-buy-now.styles.scss";
import BuyerPriceLimitExceedComponent from "../../../components/buyer/buyer-price-limit-exceed.component";

const initialShippingForm = {
	first_name: "",
	last_name: "",
	phone_number: "",
	address_1: "",
	address_2: "",
	city: "",
	zip_code: "",
	special_instruction: "",
};

const BuyerBuyNowComponent = ({ token, product, listing, goToBuyNow }) => {
	const isIn = useRef();
	const [onShippingInsurance, setOnShippingInsurance] = useState(false);
	const [onSalesService, setOnSalesService] = useState(false);
	const [openPayment, setOpenPayment] = useState(false);
	const [openShipping, setOpenShipping] = useState(false);
	const [selectedCard, setSelectedCard] = useState();
	const [selectedShipping, setSelectedShipping] = useState();
	const [savedCards, setSavedCards] = useState([]);
	const [savedShipping, setSavedShipping] = useState([]);
	const [shippingForm, setShippingForm] = useState(initialShippingForm);
	const [showCardModal, setShowCardModal] = useState(false);

	const shippingInputHandle = (event) => {
		let { name, value } = event.target;
		if (name === "phone_number") value = strToPhoneNumberString(value);
		else if (name === "special_instruction") value = value.substr(0, 100);
		setShippingForm({ ...shippingForm, [name]: value });
	};
	const loadSavedBillingCards = useCallback(() => {
		setSavedCards(null);
		buyerGetBillingCards(token)
			.then((res) => {
				if (!isIn.current) return;
				setSavedCards(res);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	}, [token]);
	const loadSavedShippingInfos = useCallback(() => {
		setSavedShipping(null);
		buyerGetShippingInfos(token)
			.then((res) => {
				if (!isIn.current) return;
				setSavedShipping(res);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	}, [token]);
	const selectCard = (item) => {
		setSelectedCard(item);
		setOpenPayment(false);
	};
	const selectShipping = (item) => {
		setSelectedShipping(item);
		setOpenShipping(false);
	};

	const submitShipping = () => {
		buyerSaveShippingInfo(token, shippingForm)
			.then((res) => {
				if (!isIn.current) return;
				setSelectedShipping(res);
				setSavedShipping([...savedShipping, res]);
				setShippingForm(initialShippingForm);
				setOpenShipping(false);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	};
	const cardSubmitCallback = (res) => {
		setSelectedCard(res);
		setSavedCards([...savedCards, res]);
		setShowCardModal(false);
		setOpenPayment(false);
	};
	const submitOrder = () => {
		if (!selectedCard || !selectedShipping) {
			toast.error("Please select your card, and shipping");
			return;
		}
		const form = {
			billing_id: selectedCard.id,
			shipping_id: selectedShipping.id,
			price: listing.price,
		};
		buyerSubmitOrder(token, listing.id, form)
			.then((res) => {
				localStorage.setItem(`order_confirmed_${product.id}`, JSON.stringify(res));
				goToBuyNow();
			})
			.catch((err) => {
				if (isIn.current) {
					if (err.error === "buy_now_price_limit_exceed") {
						localStorage.setItem(`buy_now_price_limit_exceed_${product.id}`, new Date().getTime());
					} else {
						toast.error(extractErrors(err)[0]);
					}
				}
			});
	};

	useLayoutEffect(() => {
		loadSavedBillingCards();
		loadSavedShippingInfos();
	}, [loadSavedBillingCards, loadSavedShippingInfos]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	const { sales_tax, service_fee, shipping, insurance } = listing.transaction_fees.buyer_fee.details;
	const total = listing.price + listing.transaction_fees.buyer_fee.sum;

	return (
		<div className="buyer-buy-now-component">
			<div className="container ">
				<div className="buyScreenContainer">
					<div className="buyNow">
						<h2>{product.name}</h2>
						<ul className="details">
							<li>{product.size}</li>
							<li>{product.abv}% ABV</li>
							{product.has_box !== null && <li>{product.has_box ? "With" : "Without"} BOX</li>}
							{product.has_tag !== null && <li>{product.has_tag ? "With" : "Without"} TAG</li>}
							{product.has_bag !== null && <li>{product.has_bag ? "With" : "Without"} BAG</li>}
							{product.has_tin !== null && <li>{product.has_tin ? "With" : "Without"} TIN</li>}
							{product.has_case !== null && <li>{product.has_case ? "With" : "Without"} CASE</li>}
							{product.batch !== null && <li>{product.batch}</li>}
							{product.lot !== null && <li>{product.lot}</li>}
						</ul>
						<div className="product_img">
							<img src={product.images[0]} alt={product.id} />
						</div>
					</div>
					{localStorage.getItem(`buy_now_price_limit_exceed_${product.id}`) ? (
						<BuyerPriceLimitExceedComponent productId={product.id} />
					) : (
						<div className="buyProduct">
							<div className="top-headline">
								<div className="heading d-flex justify-content-between">
									<h2 style={{ width: "60%" }}>Market Price</h2>
									<h2>${numberToPriceString(listing.price)}</h2>
								</div>
							</div>
							<div className="invoiceTable">
								<table className="table table-borderless">
									<tbody>
										<tr>
											<td>
												Shipping
												<div className="tooltip-popup">
													<i className="icon-cc-info" onMouseEnter={() => setOnSalesService(true)} onMouseLeave={() => setOnSalesService(false)}></i>
													{onSalesService && (
														<>
															<div className="popup">
																<table>
																	<tbody>
																		<tr>
																			<td>
																				<small>{shipping.label}</small>
																			</td>
																			<td>
																				<small>${numberToPriceString(shipping.value)}</small>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<small>{insurance.label}</small>
																			</td>
																			<td>
																				<small>${numberToPriceString(insurance.value)}</small>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</>
													)}
												</div>
											</td>
											<td>${numberToPriceString(shipping.value + insurance.value)}</td>
										</tr>

										<tr>
											<td>
												Taxes & Fees
												<div className="tooltip-popup">
													<i className="icon-cc-info" onMouseEnter={() => setOnShippingInsurance(true)} onMouseLeave={() => setOnShippingInsurance(false)}></i>
													{onShippingInsurance && (
														<>
															<div className="popup">
																<table>
																	<tbody>
																		<tr>
																			<td>
																				<small>{service_fee.label}</small>
																			</td>
																			<td>
																				<small>${numberToPriceString(service_fee.value)}</small>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<small>{sales_tax.label}</small>
																			</td>
																			<td>
																				<small>${numberToPriceString(sales_tax.value)}</small>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</>
													)}
												</div>
											</td>
											<td>${numberToPriceString(service_fee.value + sales_tax.value)}</td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<th>TOTAL</th>
											<th>${numberToPriceString(total)}</th>
										</tr>
									</tfoot>
								</table>
							</div>
							<div className="middle">
								<div className="paymentMethod">
									{savedCards && savedCards.length > 0 && (
										<div className={`choosePaymentMethod collapse-content ${openPayment ? "show" : ""}`}>
											<h4>Choose Payment Method </h4>
											{savedCards.map((x) => (
												<p key={x.id} className={selectedCard?.id === x.id ? "selected_item" : ""} onClick={selectCard.bind(this, x)}>
													{x.first_name} {x.last_name} - {x.card_type} (************{x.last4})
												</p>
											))}
										</div>
									)}
									<button className="collapsbtn" onClick={() => setOpenPayment(!openPayment)}>
										{selectedCard && !openPayment
											? `${selectedCard.first_name} ${selectedCard.last_name} - ${selectedCard.card_type} (************${selectedCard.last4})`
											: "Add Payment Method"}
									</button>
									<div className={`addPaymentMethod collapse-content ${openPayment ? "show" : ""}`}>
										<button className="add-new-card" onClick={() => setShowCardModal(true)}>
											Add New Card
										</button>
										{showCardModal && <BuyerSaveStripeCard callback={cardSubmitCallback} closeForm={() => setShowCardModal(false)} />}
									</div>
								</div>
								<div className="shippingInfo">
									{savedShipping && savedShipping.length > 0 && (
										<div className={`choosePaymentMethod collapse-content ${openShipping ? "show" : ""}`}>
											<h4>Choose Shipping Address </h4>
											{savedShipping.map((x) => (
												<p key={x.id} className={selectedShipping?.id === x.id ? "selected_item" : ""} onClick={selectShipping.bind(this, x)}>
													{x.first_name ?? ""} {x.last_name ?? ""} {x.address_1 ?? ""} {x.address_2 ?? ""} {x.city ?? ""} {x.zip_code ?? ""}
													{strToPhoneNumberFormat(x.phone_number)}
												</p>
											))}
										</div>
									)}
									<button className="collapsbtn" onClick={() => setOpenShipping(!openShipping)}>
										{!openShipping && selectedShipping
											? `${selectedShipping.first_name ?? ""} ${selectedShipping.last_name ?? ""} ${strToPhoneNumberFormat(selectedShipping.phone_number)} ${
													selectedShipping.address_1 ?? ""
											  } ${selectedShipping.address_2 ?? ""} ${selectedShipping.city ?? ""} ${selectedShipping.zip_code ?? ""}`
											: "Add Shipping Info"}
									</button>
									<div className={`collapse-content ${openShipping ? "show" : ""}`}>
										<div className="row px-2 py-3">
											<div className="col-md-6">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="First Name"
													name="first_name"
													value={shippingForm.first_name}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-md-6">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Last Name"
													name="last_name"
													value={shippingForm.last_name}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-12">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Street Address"
													name="address_1"
													value={shippingForm.address_1}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-12">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Street Address Second (optional)"
													name="address_2"
													value={shippingForm.address_2}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-md-5">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="City"
													name="city"
													value={shippingForm.city}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-md-3 col-6">
												<input type="text" className="payment_input form-control" placeholder="State" value="CA" disabled />
											</div>
											<div className="col-md-4 col-6">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Zip Code"
													name="zip_code"
													value={shippingForm.zip_code}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-12">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Special instructions 100 character limit"
													name="special_instruction"
													value={shippingForm.special_instruction}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
											<div className="col-md-12">
												<input
													type="text"
													className="payment_input form-control"
													placeholder="Phone number"
													name="phone_number"
													value={strToPhoneNumberFormat(shippingForm.phone_number)}
													onChange={shippingInputHandle.bind(this)}
												/>
											</div>
										</div>
										<button className="confirmBtn" onClick={submitShipping}>
											confirm
										</button>
									</div>
								</div>
							</div>
							<div className="btnWrapper ">
								<button className="buyBtn" onClick={submitOrder}>
									Buy Now
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default BuyerBuyNowComponent;
