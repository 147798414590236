import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../../../assets/images/order-confirm-grey-logo.svg";
import "./buyer-order-confirmed.styles.scss";

const BuyerOrderConfirmedPage = ({ history, product }) => {
	const [order, setOrder] = useState();

	const removeStorage = useCallback(() => {
		localStorage.removeItem(`order_confirmed_${product.id}`);
	}, [product]);

	const goToDashboard = () => {
		history.push("/buyer/profile-profile");
	};

	useLayoutEffect(() => {
		const item = localStorage.getItem(`order_confirmed_${product.id}`);
		setOrder(JSON.parse(item));
	}, [product]);

	useEffect(() => {
		window.document.onclick = () => {
			removeStorage();
		};
	}, [removeStorage]);

	return (
		<div className="buyer-order-confirmed-page">
			<div className="container ">
				<div className="orderLogoContainer">
					<img className="orderLogo" src={logo} alt="grey-logo" />
				</div>
				<div className="orderDetailContainer">
					<h1>order confirmation</h1>
					<Link className="orderNumber" to="/buyer/profile-portfolio" onClick={removeStorage}>
						#{order?.id}
					</Link>
					<p className="orderThanks">thank you for your order.</p>
					<p className="orderInfo">
						We will notify you when your order ships. Orders can also be tracked in your&nbsp;
						<Link className="orderInlineLink" to="/buyer/profile-portfolio" onClick={removeStorage}>
							Buyer Dashboard
						</Link>
						.
					</p>
					<Link className="orderFaq" to="/buyer/faq" onClick={removeStorage}>
						FAQ
					</Link>
					<button className="buyerDashboard" type="button" name="button" onClick={goToDashboard}>
					&nbsp;buyer dashboard
					</button>
					{product.my_bid && (
						<p className="reminder-txt">You have an open bid on this product. Please use button below to visit your open bids page to edit or cancel bid</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default withRouter(BuyerOrderConfirmedPage);
