import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import "./buyer-profile.styles.scss";
import { createStructuredSelector } from "reselect";
import { getProfile } from "../../../redux/auth/auth.selector";
import { connect } from "react-redux";

const BuyerProfileMenu = ({ location, profile }) => {
	const menu = location.pathname.split("/")[2];
	const [show, setShow] = useState(false);

	return (
		<div className="dashboard-menu">
			<h2 className="dashboard-title">BUYER DASHBOARD</h2>
			<p className="dashboard-subtitle">{profile.email}</p>
			<div className="left-menu">
				<button onClick={() => setShow(!show)}>
					<i className="icon-cc-down-arrow"></i>
				</button>
				<ul className={`menu ${show ? "show" : ""}`}>
					<li className={menu === "profile-portfolio" ? "active" : ""}>
						<Link to="/buyer/profile-portfolio">
							<span>Orders</span>
							<small>Active Offers</small>
						</Link>
					</li>
					<li className={menu === "profile-watchlist" ? "active" : ""}>
						<Link to="/buyer/profile-watchlist">
							<span>Watchlist</span>
							<small>Track bids across products</small>
						</Link>
					</li>
					<li className={menu === "profile-profile" ? "active" : ""}>
						<Link to="/buyer/profile-profile">
							<span>Billing Info</span>
							<small>Shipping & Payment Info</small>
						</Link>
					</li>
					<li className={menu === "account-settings" ? "active" : ""}>
						<Link to="/buyer/account-settings">
							<span>Settings</span>
							<small>Notifications</small>
						</Link>
					</li>
					<li className={menu === "account-security" ? "active" : ""}>
						<Link to="/buyer/account-security">
							<span>Security</span>
							<small>Passwords</small>
						</Link>
					</li>
				</ul>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	profile: getProfile,
});

export default connect(mapStateToProps)(withRouter(BuyerProfileMenu));
