import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { extractErrors } from "../../helpers/commonHelper";
import { shopSubmitContactForm } from "../../services/ApiService";
import logo from "../../assets/images/order-confirm-grey-logo.svg";
import "./legal-pages.styles.scss";
import NormalLoading from "../../components/ui-kits/normal-loading.component";

const initialForm = {
	email: "",
	first_name: "",
	last_name: "",
	message: "",
};

const ContactUsPage = () => {
	const isIn = useRef();
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);

	const changeForm = (event) => {
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
	};
	const submitForm = (event) => {
		event.preventDefault();
		setLoading(true);
		shopSubmitContactForm(form)
			.then((res) => {
				if (isIn.current) {
					setLoading(false);
					setForm(initialForm);
					toast.success("Submitted successfully");
				}
			})
			.catch((err) => {
				if (isIn.current) {
					toast.error(extractErrors(err)[0]);
					setLoading(false);
				}
			});
	};

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="contact-us-page">
			<div className="contents">
				<img className="contact-img" src={logo} alt="eliqx grey logo" />
				<h1 className="title">Contact Us</h1>
				{loading ? (
					<div className="loading">
						<NormalLoading />
					</div>
				) : (
					<form className="form" onSubmit={submitForm.bind(this)}>
						<div className="form-group">
							<label htmlFor="email">Email</label>
							<input type="email" name="email" value={form.email} onChange={changeForm.bind(this)} required />
						</div>
						<div className="form-group">
							<label htmlFor="first_name">First Name</label>
							<input type="text" name="first_name" value={form.first_name} onChange={changeForm.bind(this)} required />
						</div>
						<div className="form-group">
							<label htmlFor="last_name">Last Name</label>
							<input type="text" name="last_name" value={form.last_name} onChange={changeForm.bind(this)} required />
						</div>
						<div className="form-group">
							<label htmlFor="message">Message</label>
							<textarea type="text" name="message" value={form.message} onChange={changeForm.bind(this)} required />
						</div>
						<button className="submit" type="submit">
							Submit
						</button>
					</form>
				)}
			</div>
		</div>
	);
};

export default ContactUsPage;
