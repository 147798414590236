import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../redux/auth/auth.selector";
import { Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import "./admin.styles.scss";
import { adminCreateDiscountCode, adminDeleteDiscountCode, adminGetDiscountCodes, adminUpdateDiscountCode } from "../../services/ApiService";
import { toast } from "react-toastify";
import { extractErrors } from "../../helpers/commonHelper";

const initialDiscountForm = {
	code: "",
	type: "FIXED",
	value: "",
	status: "ON",
};

const DiscountModal = ({ visible, setVisible, isCreating, defaultForm, callback, token }) => {
	const [form, setForm] = useState(initialDiscountForm);

	const inputChange = (id, event) => {
		setForm({ ...form, [id]: event.target.value });
	};
	const submit = () => {
		setVisible(false);
		if (isCreating) {
			adminCreateDiscountCode(token, form)
				.then((res) => {
					toast.success("Created successfully");
					callback();
				})
				.catch((err) => {
					setVisible(true);
					toast.error(extractErrors(err)[0]);
				});
		} else {
			adminUpdateDiscountCode(token, form.id, form)
				.then((res) => {
					toast.success("Updated successfully");
					callback();
				})
				.catch((err) => {
					setVisible(true);
					toast.error(extractErrors(err)[0]);
				});
		}
	};
	useLayoutEffect(() => {
		setForm({ ...initialDiscountForm, ...defaultForm });
	}, [defaultForm]);

	return (
		<Modal isOpen={visible} className="modal-login modal-dialog-centered auth-modal">
			<ModalHeader>
				<div className="mb-0" style={{ color: "white" }}>
					{isCreating ? "Create new discount code" : "Update discount code"}
				</div>
			</ModalHeader>
			<ModalBody>
				<div className="form-group">
					<label htmlFor="code">Code</label>
					<input type="text" className="form-control" id="code" value={form.code} onChange={inputChange.bind(this, "code")} autoComplete="off" />
				</div>
				<div className="form-group">
					<label htmlFor="type">Type</label>
					<select className="form-control" id="type" value={form.type} onChange={inputChange.bind(this, "type")}>
						<option value="FIXED">FIXED</option>
						<option value="PERCENT">PERCENT</option>
					</select>
				</div>
				<div className="form-group">
					<label htmlFor="value">Value</label>
					<input type="text" className="form-control" id="value" value={form.value} onChange={inputChange.bind(this, "value")} autoComplete="off" />
				</div>
				<div className="form-group">
					<label htmlFor="status">Status</label>
					<select className="form-control" id="status" value={form.status} onChange={inputChange.bind(this, "status")}>
						<option value="ON">ON</option>
						<option value="OFF">OFF</option>
					</select>
				</div>
				<div className="form-group">
					<button className="btn btn-success" onClick={submit}>
						Confirm
					</button>
					<button className="btn btn-warning" onClick={() => setVisible(!visible)}>
						Close
					</button>
				</div>
			</ModalBody>
		</Modal>
	);
};

const AdminDiscountCodesPage = ({ token }) => {
	const [discountCodes, setDiscountCodes] = useState();
	const [visible, setVisible] = useState(false);
	const [isCreating, setIsCreating] = useState(true);
	const [selectedItem, setSelectedItem] = useState();

	const loadData = useCallback(() => {
		adminGetDiscountCodes(token)
			.then((res) => {
				setDiscountCodes(res);
			})
			.catch((err) => {
				toast.error(extractErrors(err)[0]);
			});
	}, [token]);

	const createClick = () => {
		setIsCreating(true);
		setSelectedItem(null);
		setVisible(true);
	};
	const updateClick = (item) => {
		setSelectedItem(item);
		setIsCreating(false);
		setVisible(true);
	};
	const deleteItem = (item) => {
		adminDeleteDiscountCode(token, item.id)
			.then((res) => {
				toast.success("Deleted successfully");
				loadData();
			})
			.catch((err) => {
				toast.error(extractErrors(err[0]));
			});
	};

	useEffect(() => {
		loadData();
	}, [loadData]);

	return (
		<div className="admin-discount-codes-page">
			<div className="site-content">
				<div className="content-wrapper section-ptb">
					<Container>
						<button className="btn btn-sm btn-success" onClick={createClick}>
							Create discount code
						</button>
						<table className="table table-bordered">
							<thead>
								<tr>
									<th scope="col">Code</th>
									<th scope="col">Type</th>
									<th scope="col">Amount</th>
									<th scope="col">Status</th>
									<th scope="col">Update/Delete</th>
								</tr>
							</thead>
							<tbody>
								{discountCodes &&
									discountCodes.map((item) => (
										<tr key={item.id}>
											<td>{item.code}</td>
											<td>{item.type}</td>
											<td>
												{item.type === "FIXED" ? "$" : ""}
												{parseFloat(item.value).toFixed(2)}
												{item.type === "PERCENT" ? "%" : ""}
											</td>
											<td>{item.status}</td>
											<td>
												<button className="btn btn-sm btn-info" onClick={updateClick.bind(this, item)}>
													Update
												</button>
												<button className="btn btn-sm btn-danger" onClick={deleteItem.bind(this, item)}>
													Delete
												</button>
											</td>
										</tr>
									))}
							</tbody>
						</table>
						<DiscountModal visible={visible} setVisible={setVisible} isCreating={isCreating} token={token} defaultForm={selectedItem} callback={loadData} />
					</Container>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(AdminDiscountCodesPage);
