import { API_URL } from "../env";

const defaultHeader = {
	"Content-Type": "application/json",
	Accept: "application/json",
};

export const authLogin = async (email, password, more = {}) => {
	const response = await fetch(`${API_URL}/auth/login`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify({ email, password, ...more }),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authRegister = async (form) => {
	const response = await fetch(`${API_URL}/auth/register`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authGetProfile = async (token) => {
	const response = await fetch(`${API_URL}/auth/me?token=${token}`, { method: "POST", headers: defaultHeader });
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authLogout = async (token) => {
	const response = await fetch(`${API_URL}/auth/logout?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authUpdateProfile = async (token, form) => {
	const response = await fetch(`${API_URL}/auth/update-profile?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authUpdatePassword = async (token, form) => {
	const response = await fetch(`${API_URL}/auth/update-password?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authPrepareGoogleSignIn = async () => {
	const response = await fetch(`${API_URL}/google/sign-in-request?device_id=web`, { method: "GET", headers: defaultHeader });
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authPrepareAppleSignIn = async (api_key) => {
	const response = await fetch(`${API_URL}/apple/sign-in-request?api_key=${api_key}`, { method: "GET", headers: defaultHeader });
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authSignWithToken = async (token, code = null) => {
	const response = await fetch(`${API_URL}/auth/sign-with-token`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify({ token, code }),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authForgotPassword = async (form) => {
	const response = await fetch(`${API_URL}/auth/forgot-password`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authResetPassword = async (form) => {
	const response = await fetch(`${API_URL}/auth/reset-password`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authSmsVerifyRequest = async (phone_number) => {
	const response = await fetch(`${API_URL}/auth/request-sms-verify-code`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify({ phone_number }),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authEmailVerifyRequest = async (email) => {
	const response = await fetch(`${API_URL}/auth/request-email-verify-code`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify({ email }),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authRequestTwoFactorAuthCode = async (form) => {
	const response = await fetch(`${API_URL}/auth/request-two-factor-auth-code`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authGetSettings = async (token) => {
	const response = await fetch(`${API_URL}/user/settings?token=${token}`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authSetSettings = async (token, form) => {
	const response = await fetch(`${API_URL}/user/settings?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authUpdateTwoFactorAuth = async (token, form) => {
	const response = await fetch(`${API_URL}/user/two-factor-auth?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const authVerifyEmailLink = async (token, link) => {
	const response = await fetch(`${API_URL}/auth/verify-email-link?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify({ link }),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const shopGetProducts = async (filter = {}, per_page = 10, page = 1, token = null) => {
	const response = await fetch(`${API_URL}/shop/products?per_page=${per_page}&page=${page}&token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(filter),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const shopGetProductDetail = async (productId, token = null, chart = null, similar_products) => {
	const response = await fetch(`${API_URL}/shop/product/${productId}?token=${token}&chart=${chart}&similar_products=${similar_products}`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const shopCalcTransactionFees = async (who, form) => {
	const response = await fetch(`${API_URL}/shop/calculate-fees/${who}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const shopGetDiscountCode = async (code, price = 0) => {
	const response = await fetch(`${API_URL}/shop/get-discount-code`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify({ price, code }),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const shopGetFilterOptions = async () => {
	const response = await fetch(`${API_URL}/shop/filter-options`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const shopGetSystemSettings = async (query = null) => {
	const response = await fetch(`${API_URL}/shop/system-settings?${query}`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const shopSubmitContactForm = async (form) => {
	const response = await fetch(`${API_URL}/shop/submit-contact-form`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const sellerRegisterToProduct = async (token, product_id, form) => {
	const response = await fetch(`${API_URL}/seller/register-to-product/${product_id}?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const sellerCreateProduct = async (token, form) => {
	const response = await fetch(`${API_URL}/seller/product?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const sellerUpdateProduct = async (token, productId, form) => {
	const response = await fetch(`${API_URL}/seller/product/${productId}?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const sellerDeleteProduct = async (token, productId) => {
	const response = await fetch(`${API_URL}/seller/product/${productId}?token=${token}`, {
		method: "DELETE",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const sellerAcceptBid = async (token, bidId, form) => {
	const response = await fetch(`${API_URL}/seller/sell-product/${bidId}?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const sellerGetMyListings = async (token, status) => {
	const response = await fetch(`${API_URL}/seller/my-listings?token=${token}&status=${status}`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const sellerGetMyListingById = async (token, listingId) => {
	const response = await fetch(`${API_URL}/seller/my-listings?token=${token}&listing_id=${listingId}`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	if (!result || result.length <= 0) throw new Error("Not found");
	return result[0];
};

export const sellerUpdateMyListing = async (token, listingId, form) => {
	const response = await fetch(`${API_URL}/seller/update-listing/${listingId}?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const sellerRequestConnectBank = async (token) => {
	const response = await fetch(`${API_URL}/seller/request-payment-register?token=${token}`, { method: "GET", headers: defaultHeader });
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const sellerGetMySells = async (token, form = {}) => {
	const response = await fetch(`${API_URL}/seller/my-sells?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const sellerRequestShipping = async (token, orderId, form = {}) => {
	const response = await fetch(`${API_URL}/seller/request-ship/${orderId}?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const sellerRemoveListing = async (token, listingId) => {
	const response = await fetch(`${API_URL}/seller/cancel-listing/${listingId}?token=${token}`, {
		method: "DELETE",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const sellerCreateOnCallPickup = async (token, form) => {
	const response = await fetch(`${API_URL}/user/on-call-pickup?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerGetOrders = async (token) => {
	const response = await fetch(`${API_URL}/buyer/my-orders?token=${token}`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerGetBillingCards = async (token) => {
	const response = await fetch(`${API_URL}/buyer/billing-cards?token=${token}`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerSaveBillingCard = async (token, form) => {
	const response = await fetch(`${API_URL}/buyer/billing-card?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerRemoveBillingCard = async (token, billingId) => {
	const response = await fetch(`${API_URL}/buyer/billing-card/${billingId}?token=${token}`, {
		method: "DELETE",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerUpdateBillingCard = async (token, billingId, form) => {
	const response = await fetch(`${API_URL}/buyer/billing-card/${billingId}?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerGetShippingInfos = async (token) => {
	const response = await fetch(`${API_URL}/buyer/shipping-info?token=${token}`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerSaveShippingInfo = async (token, form) => {
	const response = await fetch(`${API_URL}/buyer/shipping-info?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerUpdateShippingInfo = async (token, shippingId, form) => {
	const response = await fetch(`${API_URL}/buyer/shipping-info/${shippingId}?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerRemoveShippingInfo = async (token, shippingInfo) => {
	const response = await fetch(`${API_URL}/buyer/shipping-info/${shippingInfo}?token=${token}`, {
		method: "DELETE",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerSubmitBid = async (token, productId, form) => {
	const response = await fetch(`${API_URL}/buyer/submit-bid/${productId}?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerCloneBidToProducts = async (token, bidId, product_ids) => {
	const response = await fetch(`${API_URL}/buyer/clone-bid-to-products/${bidId}?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify({ product_ids }),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerRemoveMyBid = async (token, bidId) => {
	const response = await fetch(`${API_URL}/buyer/remove-bid/${bidId}?token=${token}`, {
		method: "DELETE",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerSubmitOrder = async (token, listingId, form) => {
	const response = await fetch(`${API_URL}/buyer/submit-order/${listingId}?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerGetMyBids = async (token) => {
	const response = await fetch(`${API_URL}/buyer/my-bids?token=${token}`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerGetMyBidById = async (token, bidId) => {
	const response = await fetch(`${API_URL}/buyer/my-bids?token=${token}&bid_id=${bidId}`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	if (!result || result.length <= 0) throw new Error("Not found");
	return result[0];
};

export const buyerUpdateMyBid = async (token, bidId, form) => {
	const response = await fetch(`${API_URL}/buyer/my-bid/${bidId}?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerGetWatchLists = async (token) => {
	const response = await fetch(`${API_URL}/buyer/watch-lists?token=${token}`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerAddWatchList = async (token, productId) => {
	const response = await fetch(`${API_URL}/buyer/watch-list/${productId}?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerRemoveWatchList = async (token, productId) => {
	const response = await fetch(`${API_URL}/buyer/watch-list/${productId}?token=${token}`, {
		method: "DELETE",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const buyerSubmitSellerProfile = async (token, form) => {
	const response = await fetch(`${API_URL}/auth/create-seller-profile?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminGetUsers = async (token, filter = {}) => {
	const response = await fetch(`${API_URL}/admin/get-users?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(filter),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminGetBuyerOrders = async (token, filter = {}) => {
	const response = await fetch(`${API_URL}/admin/get-orders?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(filter),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminGetBuyerBids = async (token, filter = {}) => {
	const response = await fetch(`${API_URL}/admin/get-bids?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(filter),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminCreateProduct = async (token, form) => {
	const response = await fetch(`${API_URL}/admin/product?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminUpdateProductDetail = async (token, productId, form) => {
	const response = await fetch(`${API_URL}/admin/product/${productId}?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminDeleteProduct = async (token, productId) => {
	const response = await fetch(`${API_URL}/admin/product/${productId}?token=${token}`, {
		method: "DELETE",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminUpdateSellerProductStatus = async (token, sellerProductId, status) => {
	const response = await fetch(`${API_URL}/admin/seller-product-status/${sellerProductId}?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify({ status }),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminGetUserDetail = async (token, userId) => {
	const response = await fetch(`${API_URL}/admin/user-detail/${userId}?token=${token}&include_shipping=1&include_billing=1`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminApproveSellerProfile = async (token, userId, form) => {
	const response = await fetch(`${API_URL}/admin/update-user-seller-profile/${userId}?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminGetOrderDetail = async (token, orderId) => {
	const response = await fetch(`${API_URL}/admin/order-detail/${orderId}?token=${token}`, { method: "GET", headers: defaultHeader });
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminPayoutToSeller = async (token, buyerOrderId) => {
	const response = await fetch(`${API_URL}/admin/payout-to-seller/${buyerOrderId}?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminGetDiscountCodes = async (token) => {
	const response = await fetch(`${API_URL}/admin/discount-codes?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminCreateDiscountCode = async (token, form) => {
	const response = await fetch(`${API_URL}/admin/discount-code?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminUpdateDiscountCode = async (token, id, form) => {
	const response = await fetch(`${API_URL}/admin/discount-code/${id}?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminDeleteDiscountCode = async (token, id) => {
	const response = await fetch(`${API_URL}/admin/discount-code/${id}?token=${token}`, {
		method: "DELETE",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminCreateBoxShipment = async (token, orderId, form = {}) => {
	const response = await fetch(`${API_URL}/admin/request-box-ship/${orderId}?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminCreateBottleShipment = async (token, orderId) => {
	const response = await fetch(`${API_URL}/admin/request-bottle-ship/${orderId}?token=${token}`, {
		method: "POST",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminUpdateBuyerOrder = async (token, orderId, form) => {
	const response = await fetch(`${API_URL}/admin/buyer-order/${orderId}?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify(form),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminUpdateSiteSetting = async (token, form) => {
	const response = await fetch(`${API_URL}/admin/site-setting?token=${token}`, {
		method: "PUT",
		headers: defaultHeader,
		body: JSON.stringify({ pairs: form }),
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminGetSiteSettings = async (token) => {
	const response = await fetch(`${API_URL}/admin/site-settings?token=${token}`, {
		method: "GET",
		headers: defaultHeader,
	});
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminGetProductDetail = async (productId, token) => {
	const response = await fetch(
		`${API_URL}/shop/product/${productId}?token=${token}&listing_status=${encodeURIComponent(JSON.stringify(["PENDING", "APPROVED", "REJECTED", "EXPIRED"]))}`,
		{
			method: "GET",
			headers: defaultHeader,
		}
	);
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};

export const adminGetFailedTransactions = async (token) => {
	const response = await fetch(`${API_URL}/admin/failed-transactions?token=${token}`, { method: "GET", headers: defaultHeader });
	const result = await response.json();
	if (!response.ok) throw result;
	return result;
};
