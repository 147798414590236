import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import { extractErrors, strToPhoneNumberFormat, strToPhoneNumberString } from "../../../helpers/commonHelper";
import { getToken } from "../../../redux/auth/auth.selector";
import { buyerGetShippingInfos, buyerSaveShippingInfo } from "../../../services/ApiService";
import "./buyer-save-shipping-form.styles.scss";

const initialShippingForm = {
	first_name: "",
	last_name: "",
	phone_number: "",
	address_1: "",
	address_2: "",
	city: "",
	zip_code: "",
	special_instruction: "",
};

const BuyerSaveShippingForm = ({ token, selectedShipping, setSelectedShipping }) => {
	const isIn = useRef();
	const [loading, setLoading] = useState(false);
	const [openShipping, setOpenShipping] = useState(false);
	const [shippingForm, setShippingForm] = useState(initialShippingForm);
	const [savedShipping, setSavedShipping] = useState([]);

	const shippingInputHandle = (event) => {
		let { name, value } = event.target;
		if (name === "phone_number") value = strToPhoneNumberString(value);
		else if (name === "special_instruction") value = value.substr(0, 100);
		setShippingForm({ ...shippingForm, [name]: value });
	};

	const selectShipping = (item) => {
		setSelectedShipping(item);
		setOpenShipping(false);
	};

	const submitShipping = () => {
		setLoading(true);
		buyerSaveShippingInfo(token, shippingForm)
			.then((res) => {
				if (!isIn.current) return;
				setSelectedShipping(res);
				setSavedShipping([...savedShipping, res]);
				setShippingForm(initialShippingForm);
				setOpenShipping(false);
				setLoading(false);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
				setLoading(false);
			});
	};

	const loadSavedShippingInfos = useCallback(() => {
		setSavedShipping(null);
		buyerGetShippingInfos(token)
			.then((res) => {
				if (!isIn.current) return;
				setSavedShipping(res);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	}, [token]);

	useLayoutEffect(() => {
		loadSavedShippingInfos();
	}, [loadSavedShippingInfos]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="buyer-save-shipping-form">
			{!loading && savedShipping && (
				<div className="shippingInfo">
					{savedShipping.length > 0 && (
						<div className={`choosePaymentMethod collapse-content ${openShipping ? "show" : ""}`}>
							<h4>Choose Shipping Address </h4>
							{savedShipping.map((x) => (
								<p key={x.id} className={selectedShipping?.id === x.id ? "selected_item" : ""} onClick={selectShipping.bind(this, x)}>
									{x.first_name ?? ""} {x.last_name ?? ""} {x.address_1 ?? ""} {x.address_2 ?? ""} {x.city ?? ""} {x.zip_code ?? ""}
									{strToPhoneNumberFormat(x.phone_number)}
								</p>
							))}
						</div>
					)}
					<button className="collapsbtn" onClick={() => setOpenShipping(true)}>
						{!openShipping && selectedShipping
							? `${selectedShipping.first_name ?? ""} ${selectedShipping.last_name ?? ""} ${strToPhoneNumberFormat(selectedShipping.phone_number)} ${
									selectedShipping.address_1 ?? ""
							  } ${selectedShipping.address_2 ?? ""} ${selectedShipping.city ?? ""} ${selectedShipping.zip_code ?? ""}`
							: "Add Shipping Info"}
					</button>
					<div className={`collapse-content ${openShipping ? "show" : ""}`}>
						<div className="row px-2 py-3">
							<div className="col-md-6">
								<input
									type="text"
									className="payment_input form-control"
									placeholder="First Name"
									name="first_name"
									value={shippingForm.first_name}
									onChange={shippingInputHandle.bind(this)}
								/>
							</div>
							<div className="col-md-6">
								<input
									type="text"
									className="payment_input form-control"
									placeholder="Last Name"
									name="last_name"
									value={shippingForm.last_name}
									onChange={shippingInputHandle.bind(this)}
								/>
							</div>
							<div className="col-12">
								<input
									type="text"
									className="payment_input form-control"
									placeholder="Street Address"
									name="address_1"
									value={shippingForm.address_1}
									onChange={shippingInputHandle.bind(this)}
								/>
							</div>
							<div className="col-12">
								<input
									type="text"
									className="payment_input form-control"
									placeholder="Street Address Second (optional)"
									name="address_2"
									value={shippingForm.address_2}
									onChange={shippingInputHandle.bind(this)}
								/>
							</div>
							<div className="col-md-5">
								<input
									type="text"
									className="payment_input form-control"
									placeholder="City"
									name="city"
									value={shippingForm.city}
									onChange={shippingInputHandle.bind(this)}
								/>
							</div>
							<div className="col-md-3 col-6">
								<input type="text" className="payment_input form-control" placeholder="State" value="CA" disabled />
							</div>
							<div className="col-md-4 col-6">
								<input
									type="text"
									className="payment_input form-control"
									placeholder="Zip Code"
									name="zip_code"
									value={shippingForm.zip_code}
									onChange={shippingInputHandle.bind(this)}
								/>
							</div>
							<div className="col-md-12">
								<input
									type="text"
									className="payment_input form-control"
									placeholder="Special instructions 100 character limit"
									name="special_instruction"
									value={shippingForm.special_instruction}
									onChange={shippingInputHandle.bind(this)}
								/>
							</div>
							<div className="col-md-12">
								<input
									type="text"
									className="payment_input form-control"
									placeholder="Phone number"
									name="phone_number"
									value={strToPhoneNumberFormat(shippingForm.phone_number)}
									onChange={shippingInputHandle.bind(this)}
								/>
							</div>
						</div>
						<button className="confirmBtn" onClick={submitShipping}>
							confirm
						</button>
					</div>
					<span className="icon-cc-down-arrow"></span>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(BuyerSaveShippingForm);
