import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import "./seller-profile.styles.scss";
import { createStructuredSelector } from "reselect";
import { getProfile } from "../../../redux/auth/auth.selector";
import { connect } from "react-redux";

const SellerProfileMenu = ({ location, profile, history }) => {
	const menu = location.pathname.split("/")[2];
	const [show, setShow] = useState(false);

	const goToMyBottle = () => {
		history.push("/seller/make-product-listing");
	};

	return (
		<div className="seller-profile-menu">
			<div className="dashboard-menu">
				<h2 className="dashboard-title">SELLER DASHBOARD</h2>
				<button className="sell-my-bottle-link" onClick={goToMyBottle}>
					CREATE LISTING
				</button>
				<p className="dashboard-subtitle">{profile.email}</p>
				<div className="left-menu">
					<button onClick={() => setShow(!show)}>
						<span className="icon-cc-down-arrow"></span>
					</button>
					<ul className={`menu ${show ? "show" : ""}`}>
						<li className={menu === "profile-portfolio" ? "active" : ""}>
							<Link to="/seller/profile-portfolio">
								<span>Sell Orders</span>
								<small>Listing Info</small>
							</Link>
						</li>
						<li className={menu === "buyer-portfolio" ? "active" : ""}>
							<Link to="/seller/buyer-portfolio">
								<span>Buy Orders</span>
								<small>Active Bids & Purchases</small>
							</Link>
						</li>
						<li className={menu === "profile-watchlist" ? "active" : ""}>
							<Link to="/seller/profile-watchlist">
								<span>Watchlist</span>
								<small>Track bids across products</small>
							</Link>
						</li>
						<li className={menu === "profile-profile" ? "active" : ""}>
							<Link to="/seller/profile-profile">
								<span>Billing Info</span>
								<small>Shipping & Payment Info</small>
							</Link>
						</li>
						<li className={menu === "account-settings" ? "active" : ""}>
							<Link to="/seller/account-settings">
								<span>Settings</span>
								<small>Notifications</small>
							</Link>
						</li>
						<li className={menu === "account-security" ? "active" : ""}>
							<Link to="/seller/account-security">
								<span>Security</span>
								<small>Manage security settings</small>
							</Link>
						</li>
						<li className={menu === "profile-on-call-pickup" ? "active" : ""}>
							<Link to="/seller/profile-on-call-pickup">
								<span>Pickup Request</span>
								<small>Schedule a pickup</small>
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	profile: getProfile,
});

export default connect(mapStateToProps)(withRouter(SellerProfileMenu));
