import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./footer.styles.scss";
import logo from "../../assets/images/eliqx-logo-all-yellow.png";
import googleplay from "../../assets/images/google-play-badge.png";
import appstore from "../../assets/images/apple-app-iphone-store.png";
import credit_card_png from "../../assets/images/credit-card-payments-logos.png";
import { createStructuredSelector } from "reselect";
import { getIsActivedSeller, getProfile, getToken } from "../../redux/auth/auth.selector";
import { connect } from "react-redux";

const Footer = ({ token, profile, isActivedSeller }) => {
	const backtotop = { display: "none" };
	const checkScroll = () => {
		var scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
		if (scrollTop > 350) {
			if (document.getElementById("back-to-top") != null) {
				document.getElementById("back-to-top").setAttribute("style", "display:block");
			}
		} else {
			if (document.getElementById("back-to-top") != null) {
				document.getElementById("back-to-top").setAttribute("style", "display:none");
			}
		}
	};
	const clickToTop = () => {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
	};
	useEffect(() => {
		window.addEventListener("scroll", checkScroll);
		return () => {
			window.removeEventListener("scroll", checkScroll);
		};
	}, []);

	return (
		<div className="footer-component">
			<footer>
				<div className="page-width">
					<div className="footer-top">
						<div className="about">
							<h4>About</h4>
							<p>
								eliq<span className="icon-cc-x-logo"></span>, an adult beverage focused application for a new era of at home consumption.
							</p>
							<a rel="noreferrer" href="https://play.google.com/store/apps/details?id=io.eliqx" target="_blank">
								<img src={googleplay} alt="google-play" />
							</a>
							<a rel="noreferrer" href="https://apps.apple.com/us/app/eliqx/id1556453431" target="_blank">
								<img src={appstore} alt="app-store" />
							</a>
							<div className="credit-card-div">
								<img src={credit_card_png} alt="credit-card-png" />
								<p>Payments powered by stripe</p>
							</div>
						</div>
						<div className="links-col">
							<h4>Buyer Info</h4>
							<ul>
								<li>
									<Link to="/buyer/guide" onClick={clickToTop}>
										How does it work?
									</Link>
								</li>
								<li>
									<Link to="/buyer/faq" onClick={clickToTop}>
										Buyer FAQ
									</Link>
								</li>
								{token && (
									<li>
										<Link to="/buyer/profile-portfolio?tab=PURCHASED" onClick={clickToTop}>
											Order Status
										</Link>
									</li>
								)}
								{token && (
									<li>
										<Link to="/buyer/profile-portfolio" onClick={clickToTop}>
											Buyer Dashboard
										</Link>
									</li>
								)}
							</ul>
						</div>
						<div className="links-col s-info">
							<h4>Seller Info</h4>
							<ul>
								<li>
									<Link to={!token ? "/login" : profile?.seller_profile ? "#" : "/buyer/register-seller-profile"} onClick={clickToTop}>
										Register to sell on eliqx
									</Link>
								</li>
								{isActivedSeller && (
									<>
										<li>
											<Link to="/seller/guide" onClick={clickToTop}>
												Seller guide
											</Link>
										</li>
										<li>
											<Link to="/seller/faq" onClick={clickToTop}>
												Requirements to sell on eliqx
											</Link>
										</li>
										<li>
											<Link to="/seller/profile-portfolio" onClick={clickToTop}>
												Seller Dashboard
											</Link>
										</li>
										<li>
											<Link to="/seller/make-product-listing" onClick={clickToTop}>
												Create Listing
											</Link>
										</li>
										<li>
											<Link to="/seller/legal/terms-of-use" onClick={clickToTop}>
												Sellers Terms of Service
											</Link>
										</li>
									</>
								)}
							</ul>
						</div>
						<div className="links-col">
							<h4>Fine Print</h4>
							<ul>
								<li>
									<Link to="/legal/terms-of-use" onClick={clickToTop}>
										Terms of Service
									</Link>
								</li>
								<li>
									<Link to="/legal/privacy-policy" onClick={clickToTop}>
										Privacy Policy
									</Link>
								</li>
								<li>
									<Link to="/legal/return-policy" onClick={clickToTop}>
										Refund Policy
									</Link>
								</li>
								<li>
									<a href="https://www.p65warnings.ca.gov/products/alcoholic-beverages">CA Proposition 65</a>
								</li>
								<li>
									<Link to="/legal/contact-us" onClick={clickToTop}>
										Contact Us
									</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="footer-bottom">
						<img src={logo} alt="yellow" />
						<p>Copyright © 2022 All Rights Reserved Liberty Market, LLC.</p>
					</div>
				</div>
			</footer>
			<div id="back-to-top" style={backtotop} onClick={clickToTop}>
				<Link to="#" className="top arrow" replace={true}>
					<span className="icon-cc-up-arrow"></span>
				</Link>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
	profile: getProfile,
	isActivedSeller: getIsActivedSeller,
});

export default connect(mapStateToProps)(Footer);
