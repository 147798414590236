import React from "react";
import { numberToPriceString } from "../../helpers/commonHelper";
import "./shop.styles.scss";
import { Link } from "react-router-dom";

const PackageBox = ({ product }) => (
	<div className="product-details">
		<p className="product-detail">{product.size}</p>
		<p className="product-detail">{product.abv}% ABV</p>
		{product.has_box !== null && <p className="product-detail">{product.has_box ? "With" : "Without"} BOX</p>}
		{product.has_tag !== null && <p className="product-detail">{product.has_tag ? "With" : "Without"} TAG</p>}
		{product.has_bag !== null && <p className="product-detail">{product.has_bag ? "With" : "Without"} BAG</p>}
		{product.has_tin !== null && <p className="product-detail">{product.has_tin ? "With" : "Without"} TIN</p>}
		{product.has_case !== null && <p className="product-detail">{product.has_case ? "With" : "Without"} CASE</p>}
	</div>
);

const ProductRelativeProducts = ({ products }) => (
	<div className="product-relative-products">
		{products && (
			<div className="all-products-row">
				{products.map((product) => (
					<div className="product-card" key={product.id}>
						<div className="product-card-link">
							<Link to={`/shop/product/${product.id}`}>
								<img className="product-img" src={product.images[0]} alt="eliqx rare spirits product profile" />
							</Link>
							<div className="product-info">
								<h2 className="product-name">{product.name}</h2>
								<PackageBox product={product} />
								<div className="product-price-block">
									<span className="product-listing-current-price">${numberToPriceString(product.min_price)}</span>
									<br />
									<span className="product-listing-status">Lowest Asking Price</span>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		)}
	</div>
);

export default ProductRelativeProducts;
