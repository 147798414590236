import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { getProfile, getToken } from "../../../redux/auth/auth.selector";
import {
	buyerGetBillingCards,
	buyerGetShippingInfos,
	buyerRemoveBillingCard,
	buyerRemoveShippingInfo,
	buyerSaveShippingInfo,
	buyerUpdateShippingInfo,
	sellerRequestConnectBank,
} from "../../../services/ApiService";
import SellerProfileMenu from "./seller-profile-menu.component";
import "./seller-profile.styles.scss";
import { toast } from "react-toastify";
import { extractErrors, strToPhoneNumberFormat, strToPhoneNumberString } from "../../../helpers/commonHelper";
import BuyerSaveStripeCard from "../../../components/buyer/buyer-save-stripe-card/buyer-save-stripe-card.component";
import * as AuthAction from "../../../redux/auth/auth.action";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";

const initialShippingForm = {
	first_name: "",
	last_name: "",
	phone_number: "",
	address_1: "",
	address_2: "",
	city: "",
	zip_code: "",
	special_instruction: "",
};

const PayoutBankBox = ({ token, profile, loadProfile }) => {
	const isIn = useRef();
	const payoutBankName = () => {
		return profile?.seller_profile?.stripe_account?.business_profile?.name ?? "NO BANK NAME";
	};
	const bankIsConnected = !!profile?.seller_profile?.stripe_account?.payouts_enabled;
	const [loading, setLoading] = useState(false);
	const connectBank = async (event) => {
		if (bankIsConnected) return;
		event.preventDefault();
		setLoading(true);
		try {
			if (!isIn.current) return;
			let result = await sellerRequestConnectBank(token);
			const newWindow = window.open(result.url, "_blank");
			const timer = setInterval(() => {
				if (!newWindow?.closed) return;
				clearInterval(timer);
				loadProfile()
					.then(() => {
						if (isIn.current) setLoading(false);
					})
					.catch((err) => {
						if (isIn.current) {
							toast.error(extractErrors(err)[0]);
							setLoading(false);
						}
					});
			}, 500);
		} catch (error) {
			setLoading(false);
			toast.error(extractErrors(error)[0]);
		}
	};

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="info-sec payout-info">
			<h4>PAYOUT INFO</h4>
			{loading ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<div className="details">
					<p>
						If you have your banking login info for most major banks please connect via plaid below otherwise use top button to enter routing and bank account
						number.
					</p>
					<div className="buttons">
						{bankIsConnected && <p className="bank-name">{payoutBankName()}</p>}
						<Link to="#" className="button" onClick={connectBank} replace={true}>
							{bankIsConnected ? "BANK CONNECTED" : "ENTER BANKING INFO"}
						</Link>
					</div>
				</div>
			)}
		</div>
	);
};

const ShippingInfoBox = ({ token, isPickup }) => {
	const isIn = useRef();
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState(initialShippingForm);
	const [showForm, setShowForm] = useState(false);
	const [selectedShippingId, setSelectedShippingId] = useState();
	const newShipping = () => {
		setSelectedShippingId(null);
		setForm(initialShippingForm);
		setShowForm(true);
	};
	const selectShipping = (item) => {
		setSelectedShippingId(item.id);
		setForm({ ...form, ...item });
		setShowForm(true);
	};
	const inputHandle = (event) => {
		let { name, value } = event.target;
		if (name === "phone_number") value = strToPhoneNumberString(value);
		else if (name === "special_instruction") value = value.substr(0, 100);
		setForm({ ...form, [name]: value });
	};
	const submitForm = () => {
		setLoading(true);
		for (let i in form) if (form[i] === null) delete form[i];
		if (!selectedShippingId) {
			buyerSaveShippingInfo(token, { ...form, is_pickup: isPickup })
				.then((res) => {
					if (isIn.current) {
						setShowForm(false);
						loadData();
					}
				})
				.catch((err) => {
					if (isIn.current) {
						setLoading(false);
						toast.error(extractErrors(err)[0]);
					}
				});
		} else {
			buyerUpdateShippingInfo(token, selectedShippingId, form)
				.then((res) => {
					if (isIn.current) {
						setShowForm(false);
						loadData();
					}
				})
				.catch((err) => {
					if (isIn.current) {
						setLoading(false);
						toast.error(extractErrors(err)[0]);
					}
				});
		}
	};
	const cancelClick = () => {
		setSelectedShippingId(null);
		setForm(initialShippingForm);
		setShowForm(false);
	};

	const loadData = useCallback(() => {
		setLoading(true);
		buyerGetShippingInfos(token)
			.then((res) => {
				if (isIn.current) {
					setData(res.filter((x) => (isPickup ? x.is_pickup === 1 : x.is_pickup === 0)));
					setLoading(false);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [token, isPickup]);

	const removeShipping = (item) => {
		setLoading(true);
		buyerRemoveShippingInfo(token, item.id)
			.then((res) => {
				if (isIn.current) {
					loadData();
					toast.success("Deleted successfully!");
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	};

	useEffect(() => {
		loadData();
	}, [loadData]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="info-sec">
			<h4>{isPickup ? "PICKUP ADDRESS" : "SHIP TO ADDRESS"}</h4>
			{loading || !data ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<div className="all-address">
					{data.map((item) => (
						<div className="item" key={item.id}>
							<Link to="#" className="address-wrap" onClick={selectShipping.bind(this, item)} replace={true}>
								<span className="name">
									{item.first_name} {item.last_name}
								</span>
								<span className="address">
									{item.address_1} {item.address_2} <br />
									{item.city} CA {item.zip_code}
								</span>
								<span className="name">{strToPhoneNumberFormat(item.phone_number)}</span>
							</Link>
							<button className="remove-btn" onClick={removeShipping.bind(this, item)}>
								Delete
							</button>
						</div>
					))}
					{showForm && (
						<div className="item">
							<form>
								<div className="row">
									<div className="col-6">
										<input type="text" placeholder="First Name" value={form.first_name} name="first_name" onChange={inputHandle} />
									</div>
									<div className="col-6">
										<input type="text" placeholder="Last Name" value={form.last_name} name="last_name" onChange={inputHandle} />
									</div>
									<div className="col-12">
										<input type="text" placeholder="Street Address" name="address_1" value={form.address_1} onChange={inputHandle} />
									</div>
									<div className="col-12">
										<input type="text" placeholder="Apt, Suite, Unit (optional)" name="address_2" value={form.address_2 ?? ""} onChange={inputHandle} />
									</div>
									<div className="col-12">
										<input type="text" placeholder="City" name="city" value={form.city} onChange={inputHandle} />
									</div>
									<div className="col-6">
										<input type="text" placeholder="State" value="CA" disabled />
									</div>
									<div className="col-6">
										<input type="text" placeholder="Zip" name="zip_code" value={form.zip_code} onChange={inputHandle} />
									</div>
									<div className="col-12">
										<input
											type="text"
											placeholder="Special instructions 100 character limit"
											value={form.special_instruction ?? ""}
											name="special_instruction"
											onChange={inputHandle}
										/>
									</div>
									<div className="col-12">
										<input
											type="text"
											placeholder="Phone number"
											value={strToPhoneNumberFormat(form.phone_number)}
											name="phone_number"
											onChange={inputHandle}
										/>
									</div>
									<div className="col-12">
										<button type="button" onClick={submitForm}>
											SAVE
										</button>
										<Link to="#" onClick={cancelClick} replace={true}>
											cancel
										</Link>
									</div>
								</div>
							</form>
						</div>
					)}
					{data && data.length < 4 && (
						<div className="item">
							<Link to="#" className="simple-btn addnew" onClick={newShipping} replace={true}>
								Add New Address
							</Link>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

const BillingCardBox = ({ token }) => {
	const isIn = useRef();
	const [loading, setLoading] = useState(false);
	const [cards, setCards] = useState();
	const [showForm, setShowForm] = useState(false);

	const newCard = () => {
		setShowForm(true);
	};
	const loadCards = useCallback(() => {
		setLoading(true);
		buyerGetBillingCards(token)
			.then((res) => {
				if (isIn.current) {
					setLoading(false);
					setCards(res);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [token]);

	const removeCard = (card) => {
		setLoading(true);
		buyerRemoveBillingCard(token, card.id)
			.then((res) => {
				if (isIn.current) {
					loadCards();
					toast.success("Deleted successfully!");
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	};

	useEffect(() => {
		loadCards();
	}, [loadCards]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="info-sec card-info">
			<h4>PAYMENT INFO</h4>
			{loading || !cards ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<div className="all-address">
					{cards.map((item) => (
						<div className="item" key={item.id}>
							<Link to="#" className="address-wrap" replace={true}>
								<span className="name">
									{item.first_name} {item.last_name}
								</span>
								<span className="address card-end-number">
									********{item.last4} {item.card_type}
								</span>
							</Link>
							<button className="remove-btn" onClick={removeCard.bind(this, item)}>
								Delete
							</button>
						</div>
					))}
					{showForm && (
						<div className="item">
							<BuyerSaveStripeCard callback={loadCards} closeForm={() => setShowForm(false)} />
						</div>
					)}
					{cards && cards.length < 4 && (
						<div className="item">
							<Link to="#" className="simple-btn addnew" onClick={newCard} replace={true}>
								Add New Credit Card
							</Link>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

const SellerProfilePage = ({ token, profile, loadProfile }) => (
	<div className="seller-profile-page">
		<div className="dashboard">
			<SellerProfileMenu />
			<div className="dashboard-main">
				<div className="profile">
					<div className="page-title">
						<h2>PROFILE</h2>
						<h1>{profile.email}</h1>
					</div>
					<PayoutBankBox token={token} profile={profile} loadProfile={loadProfile} />
					<ShippingInfoBox token={token} isPickup={false} />
					<ShippingInfoBox token={token} isPickup={true} />
					<BillingCardBox token={token} />
				</div>
			</div>
		</div>
	</div>
);

const mapStateToProps = createStructuredSelector({
	token: getToken,
	profile: getProfile,
});

const mapDispatchToProps = (dispatch) => ({
	loadProfile: () => dispatch(AuthAction.loadProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerProfilePage);
