import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import { extractErrors, numberToPriceString } from "../../../helpers/commonHelper";
import { getToken } from "../../../redux/auth/auth.selector";
import { sellerAcceptBid, shopCalcTransactionFees, shopGetDiscountCode, shopGetProductDetail } from "../../../services/ApiService";
import SellerSellNowConfirmedPage from "./seller-sell-now-confirmed-page.component";
import "./seller-accept-bid-page.styles.scss";
import SellerCertifyBoxComponent from "../../../components/seller/seller-certify-box.component";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";
import SellerPriceLimitExceedComponent from "../../../components/seller/seller-price-limit-exceed.component";

const SellerAcceptBidPage = ({ token, match, location }) => {
	const isIn = useRef();
	const timeout = useRef();
	const productId = match.params.productId;
	const bidId = new URLSearchParams(location.search).get("bid");
	const [loading, setLoading] = useState(false);
	const [product, setProduct] = useState();
	const [selectedBid, setSelectedBid] = useState();
	const [myListing, setMyListing] = useState();
	const [fees, setFees] = useState();
	const [discountCode, setDiscountCode] = useState("");
	const [discountPrice, setDiscountPrice] = useState();
	const takeShippingFee = true;
	const [mustCheck, setMustCheck] = useState(0);

	const loadProduct = useCallback(() => {
		setLoading(true);
		shopGetProductDetail(productId, token)
			.then((res) => {
				if (!isIn.current) return;
				setProduct(res);
				if (res.buyer_bids) {
					if (bidId) {
						setSelectedBid(res.buyer_bids.find((x) => x.id === bidId));
					} else {
						res.buyer_bids.sort((a, b) => (a.price > b.price ? -1 : 1));
						const temp = res.buyer_bids.filter((x) => x.status === "PENDING");
						if (temp && temp.length > 0) setSelectedBid(temp[0]);
					}
				}
				if (res.my_products && res.my_products.length > 0) {
					res.my_products.sort((a, b) => (a.price < b.price ? -1 : 1));
					setMyListing(res.my_products[0]);
					setDiscountCode(res.my_products[0].discount_code);
				}
				setLoading(false);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	}, [productId, token, bidId]);

	const submitSellNow = () => {
		if (!selectedBid) {
			toast.error("No selected bid");
			return;
		}
		if (mustCheck !== 7) {
			toast.error("You must agree with our terms and service policy");
			return;
		}
		setLoading(true);
		sellerAcceptBid(token, selectedBid.id, {
			bid_price: selectedBid.price,
			discount_code: discountCode,
			take_shipping_fee: takeShippingFee,
		})
			.then((res) => {
				localStorage.setItem(`sell_now_confirmed_${productId}`, JSON.stringify(res));
				setLoading(false);
			})
			.catch((err) => {
				if (isIn.current) {
					if (err.error === "sell_now_price_limit_exceed") {
						localStorage.setItem(`sell_now_price_limit_exceed_${productId}`, new Date().getTime());
						setLoading(false);
					} else {
						loadProduct();
						toast.error(extractErrors(err)[0]);
					}
				}
			});
	};

	useLayoutEffect(() => {
		loadProduct();
	}, [loadProduct]);

	useEffect(() => {
		if (timeout.current) clearTimeout(timeout.current);
		if (!selectedBid) return;
		timeout.current = setTimeout(() => {
			shopCalcTransactionFees("SELLER", { price: selectedBid.price, type: "BID", take_shipping_fee: takeShippingFee })
				.then((res) => {
					if (isIn.current) setFees(res);
				})
				.catch((err) => {
					if (isIn.current) toast.warning(extractErrors(err)[0]);
				});
		}, 3000);
	}, [selectedBid, takeShippingFee]);

	useEffect(() => {
		if (!selectedBid) return;
		shopGetDiscountCode(discountCode, selectedBid?.price)
			.then((res) => {
				setDiscountPrice(res);
			})
			.catch((err) => {
				setDiscountPrice(null);
			});
	}, [selectedBid, discountCode]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	if (!product || loading || !myListing) {
		return (
			<div className="seller-accept-bid-page">
				<div className="container">
					{loading ? (
						<div className="loading">
							<NormalLoading />
						</div>
					) : (
						<h1>Listing does not exist</h1>
					)}
				</div>
			</div>
		);
	}
	if (localStorage.getItem(`sell_now_confirmed_${productId}`)) {
		return <SellerSellNowConfirmedPage product={product} />;
	}
	return (
		<div className="seller-accept-bid-page">
			<div className="container ">
				<div className="bidScreenContainer">
					<div className="productImage">
						<img src={product.images[0]} alt={product.id} />
					</div>
					<div className="sellNow">
						<div className="top-headline">
							<div className="heading d-flex justify-content-between">
								<h2>{product.name}</h2>
								<h2>${numberToPriceString(selectedBid?.price)}</h2>
							</div>
							<ul className="details">
								<li>{product.size}</li>
								<li>{product.abv}% ABV</li>
								{product.has_box !== null && <li>{product.has_box ? "With" : "Without"} BOX</li>}
								{product.has_tag !== null && <li>{product.has_tag ? "With" : "Without"} TAG</li>}
								{product.has_bag !== null && <li>{product.has_bag ? "With" : "Without"} BAG</li>}
								{product.has_tin !== null && <li>{product.has_tin ? "With" : "Without"} TIN</li>}
								{product.has_case !== null && <li>{product.has_case ? "With" : "Without"} CASE</li>}
							</ul>
						</div>
						{localStorage.getItem(`sell_now_price_limit_exceed_${productId}`) ? (
							<SellerPriceLimitExceedComponent productId={productId} />
						) : selectedBid ? (
							<>
								<div className="discount mt-3">
									<input
										className="discount-code form-condivol"
										type="text"
										placeholder="DISCOUNT CODE"
										value={discountCode ?? ""}
										onChange={(e) => setDiscountCode(e.target.value)}
									/>
								</div>
								<div className="invoiceTable">
									<table className="table table-borderless">
										<tbody>
											{fees && (
												<>
													<tr>
														<td>{fees.details.listing.label}</td>
														<td>-${numberToPriceString(fees.details.listing.value)}</td>
													</tr>
													<tr>
														<td>{fees.details.processing.label}</td>
														<td>-${numberToPriceString(fees.details.processing.value)}</td>
													</tr>
													<tr>
														<td>{fees.details.transaction.label}</td>
														<td>-${numberToPriceString(fees.details.transaction.value)}</td>
													</tr>
													<tr>
														<td>{fees.details.shipping.label}</td>
														<td>-${numberToPriceString(fees.details.shipping.value)}</td>
													</tr>
												</>
											)}
											<tr>
												<td>DISCOUNT APPLIED</td>
												<td>${numberToPriceString(discountPrice?.result.value)}</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<th>TOTAL PAYOUT</th>
												<th>
													$
													{numberToPriceString(
														(selectedBid?.price ?? 0) -
															(fees?.sum ?? 0) +
															(isNaN(parseFloat(discountPrice?.result.value)) ? 0 : parseFloat(discountPrice?.result.value))
													)}
												</th>
											</tr>
										</tfoot>
									</table>
								</div>
								<div className="checkGroup">
									<SellerCertifyBoxComponent value={mustCheck} setValue={setMustCheck} />
								</div>
								<div className="btnWrapper ">
									<button className="listBtn" onClick={submitSellNow}>
										SELL NOW
									</button>
								</div>
							</>
						) : (
							<h1 className="text-center text-white mt-6">Bidder does not exist</h1>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(SellerAcceptBidPage);
