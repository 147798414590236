import React from "react";
import Loader from "react-loader-spinner";

const NormalLoading = () => (
  <div style={{ marginTop: "30px", textAlign: "center", width: "100%" }}>
    <Loader type="Puff" color="#04d39f" height="100" width="100" />
  </div>
);

export default NormalLoading;
