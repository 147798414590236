import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import {
	Col,
	Collapse,
	Container,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	Navbar,
	NavbarToggler,
	NavItem,
	Row,
	UncontrolledDropdown,
} from "reactstrap";
import logo from "../../assets/images/eliqx-logo-all-grey.svg";
import { getProfile } from "../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { logout } from "../../redux/auth/auth.action";
import { getLang } from "../../helpers/languageHelper";
import { ToastContainer } from "react-toastify";

const AdminHeader = ({ location, history, logout, profile }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const checkClass = (val) => {
		return location.pathname.search(val) > -1;
	};
	const logoutClick = () => {
		logout().then(() => history.replace("/login"));
	};

	return (
		<div className="admin-menu">
			<ToastContainer autoClose={7000} draggable={false} />
			<Container>
				<Row className="align-items-center">
					<Col md={12}>
						<div className="d-flex align-items-center positive-reletive">
							<Link to="/">
								<img className="img-fluid logo" src={logo} alt="logo" />
							</Link>
							<Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} className="profile-dropdown ml-auto">
								<DropdownToggle caret className="btn-white">
									<div className="d-none d-sm-block">
										<h6 className="mb-0">{profile.name}</h6>
										<span className="text-dark">{profile.email}</span>
									</div>
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem className="nav-link" tag={Link} to="#">
										<i className="fa fa-cog"></i>
										{getLang("account_settings")}
									</DropdownItem>
									<DropdownItem className="nav-link" tag={Link} to="/">
										<i className="fa fa-home"></i>
										{getLang("back_to_home")}
									</DropdownItem>
									<DropdownItem onClick={logoutClick} className="nav-link" tag={Link} to="/">
										<i className="fa fa-sign-out"></i>
										{getLang("log_out")}
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</div>
						<Navbar light expand="md" className="bg-white text-left">
							<NavbarToggler onClick={() => setIsOpen(!isOpen)} />
							<Collapse isOpen={isOpen} navbar>
								<Nav navbar>
									<NavItem className={`"dashboard" ${checkClass("dashboard") ? "active" : ""}`}>
										<Link to="/admin/dashboard" className="nav-link">
											<i className="fa fa-line-chart"></i>
											{getLang("dashboard")}
										</Link>
									</NavItem>
									<UncontrolledDropdown nav inNavbar className={`"product" ${checkClass("product") ? "active" : ""}`}>
										<DropdownToggle nav caret>
											<i className="fa fa-shopping-cart"></i>
											{getLang("products")}
										</DropdownToggle>
										<DropdownMenu>
											<DropdownItem className="nav-link" tag={Link} to="/admin/products">
												<i className="fa fa-shopping-cart"></i>
												{getLang("products")}
											</DropdownItem>
											<DropdownItem className="nav-link" tag={Link} to="/admin/product/add">
												<i className="fa fa-cart-arrow-down"></i>
												{getLang("add-product")}
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
									<NavItem className={`"user" ${checkClass("user") ? "active" : ""}`}>
										<Link to="/admin/users" className="nav-link">
											<i className="fa fa-users"></i>
											{getLang("users")}
										</Link>
									</NavItem>
									<NavItem className={`"order" ${checkClass("order") ? "active" : ""}`}>
										<Link to="/admin/orders" className="nav-link">
											<i className="fa fa-file"></i>
											{getLang("orders")}
										</Link>
									</NavItem>
									<NavItem className={`"bid" ${checkClass("bid") ? "active" : ""}`}>
										<Link to="/admin/bids" className="nav-link">
											<i className="fa fa-file"></i>
											{getLang("bids")}
										</Link>
									</NavItem>
									<NavItem className={`"discount_code" ${checkClass("discount_code") ? "active" : ""}`}>
										<Link to="/admin/discount_codes" className="nav-link">
											<i className="fa fa-file"></i>Discount codes
										</Link>
									</NavItem>
									<NavItem className={`"site-setting" ${checkClass("site-setting") ? "active" : ""}`}>
										<Link to="/admin/site-settings" className="nav-link">
											<i className="fa fa-cog"></i>Settings
										</Link>
									</NavItem>
									<NavItem className={`"address" ${checkClass("address") ? "active" : ""}`}>
										<Link to="/admin/addresses" className="nav-link">
											<i className="fa fa-address-book"></i>Addresses
										</Link>
									</NavItem>
								</Nav>
							</Collapse>
						</Navbar>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	profile: getProfile,
});

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminHeader));
