import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { convertDateTimeFormat, numberToPriceString } from "../../../helpers/commonHelper";
import "./product-history-graph.styles.scss";
import { createStructuredSelector } from "reselect";
import { getIsActivedSeller } from "../../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { shopGetSystemSettings } from "../../../services/ApiService";

const ProductHistoryGraph = ({ isSeller, chartData, selectedLabel, setSelectedLabel, bids, orders, listings, product }) => {
	const isIn = useRef();
	const hasListing = product?.my_products?.length > 0 && product.my_products.filter((x) => x.status === "APPROVED").length > 0;
	const [sAmt, setSAmt] = useState(0);

	useEffect(() => {
		isIn.current = true;

		shopGetSystemSettings("public=1")
			.then((res) => {
				if (isIn.current) setSAmt(res.seller_buy_bid_save_fee ?? 0);
			})
			.catch(() => {});

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="product-sale-history">
			<div className="container-fluid">
				<div className="change-graph-info">
					<div className="graph-sort">
						<Link className={`graph-sort-item ${selectedLabel === "bids" ? "active" : ""}`} to="#" onClick={() => setSelectedLabel("bids")} replace={true}>
							offers
						</Link>
						<Link className={`graph-sort-item ${selectedLabel === "orders" ? "active" : ""}`} to="#" onClick={() => setSelectedLabel("orders")} replace={true}>
							last sold
						</Link>
						<Link
							className={`graph-sort-item ${selectedLabel === "listings" ? "active" : ""}`}
							to="#"
							onClick={() => setSelectedLabel("listings")}
							replace={true}
						>
							listings
						</Link>
					</div>
				</div>
				<div className="graph">
					{selectedLabel === "orders" && orders && (
						<div className="graph-data-last-sold">
							<p>There have been {orders.length} sale(s) of this bottle</p>
							<table className="product-table">
								<thead className="product-table-title">
									<tr>
										<th>date</th>
										<th>time</th>
										<th>last sold</th>
									</tr>
								</thead>
								<tbody className="product-table-data">
									{orders.slice(0, 5).map((item) => (
										<tr key={item.id}>
											<td>{convertDateTimeFormat(item?.created_at)?.date ?? "---"}</td>
											<td>{convertDateTimeFormat(item?.created_at)?.time ?? "---"}</td>
											<td>${numberToPriceString(item?.price)}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
					{selectedLabel === "listings" && listings && (
						<div className="graph-data-view-sells">
							<p>There are {listings.length} of listing(s) of this bottle</p>
							<table className="product-table">
								<thead className="product-table-title">
									<tr>
										<th>date</th>
										<th>time</th>
										<th>listing price</th>
									</tr>
								</thead>
								<tbody className="product-table-data">
									{listings.slice(0, 5).map((item) => (
										<tr key={item.id}>
											<td>{convertDateTimeFormat(item?.created_at)?.date ?? "---"}</td>
											<td>{convertDateTimeFormat(item?.created_at)?.time ?? "---"}</td>
											<td>${numberToPriceString(item?.price)}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
					{selectedLabel === "bids" && bids && (
						<div className="graph-data-view-bids">
							<p>There are {bids.length} offer(s) on this bottle</p>
							<table className="product-table">
								<thead className="product-table-title">
									<tr>
										<th>date</th>
										<th>time</th>
										<th>current offers</th>
										{isSeller && <th>EARN {sAmt}% MORE</th>}
									</tr>
								</thead>
								<tbody className="product-table-data">
									{bids.slice(0, 5).map((item, k) => (
										<tr key={item.id}>
											<td>{convertDateTimeFormat(item?.created_at)?.date ?? "---"}</td>
											<td>{convertDateTimeFormat(item?.created_at)?.time ?? "---"}</td>
											<td>${numberToPriceString(item?.price)}</td>
											{isSeller && (
												<td>
													{k <= 0 ? (
														<Link to={`/seller/${hasListing ? "accept-bid" : "list-sell"}/${product.id}?bid=${item.id}`}>SELL MY BOTTLE</Link>
													) : (
														<span>N/A</span>
													)}
												</td>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	isSeller: getIsActivedSeller,
});

export default connect(mapStateToProps)(ProductHistoryGraph);
