import React, { useRef, useState } from "react";
import Loader from "react-loader-spinner";
import * as AuthAction from "../../redux/auth/auth.action";
import googleLoginButtonImage from "../../assets/images/google-login.png";
import { connect } from "react-redux";
import { authPrepareGoogleSignIn, authSignWithToken } from "../../services/ApiService";
import { toast } from "react-toastify";
import { extractErrors } from "../../helpers/commonHelper";
import { getLang } from "../../helpers/languageHelper";

const PhoneNumberVerifyCodeBox = ({ loading, submit }) => {
  const [code, setCode] = useState("");
  const inputHandle = (event) => {
    setCode(event.target.value);
  };

  if (loading) {
    return <Loader type="Puff" color="#04d39f" height="100" width="100" style={{ marginTop: "30px" }} />;
  }
  return (
    <div className="row mt-5 mb-5" style={{ justifyContent: "center" }}>
      <input type="text" id="code" style={{ width: "120px" }} placeholder={getLang("your_code")} value={code} onChange={inputHandle} />
      <button className="btn btn-primary" style={{ width: "100px", marginTop: 0, marginLeft: 20 }} onClick={() => submit(code)}>
        {getLang("verify")}
      </button>
    </div>
  );
};

const GoogleSignButton = ({ signWithToken }) => {
  const [googleLoading, setGoogleLoading] = useState(false);
  const [showCode, setShowCode] = useState(false);
  let currentForm = useRef();

  const signNow = (code) => {
    setGoogleLoading(true);
    signWithToken(currentForm.current, code).catch((err) => {
      toast.error(getLang(extractErrors(err)[0]));
      setGoogleLoading(false);
    });
  };

  const signInWithGoogle = async () => {
    setGoogleLoading(true);
    try {
      const { token, url } = await authPrepareGoogleSignIn();
      currentForm.current = token;
      const signwithtoken = (token) => {
        authSignWithToken(token)
          .then((res) => {
            if (res.status_code === 1) {
              setGoogleLoading(false);
              setShowCode(true);
            } else {
              signWithToken(token).catch((err) => {
                setGoogleLoading(false);
                toast.error(getLang(extractErrors(err)[0]));
              });
            }
          })
          .catch((err) => {
            setGoogleLoading(false);
            toast.error(getLang(extractErrors(err)[0]));
          });
      };
      const googlewindow = window.open(url, "MsgWindow", "width=500,height=775");
      const timer = setInterval(() => {
        if (googlewindow.closed) {
          clearInterval(timer);
          signwithtoken(token);
        }
      });
    } catch (err) {
      setGoogleLoading(false);
      toast.error(extractErrors(err)[0]);
    }
  };

  if (showCode) {
    return <PhoneNumberVerifyCodeBox loading={googleLoading} submit={signNow} />;
  }
  return (
    <div className="google-sign-button">
      {googleLoading ? (
        <Loader type="Puff" color="#04d39f" height="100" width="100" style={{ marginTop: "30px" }} />
      ) : (
        <button className="google-login-button" onClick={signInWithGoogle}>
          <img src={googleLoginButtonImage} alt="google-login" className="google-login" />
        </button>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signWithToken: (token, code = null) => dispatch(AuthAction.signWithToken(token, code)),
});

export default connect(null, mapDispatchToProps)(GoogleSignButton);
