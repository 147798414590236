import React, { useCallback, useLayoutEffect, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import { extractErrors } from "../../../helpers/commonHelper";
import { getToken } from "../../../redux/auth/auth.selector";
import { buyerGetBillingCards } from "../../../services/ApiService";
import BuyerSaveStripeCard from "../buyer-save-stripe-card/buyer-save-stripe-card.component";
import "./buyer-save-card-form.styles.scss";

const BuyerSaveCardForm = ({ token, selectedCard, setSelectedCard }) => {
	const isIn = useRef();
	const [savedCards, setSavedCards] = useState([]);
	const [openPayment, setOpenPayment] = useState(false);
	const [showCardModal, setShowCardModal] = useState(false);

	const cardSubmitCallback = (res) => {
		setSelectedCard(res);
		setSavedCards([...savedCards, res]);
		setShowCardModal(false);
		setOpenPayment(false);
	};

	const selectCard = (item) => {
		setSelectedCard(item);
		setOpenPayment(false);
	};

	const loadSavedBillingCards = useCallback(() => {
		setSavedCards(null);
		buyerGetBillingCards(token)
			.then((res) => {
				if (!isIn.current) return;
				setSavedCards(res);
			})
			.catch((err) => {
				if (!isIn.current) return;
				toast.error(extractErrors(err)[0]);
			});
	}, [token]);

	useLayoutEffect(() => {
		loadSavedBillingCards();
	}, [loadSavedBillingCards]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="buyer-save-card-form">
			{savedCards && (
				<div className="paymentMethod">
					{savedCards.length > 0 && (
						<div className={`choosePaymentMethod collapse-content ${openPayment ? "show" : ""}`}>
							<h4>Choose Payment Method </h4>
							{savedCards.map((x) => (
								<p key={x.id} className={selectedCard?.id === x.id ? "selected_item" : ""} onClick={selectCard.bind(this, x)}>
									{x.first_name} {x.last_name} - {x.card_type} (************{x.last4})
								</p>
							))}
						</div>
					)}
					<button className="collapsbtn" onClick={() => setOpenPayment(!openPayment)}>
						{selectedCard && !openPayment
							? `${selectedCard.first_name} ${selectedCard.last_name} - ${selectedCard.card_type} (************${selectedCard.last4})`
							: "Add Payment Method"}
					</button>
					<div className={`addPaymentMethod collapse-content ${openPayment ? "show" : ""}`}>
						<button className="add-new-card" onClick={() => setShowCardModal(true)}>
							Add New Card
						</button>
						{showCardModal && <BuyerSaveStripeCard callback={cardSubmitCallback} closeForm={() => setShowCardModal(false)} />}
					</div>
					<span className="icon-cc-down-arrow" onClick={() => setOpenPayment(!openPayment)}></span>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(BuyerSaveCardForm);
