import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import "./seller-listing-confirm.styles.scss";
import logo from "../../../assets/images/order-confirm-grey-logo.svg";
import { Link, withRouter } from "react-router-dom";

const SellerListingConfirmPage = ({ history, product }) => {
	const [listing, setListing] = useState();

	const removeStorage = useCallback(() => {
		localStorage.removeItem(`listing_confirmed_${product.id}`);
	}, [product]);

	const goToDashboard = () => {
		history.push("/seller/profile-portfolio");
	};

	useLayoutEffect(() => {
		const item = localStorage.getItem(`listing_confirmed_${product.id}`);
		setListing(JSON.parse(item));
	}, [product]);

	useEffect(() => {
		window.document.onclick = () => {
			removeStorage();
		};
	}, [removeStorage]);

	return (
		<div className="seller-listing-confirm-page">
			<div className="container ">
				<div className="orderLogoContainer">
					<img className="orderLogo" src={logo} alt="grey-logo" />
				</div>
				<div className="orderDetailContainer">
					<h1>listing confirmed</h1>
					<Link className="orderNumber" to="/seller/profile-portfolio" onClick={removeStorage}>
						#{listing?.id}
					</Link>
					<Link className="orderNumber" to="/seller/profile-portfolio" onClick={removeStorage}>
						expires {listing?.expired_at}
					</Link>
					<p className="orderThanks">Thank you for listing your bottle!</p>
					<p className="orderInfo">
						Two things can happen, a buyer will come along and accept your list price or you can browse bids on your bottle and click the Sell Now button to
						complete the sale.
						<br />
						<br />
						If your list price is accepted by a buyer you will be notified with instructions.
					</p>
					<Link className="orderFaq" to="/seller/faq" onClick={removeStorage}>
						FAQ
					</Link>
					<button className="buyerDashboard" type="button" name="button" onClick={goToDashboard}>
						seller dashboard
					</button>
				</div>
			</div>
		</div>
	);
};

export default withRouter(SellerListingConfirmPage);
