import { createSelector } from "reselect";

const selectAuth = (state) => state.auth;

export const getToken = createSelector([selectAuth], (auth) => auth.token);

export const getProfile = createSelector([selectAuth], (auth) => {
	const { token, ...profile } = auth;
	return profile;
});

export const getRole = createSelector([selectAuth], (auth) => auth.role);

export const getIsActivedSeller = createSelector([selectAuth], (auth) => auth.isActivedSeller);

export const getSellerProfile = createSelector([selectAuth], (auth) => auth.seller_profile);
