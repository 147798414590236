import React from "react";
import { useHistory } from "react-router-dom";
import "./buyer.styles.scss";

const BuyerPriceLimitExceedComponent = ({ productId }) => {
	const history = useHistory();
	const onClickLooking = () => {
		localStorage.removeItem(`buy_now_price_limit_exceed_${productId}`);
		history.goBack();
	};

	return (
		<div className="buyer-price-limit-exceed">
			<p className="message">Thank you for your interest in this amazing bottle</p>
			<p className="message font-bold">
				Due to the high value of this listing. An eliqX team member will contact you to arrange for an alternative method of payment and transfer ownership.
			</p>
			<p className="message">
				Please allow for 24-48 hours for a response. We will attempt to call or email.
				<br />
				Alternatively we can be reached
				<br />
				info@eliqx.io
			</p>
			<button className="keep-looking" onClick={onClickLooking.bind(this)}>
				Keep Looking
			</button>
		</div>
	);
};

export default BuyerPriceLimitExceedComponent;
