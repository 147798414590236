import { SET_HEADER_MAIN_MENU_SHOW, SET_HEADER_PROFILE_MENU_SHOW, TOGGLE_HEADER_MAIN_MENU_SHOW, TOGGLE_HEADER_PROFILE_MENU_SHOW } from "./setting.action";

const INITIAL_STATE = {
  headerProfileMenuShow: false,
  headerMainMenuShow: false,
};

export const settingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_HEADER_PROFILE_MENU_SHOW:
      return {
        ...state,
        headerProfileMenuShow: !state.headerProfileMenuShow,
      };

    case SET_HEADER_PROFILE_MENU_SHOW:
      return {
        ...state,
        headerProfileMenuShow: action.val,
      };

    case TOGGLE_HEADER_MAIN_MENU_SHOW:
      return {
        ...state,
        headerMainMenuShow: !state.headerMainMenuShow,
      };

    case SET_HEADER_MAIN_MENU_SHOW:
      return {
        ...state,
        headerMainMenuShow: action.val,
      };

    default:
      return state;
  }
};

export default settingReducer;
