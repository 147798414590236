import React, { useState } from "react";
import Slider from "react-slick";
import { Row } from "reactstrap";
import TwoRadioButtonLine from "../ui-kits/two-radio-button-line.component";
import "./shop.styles.scss";
import { getLang } from "../../helpers/languageHelper";

const settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
};

const product_slider = {
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 1,
};

const ProductMainDetailBox = ({ product }) => {
	const [selectedImgId, setSelectedImgId] = useState(0);

	return (
		<Row className="product-main-detail-box">
			<div className="product-top-left col-xl-5 col-md-6">
				<div className="product-top-left-inner">
					<div className="ciyashop-product-images">
						<div className="ciyashop-product-images-wrapper ciyashop-gallery-style-default ciyashop-gallery-thumb_position-bottom ciyashop-gallery-thumb_vh-horizontal">
							{product.images && product.images.length > 0 && (
								<div className="ciyashop-product-gallery ciyashop-product-gallery--with-images slick-carousel">
									<Slider {...settings} className="ciyashop-product-gallery__wrapper popup-gallery">
										<div className="ciyashop-product-gallery__image">
											<img src={product.images[selectedImgId] ?? ""} className="img-fluid" alt="slider1" />
										</div>
									</Slider>
									<div className="ciyashop-product-thumbnails">
										<Slider {...product_slider} className="ciyashop-product-thumbnails__wrapper">
											{product.images.map((image, index) => (
												<div key={index}>
													<div className="ciyashop-product-thumbnail__image">
														<div onClick={() => setSelectedImgId(index)}>
															<img src={image} className="img-fluid" alt={index} />
														</div>
													</div>
												</div>
											))}
										</Slider>
									</div>
								</div>
							)}
							<div className="clearfix" />
						</div>
					</div>
				</div>
			</div>
			<div className="product-top-right col-xl-7 col-md-6">
				<div className="product-top-right-inner text-left">
					<div className="summary entry-summary">
						<h3 className="product_id">EID #{product.id}</h3>
						<h1 className="product_title">{product.name}</h1>
						<h2 className="bottle-size">{product.size}</h2>
						<h3 className="abv">{product.abv}%</h3>
						<div className="product-details__short-description">
							<div className="pdp-about-details-txt pdp-about-details-equit">
								<p>{product.description}</p>
							</div>
						</div>
						{product.has_box !== null && (
							<div className="small-fields">
								<TwoRadioButtonLine label_1="with Box" label_2="without Box" val={product.has_box} />
							</div>
						)}
						{product.has_tag !== null && (
							<div className="small-fields">
								<TwoRadioButtonLine label_1="with Tag" label_2="without Tag" val={product.has_tag} />
							</div>
						)}
						{product.has_bag !== null && (
							<div className="small-fields">
								<TwoRadioButtonLine label_1="with Bag" label_2="without Bag" val={product.has_bag} />
							</div>
						)}
						{product.has_tin !== null && (
							<div className="small-fields">
								<TwoRadioButtonLine label_1="with Tin" label_2="without Tin" val={product.has_tin} />
							</div>
						)}
						{product.has_case !== null && (
							<div className="small-fields">
								<TwoRadioButtonLine label_1="with Case" label_2="without Case" val={product.has_case} />
							</div>
						)}
						<table className="detail table table-bordered table-stripe">
							<tbody>
								{product.batch && (
									<tr>
										<th>{getLang("batch")}</th>
										<td>
											<h3 className="batch">{product.batch}</h3>
										</td>
									</tr>
								)}
								{product.lot && (
									<tr>
										<th>{getLang("lot")}</th>
										<td>
											<h3 className="lot">{product.lot}</h3>
										</td>
									</tr>
								)}
								{product.distillery && (
									<tr>
										<th>{getLang("distillery")}</th>
										<td>
											<h3 className="distillery">{product.distillery}</h3>
										</td>
									</tr>
								)}
								{product.company && (
									<tr>
										<th>{getLang("company")}</th>
										<td>
											<p className="company">{product.company}</p>
										</td>
									</tr>
								)}
								{product.category && (
									<tr>
										<th>{getLang("category")}</th>
										<td>
											<p className="category">{product.category}</p>
										</td>
									</tr>
								)}
								{product.sub_category_1 && (
									<tr>
										<th>Sub category</th>
										<td>
											<p className="sub_category_1">{product.sub_category_1}</p>
										</td>
									</tr>
								)}
								{product.classification && (
									<tr>
										<th>{getLang("classification")}</th>
										<td>
											<p className="classification">{product.classification}</p>
										</td>
									</tr>
								)}
								{product.brand && (
									<tr>
										<th>{getLang("brand")}</th>
										<td>
											<p className="brand">{product.brand}</p>
										</td>
									</tr>
								)}
								{product.collection && (
									<tr>
										<th>{getLang("collection")}</th>
										<td>
											<p className="collection">{product.collection}</p>
										</td>
									</tr>
								)}
								{product.specification && (
									<tr>
										<th>{getLang("specification")}</th>
										<td>
											<p className="specification">{product.specification}</p>
										</td>
									</tr>
								)}
								{product.year_release && (
									<tr>
										<th>Release</th>
										<td>
											<p className="year_release">{product.year_release}</p>
										</td>
									</tr>
								)}
								{product.aged && (
									<tr>
										<th>{getLang("aged")}</th>
										<td>
											<p className="aged">{product.aged}</p>
										</td>
									</tr>
								)}
								{product.sku && (
									<tr>
										<th>{getLang("sku")}</th>
										<td>
											<p className="sku">{product.sku}</p>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</Row>
	);
};

export default ProductMainDetailBox;
