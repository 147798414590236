import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import NormalLoading from "../../components/ui-kits/normal-loading.component";

import { extractErrors } from "../../helpers/commonHelper";
import { getLang } from "../../helpers/languageHelper";
import { getToken } from "../../redux/auth/auth.selector";
import { authVerifyEmailLink } from "../../services/ApiService";
import "./email-verify-page.styles.scss";

const EmailVerifyPage = ({ token, match, history }) => {
	const isIn = useRef();
	const link = match.params.link;

	const attemptVerify = useCallback(() => {
		if (!token) {
			toast.error("You are not sign in");
			return;
		}
		authVerifyEmailLink(token, link)
			.then((res) => {
				toast.success(res.success);
				history.replace("/");
			})
			.catch((err) => {
				toast.error(getLang(extractErrors(err)[0]));
			});
	}, [token, link, history]);

	useLayoutEffect(() => {
		attemptVerify();
	}, [attemptVerify]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="email-verify-page">
			<div className="loading">
				<NormalLoading />
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(withRouter(EmailVerifyPage));
