import React, { useCallback, useEffect, useRef, useState } from "react";
import AppleLogin from "react-apple-login";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { APPLE_API_KEY } from "../../env";
import { extractErrors } from "../../helpers/commonHelper";
import { getLang } from "../../helpers/languageHelper";
import { authPrepareAppleSignIn } from "../../services/ApiService";

const AppleSignButton = () => {
  const isIn = useRef();
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState();
  const [redirectUrl, setRedirectUrl] = useState();

  const loadData = useCallback(() => {
    setLoading(true);
    authPrepareAppleSignIn(APPLE_API_KEY)
      .then((res) => {
        if (!isIn.current) return;
        setLoading(false);
        setClientId(res.client_id);
        setRedirectUrl(res.redirect_url);
      })
      .catch((err) => {
        if (!isIn.current) return;
        toast.error(getLang(extractErrors(err)[0]));
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    isIn.current = true;
    loadData();

    return () => {
      isIn.current = false;
    };
  }, [loadData]);

  if (loading) {
    return <Loader type="Puff" color="#04d39f" height="100" width="100" style={{ marginTop: "30px" }} />;
  }
  if (!clientId || !redirectUrl) {
    return <div>{getLang("not_support")}</div>;
  }
  return (
    <div style={{ margin: "20px 0 10px 0", cursor: "pointer" }}>
      <AppleLogin
        clientId={clientId}
        redirectURI={redirectUrl}
        responseType={"code id_token"}
        responseMode={"form_post"}
        scope={"name email"}
        state={APPLE_API_KEY}
        usePopup={false}
        designProp={{
          height: 50,
          width: 200,
          color: "black",
          border: false,
          type: "sign-in",
          border_radius: 15,
          scale: 1,
          locale: "en_US",
        }}
      />
    </div>
  );
};

export default AppleSignButton;
