import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import { createStructuredSelector } from "reselect";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";
import { extractErrors, strToPhoneNumberFormat, strToPhoneNumberString } from "../../../helpers/commonHelper";
import { getToken } from "../../../redux/auth/auth.selector";
import { buyerGetShippingInfos, buyerRemoveShippingInfo, buyerSaveShippingInfo, sellerCreateOnCallPickup } from "../../../services/ApiService";

import "./admin-address.styles.scss";

const initialShippingForm = {
	first_name: "",
	last_name: "",
	email: "",
	phone_number: "",
	address_1: "",
	address_2: "",
	city: "",
	zip_code: "",
};

const AddNewAddress = ({ visible, setVisible, isPickup, token, loadData }) => {
	const [form, setForm] = useState(initialShippingForm);

	const inputChange = (event) => {
		let { id, value } = event.target;
		if (id === "phone_number") value = strToPhoneNumberString(value);
		setForm({ ...form, [id]: value });
	};
	const submit = () => {
		buyerSaveShippingInfo(token, { ...form, is_pickup: isPickup })
			.then((res) => {
				setVisible(false);
				loadData();
				toast.success("Created successfully");
			})
			.catch((err) => {
				setVisible(true);
				toast.error(extractErrors(err)[0]);
			});
	};

	return (
		<div className="add-new-address">
			<Modal isOpen={visible} className="modal-login modal-dialog-centered auth-modal" onClosed={() => setForm(initialShippingForm)}>
				<ModalHeader>
					<div className="mb-0" style={{ color: "white" }}>
						Add New {isPickup ? "Pickup" : "Shipping"} Address
					</div>
				</ModalHeader>
				<ModalBody>
					<div className="row">
						<div className="form-group">
							<label htmlFor="first_name">First Name</label>
							<input type="text" className="form-control" id="first_name" value={form.first_name} onChange={inputChange.bind(this)} autoComplete="off" />
						</div>
						<div className="form-group">
							<label htmlFor="last_name">Last Name</label>
							<input type="text" className="form-control" id="last_name" value={form.last_name} onChange={inputChange.bind(this)} autoComplete="off" />
						</div>
					</div>
					<div className="form-group">
						<label htmlFor="phone_number">Phone Number</label>
						<input
							type="text"
							className="form-control"
							id="phone_number"
							value={strToPhoneNumberFormat(form.phone_number)}
							onChange={inputChange.bind(this)}
							autoComplete="off"
						/>
					</div>
					<div className="form-group">
						<label htmlFor="email">Email</label>
						<input type="text" className="form-control" id="email" value={form.email} onChange={inputChange.bind(this)} autoComplete="off" />
					</div>
					<div className="form-group">
						<label htmlFor="address_1">Address 1</label>
						<input type="text" className="form-control" id="address_1" value={form.address_1} onChange={inputChange.bind(this)} autoComplete="off" />
					</div>
					<div className="form-group">
						<label htmlFor="address_2">Address 2</label>
						<input type="text" className="form-control" id="address_2" value={form.address_2} onChange={inputChange.bind(this)} autoComplete="off" />
					</div>
					<div className="form-group">
						<label htmlFor="state">State</label>
						<input type="text" className="form-control" id="state" value="CA" disabled />
					</div>
					<div className="form-group">
						<label htmlFor="city">City</label>
						<input type="text" className="form-control" id="city" value={form.city} onChange={inputChange.bind(this)} autoComplete="off" />
					</div>
					<div className="form-group">
						<label htmlFor="zip_code">Zip code</label>
						<input type="text" className="form-control" id="zip_code" value={form.zip_code} onChange={inputChange.bind(this)} autoComplete="off" />
					</div>
					<div className="form-group">
						<label htmlFor="special_instruction">Special Instruction</label>
						<input
							type="text"
							className="form-control"
							id="special_instruction"
							value={form.special_instruction ?? ""}
							onChange={inputChange.bind(this)}
							autoComplete="off"
						/>
					</div>
					<div className="form-group">
						<button className="btn btn-success" onClick={submit}>
							Confirm
						</button>
						<button className="btn btn-warning" onClick={() => setVisible(!visible)}>
							Close
						</button>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

const AdminAddressesPage = ({ token }) => {
	const isIn = useRef();
	const [addresses, setAddresses] = useState();
	const [pickups, setPickups] = useState();
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [isPickup, setIsPickup] = useState(false);

	const removeShipping = (item) => {
		setLoading(true);
		buyerRemoveShippingInfo(token, item.id)
			.then((res) => {
				if (isIn.current) {
					toast.success("Removed successfully");
					loadData();
				}
			})
			.catch((err) => {
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	};
	const addNewClick = (pickup) => {
		setIsPickup(pickup);
		setVisible(true);
	};
	const createOnCallPickup = (item) => {
		let package_count = null;
		package_count = parseInt(prompt("Please enter package number", 1));
		if (isNaN(package_count)) return;
		setLoading(true);
		sellerCreateOnCallPickup(token, { shipping_id: item.id, package_count })
			.then((res) => {
				setLoading(false);
				toast.success("Requested successfully");
			})
			.catch((err) => {
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	};
	const loadData = useCallback(() => {
		setLoading(true);
		buyerGetShippingInfos(token)
			.then((res) => {
				if (isIn.current) {
					setLoading(false);
					setAddresses(res.filter((x) => x.is_pickup === 0));
					setPickups(res.filter((x) => x.is_pickup === 1));
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [token]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="admin-addresses-page">
			<Container>
				{loading ? (
					<div className="loading">
						<NormalLoading />
					</div>
				) : (
					<div>
						<div className="col-md-12">
							<h2>Shipping Addresses</h2>
							<button onClick={addNewClick.bind(this, false)}>Add new</button>
							<table className="table table-striped mb-0">
								<thead>
									<tr>
										<th scope="col">First Name</th>
										<th scope="col">Last Name</th>
										<th scope="col">Email</th>
										<th scope="col">Phone Number</th>
										<th scope="col">Address 1</th>
										<th scope="col">Address 2</th>
										<th scope="col">City</th>
										<th scope="col">Zip Code</th>
										<th scope="col">Special Instruction</th>
										<th scope="col">Action</th>
									</tr>
								</thead>
								<tbody>
									{addresses &&
										addresses.map((item) => (
											<tr key={item.id}>
												<td>{item.first_name}</td>
												<td>{item.last_name}</td>
												<td>{item.email}</td>
												<td>{item.phone_number}</td>
												<td>{item.address_1}</td>
												<td>{item.address_2}</td>
												<td>{item.city}</td>
												<td>{item.zip_code}</td>
												<td>{item.special_instruction ?? ""}</td>
												<td>
													<button onClick={removeShipping.bind(this, item)}>Remove</button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
						<div className="col-md-12 mt-5">
							<h2>Pickup Addresses</h2>
							<button onClick={addNewClick.bind(this, true)}>Add new</button>
							<table className="table table-striped mb-0">
								<thead>
									<tr>
										<th scope="col">First Name</th>
										<th scope="col">Last Name</th>
										<th scope="col">Email</th>
										<th scope="col">Phone Number</th>
										<th scope="col">Address 1</th>
										<th scope="col">Address 2</th>
										<th scope="col">City</th>
										<th scope="col">Zip Code</th>
										<th scope="col">Special Instruction</th>
										<th scope="col">Action</th>
										<th scope="col">Pickup</th>
									</tr>
								</thead>
								<tbody>
									{pickups &&
										pickups.map((item) => (
											<tr key={item.id}>
												<td>{item.first_name}</td>
												<td>{item.last_name}</td>
												<td>{item.email}</td>
												<td>{item.phone_number}</td>
												<td>{item.address_1}</td>
												<td>{item.address_2}</td>
												<td>{item.city}</td>
												<td>{item.zip_code}</td>
												<td>{item.special_instruction ?? ""}</td>
												<td>
													<button onClick={removeShipping.bind(this, item)}>Remove</button>
												</td>
												<td>
													<button onClick={createOnCallPickup.bind(this, item)}>Pickup Now</button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				)}
				<AddNewAddress visible={visible} setVisible={setVisible} isPickup={isPickup} token={token} loadData={loadData} />
			</Container>
		</div>
	);
};

const mapDispatchToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapDispatchToProps)(AdminAddressesPage);
