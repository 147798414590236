import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { extractErrors, numberToPriceString } from "../../../helpers/commonHelper";
import { getToken } from "../../../redux/auth/auth.selector";
import SellerProfileMenu from "./buyer-profile-menu.component";
import "./buyer-profile.styles.scss";
import { buyerGetWatchLists } from "../../../services/ApiService";
import { toast } from "react-toastify";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";

const getItemNameDiv = (product) => (
	<span>
		<u>{product.name}</u>
		<u>{product.size}</u>
		<u>{product.abv}% ABV</u>
		{product.has_box !== null && <u>{product.has_box ? "With" : "Without"} BOX</u>}
		{product.has_tag !== null && <u>{product.has_tag ? "With" : "Without"} TAG</u>}
		{product.has_bag !== null && <u>{product.has_bag ? "With" : "Without"} BAG</u>}
		{product.has_tin !== null && <u>{product.has_tin ? "With" : "Without"} TIN</u>}
		{product.has_case !== null && <u>{product.has_case ? "With" : "Without"} CASE</u>}
	</span>
);

const BuyerProfileWatchListPage = ({ token }) => {
	const isIn = useRef();
	const [loading, setLoading] = useState(false);
	const [watchList, setWatchList] = useState();

	const loadWatch = useCallback(() => {
		setLoading(true);
		buyerGetWatchLists(token)
			.then((res) => {
				if (isIn.current) {
					setWatchList(res);
					setLoading(false);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	}, [token]);

	useLayoutEffect(() => {
		loadWatch();
	}, [loadWatch]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="buyer-profile-watchlist-page">
			<div className="dashboard">
				<SellerProfileMenu />
				<div className="dashboard-main">
					<div className="watchlist">
						<div className="tabs">
							<p className="tab-item active">WATCHLIST</p>
						</div>
						{loading || !watchList ? (
							<div className="loading">
								<NormalLoading />
							</div>
						) : (
							<table className="table">
								<thead>
									<tr>
										<th>ITEM NAME</th>
										<th>LAST SOLD</th>
										<th>LISTINGS</th>
										<th>HIGH OFFER</th>
										<th></th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{watchList.map((item) => (
										<tr key={item.id}>
											<td data-th="ITEM NAME">
												<Link to={`/shop/product/${item.product?.id}`}>
													<span>{getItemNameDiv(item.product)}</span>
												</Link>
											</td>
											<td data-th="LAST SOLD">
												<Link to={`/shop/product/${item.product?.id}`}>
													<u>${numberToPriceString(item.last_sold?.price)}</u>
												</Link>
											</td>
											<td data-th="HIGH ASK">
												<Link className="orange-soda" to={`/shop/product/${item.product?.id}`}>
													<u>${numberToPriceString(item.lowest_listing?.price)}</u>
												</Link>
											</td>
											<td data-th="HIGH BID">
												<Link className="ming" to={`/shop/product/${item.product?.id}`}>
													<u>${numberToPriceString(item.highest_bid?.price)}</u>
												</Link>
											</td>
											<td data-th="">
												<span>
													<Link to={`/buyer/place-bid/${item.product?.id}`}>MAKE OFFER</Link>
												</span>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(BuyerProfileWatchListPage);
