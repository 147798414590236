import React, { useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import * as ApiService from "../../services/ApiService";
import { getToken } from "../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link } from "react-router-dom";
import { getLang } from "../../helpers/languageHelper";
import { numberToPriceString } from "../../helpers/commonHelper";
import NormalLoading from "../ui-kits/normal-loading.component";

const BuyerOrders = ({ token }) => {
	const isIn = useRef();
	const [orders, setOrders] = useState();
	const [loading, setLoading] = useState(false);

	const loadOrders = () => {
		setLoading(true);
		ApiService.buyerGetOrders(token)
			.then((res) => {
				if (isIn.current) {
					setLoading(false);
					setOrders(res.data);
				}
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(getLang(err.error ?? "Connection failed"));
				}
			});
	};
	useState(() => {
		loadOrders();
	}, [loadOrders]);

	useState(() => {
		isIn.current = true;
		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<Col lg={9} className="mt-4 mt-lg-0">
			<Row>
				{loading ? (
					<div className="loading">
						<NormalLoading />
					</div>
				) : (
					<Col lg={12}>
						<div className="table-responsive">
							<table className="table orderhistory-table mb-0">
								<thead className="thead-light">
									<tr>
										<th scope="col">{getLang("orders")}</th>
										<th scope="col">{getLang("date")}</th>
										<th scope="col">{getLang("status")}</th>
										<th scope="col">{getLang("bid_price")}</th>
										<th scope="col">{getLang("bid_quantity")}</th>
										<th scope="col">{getLang("product_name")}</th>
										<th scope="col">{getLang("seller_name")}</th>
									</tr>
								</thead>
								{orders && (
									<tbody>
										{orders.map((order) => (
											<tr key={order.id}>
												<td>{order.id}</td>
												<td>{order.created_at}</td>
												<td>{order.status}</td>
												<td>${numberToPriceString(order.bid_price)}</td>
												<td>{order.bid_quantity}</td>
												<td>
													<Link to={`/shop/product-detail/${order.product.id}`}>{order.product.name}</Link>
												</td>
												<td>{order.seller.name}</td>
											</tr>
										))}
									</tbody>
								)}
							</table>
						</div>
					</Col>
				)}
			</Row>
		</Col>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(BuyerOrders);
