import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Container } from "reactstrap";
import { createStructuredSelector } from "reselect";
import NormalLoading from "../../components/ui-kits/normal-loading.component";
import { extractErrors, numberToPriceString } from "../../helpers/commonHelper";
import { getLang } from "../../helpers/languageHelper";
import { getToken } from "../../redux/auth/auth.selector";
import { adminGetBuyerBids } from "../../services/ApiService";

import "./admin.styles.scss";

const AdminBidsPage = ({ token }) => {
	const isIn = useRef();
	const [bids, setBids] = useState();
	const [loading, setLoading] = useState(false);
	const [sortDir, setSortDir] = useState({ status: false, date: true, bid_length: false, price: false });

	const sortBidsBy = (key) => {
		const temp = [...bids];
		const dir = sortDir[key];
		setSortDir({ ...sortDir, [key]: !dir });
		temp.sort((a, b) => (a[key] < b[key] ? (dir ? -1 : 1) : dir ? 1 : -1));
		setBids(temp);
	};
	const loadData = useCallback(() => {
		setLoading(true);
		adminGetBuyerBids(token)
			.then((res) => {
				if (!isIn.current) return;
				setLoading(false);
				setBids(res);
			})
			.catch((err) => {
				if (!isIn.current) return;
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	}, [token]);

	useEffect(() => {
		isIn.current = true;
		loadData();

		return () => {
			isIn.current = false;
		};
	}, [loadData]);

	return (
		<div className="admin-bids-page">
			<Container>
				<h3 className="subtitle">{getLang("bids_list")}</h3>
				{loading || !bids ? (
					<div className="loading">
						<NormalLoading />
					</div>
				) : (
					<table className="table table-striped mb-0">
						<thead>
							<tr>
								<th scope="col">{getLang("product_name")}</th>
								<th scope="col">{getLang("bidder_name")}</th>
								<th scope="col">{getLang("bidder_email")}</th>
								<th scope="col" onClick={sortBidsBy.bind(this, "price")}>
									{getLang("bidder_price")}
								</th>
								<th scope="col" onClick={sortBidsBy.bind(this, "bid_length")}>
									{getLang("bid_length")}
								</th>
								<th scope="col" onClick={sortBidsBy.bind(this, "status")}>
									{getLang("bid_status")}
								</th>
								<th scope="col" onClick={sortBidsBy.bind(this, "created_at")}>
									{getLang("date")}
								</th>
							</tr>
						</thead>
						<tbody>
							{bids.map((item) => (
								<tr key={item.id}>
									<td>{item.product.name}</td>
									<td>{item.bidder.name}</td>
									<td>{item.bidder.email}</td>
									<td>{numberToPriceString(item.price)}</td>
									<td>{item.bid_length} days</td>
									<td>{item.status}</td>
									<td>{item.created_at}</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</Container>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(AdminBidsPage);
