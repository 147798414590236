import React, { useCallback, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./buyer-multi-bid-confirm-page.styles.scss";
import logo from "../../../assets/images/eliqx-logo-all-grey.svg";
import { extractErrors, numberToPriceString } from "../../../helpers/commonHelper";
import BuyerBidConfirmedPage from "./buyer-bid-confirmed-page.component";
import { buyerCloneBidToProducts } from "../../../services/ApiService";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import NormalLoading from "../../../components/ui-kits/normal-loading.component";

const PackageBox = ({ item }) => {
	if (item.has_box !== null) {
		return (
			<>
				{item.has_box ? "With" : "Without"} BOX
				<br />
			</>
		);
	}
	if (item.has_tag !== null) {
		return (
			<>
				{item.has_tag ? "With" : "Without"} TAG
				<br />
			</>
		);
	}
	if (item.has_bag !== null) {
		return (
			<>
				{item.has_bag ? "With" : "Without"} BAG
				<br />
			</>
		);
	}
	if (item.has_tin !== null) {
		return (
			<>
				{item.has_tin ? "With" : "Without"} TIN
				<br />
			</>
		);
	}
	if (item.has_case !== null) {
		return (
			<>
				{item.has_case ? "With" : "Without"} CASE
				<br />
			</>
		);
	}
	return <></>;
};

const BuyerMultiBidConfirmPage = ({ token, product }) => {
	const [bid, setBid] = useState();
	const [confirmed, setConfirmed] = useState(false);
	const [selectedIds, setSelectedIds] = useState([]);
	const [loading, setLoading] = useState(false);

	const toggleSelect = useCallback(
		(item) => {
			if (selectedIds.findIndex((x) => x === item.id) > -1) {
				const temp = selectedIds.filter((x) => x !== item.id);
				setSelectedIds(temp);
			} else {
				setSelectedIds([...selectedIds, item.id]);
			}
		},
		[selectedIds]
	);
	const isSelected = useCallback(
		(id) => {
			return selectedIds.findIndex((x) => x === id) > -1;
		},
		[selectedIds]
	);
	const submitCheckedBid = useCallback(() => {
		if (selectedIds.length <= 0) {
			setConfirmed(true);
			return;
		}
		setLoading(true);
		buyerCloneBidToProducts(token, bid.id, selectedIds)
			.then((res) => {
				setLoading(false);
				setConfirmed(true);
			})
			.catch((err) => {
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	}, [selectedIds, token, bid]);

	useLayoutEffect(() => {
		const item = localStorage.getItem(`bid_confirmed_${product.id}`);
		setBid(JSON.parse(item));
	}, [product]);

	const similar_products = product?.similar_products?.filter((x) => {
		try {
			const min_price = product.seller_products.filter((x) => x.status === "APPROVED")[0].price;
			if (Math.abs(parseFloat(min_price) - parseFloat(x.min_price)) > 200.0) return false;
		} catch {}
		if (!bid) return false;
		if (x.min_price === null) return true;
		return parseFloat(x.min_price) > parseFloat(bid.price);
	});
	if (!similar_products || similar_products.length <= 0 || confirmed) {
		return <BuyerBidConfirmedPage product={product} />;
	}
	return (
		<div className="buyer-multi-bid-confirmed-page">
			{loading ? (
				<div className="loading">
					<NormalLoading />
				</div>
			) : (
				<div className="bidonmulti">
					<div className="content">
						<div className="logo">
							<img src={logo} alt="grey-logo" />
						</div>
						<div className="right-contents">
							<div className="text">
								<h2>Congratulations</h2>
								<h4>Your Bid is Live</h4>
								<p>
									There are similar items to the item you bid on. Some have original packaging others do not. Some have different ABV’s, you get the drift? By
									selecting items below, we will place the same BID on all selected products. As soon as the first bid is accepted, the others will cancel
									automatically.
								</p>
								<h6>Place same ${numberToPriceString(bid?.price)} bid on these similar products?</h6>
							</div>
							<div className="similarproduct">
								{similar_products.map((item) => (
									<div className="form-check" key={item.id}>
										<input
											className="form-check-input"
											type="checkbox"
											id={`check1_${item.id}`}
											checked={isSelected(item.id)}
											onChange={toggleSelect.bind(this, item)}
										/>
										<label className="form-check-label" htmlFor={`check1_${item.id}`}>
											{item.name} <br />
											{item.size} <br />
											{item.abv}% ABV
											<br />
											<PackageBox item={item} />
											{item.lot && (
												<>
													Lot #{item.lot} <br />
												</>
											)}
											{item.batch && (
												<>
													Batch #{item.batch} <br />
												</>
											)}
											{item.year_release && <>Released {item.year_release}</>}
										</label>
									</div>
								))}
							</div>
							<div className="buttons">
								<Link to="#" onClick={() => setConfirmed(true)} replace={true}>
									CONTINUE
								</Link>
								<Link to="#" onClick={submitCheckedBid} replace={true}>
									CONFIRM BID
								</Link>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(BuyerMultiBidConfirmPage);
