import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import "./buyer-bid-confirmed-page.styles.scss";
import grey_logo from "../../../assets/images/order-confirm-grey-logo.svg";
import { numberToPriceString } from "../../../helpers/commonHelper";

const BuyerBidConfirmedPage = ({ history, product }) => {
	const [bid, setBid] = useState();

	const removeStorage = useCallback(() => {
		localStorage.removeItem(`bid_confirmed_${product.id}`);
	}, [product]);

	const goToDashboard = () => {
		history.push("/buyer/profile-portfolio");
	};

	useLayoutEffect(() => {
		const item = localStorage.getItem(`bid_confirmed_${product.id}`);
		setBid(JSON.parse(item));
	}, [product]);

	useEffect(() => {
		window.document.onclick = () => {
			removeStorage();
		};
	}, [removeStorage]);

	const similar_products = product?.similar_products?.filter((x) => bid && x.min_price && parseFloat(x.min_price) < parseFloat(bid.price));

	return (
		<div className="buyer-bid-confirmed-page">
			<div className="container ">
				<div className="orderLogoContainer">
					<img className="orderLogo" src={grey_logo} alt="grey_logo" />
				</div>
				<div className="orderDetailContainer">
					<h1>bid confirmation</h1>
					<Link className="orderNumber" to="/buyer/profile-portfolio" onClick={removeStorage}>
						#{bid?.id}
					</Link>
					<Link className="orderNumber" to="/buyer/profile-portfolio" onClick={removeStorage}>
						Expires {bid?.expired_at}
					</Link>
					<p className="orderThanks">thank you for placing a bid!</p>
					<p className="orderInfo">
						You will be notified when and if your bid is accepted by a seller. Our licensed partners are shown a list of bids on this bottle and can accept your
						bids.
						<br />
						<br />
						Bids can be changed on your
						<Link className="orderInlineLink" to="/buyer/profile-profile" onClick={removeStorage}>
							&nbsp;dashboard
						</Link>
						.
					</p>
					<Link className="orderFaq" to="/buyer/faq" onClick={removeStorage}>
						FAQ
					</Link>
					<button className="buyerDashboard" type="button" name="button" onClick={goToDashboard}>
						buyer dashboard
					</button>
				</div>
			</div>
			<div className="container">
				{similar_products &&
					similar_products.map((item) => (
						<Link className="relative-lower-item" key={item.id} to={`/shop/product/${item.id}`} onClick={removeStorage}>
							<div>Name: {item.name}</div>
							<div>Price: ${numberToPriceString(item.min_price)}</div>
						</Link>
					))}
			</div>
		</div>
	);
};

export default withRouter(BuyerBidConfirmedPage);
