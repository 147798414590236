import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { toast } from "react-toastify";
import { adminApproveSellerProfile, adminGetUserDetail } from "../../services/ApiService";
import { extractErrors, strToPhoneNumberFormat } from "../../helpers/commonHelper";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../redux/auth/auth.selector";
import "./admin.styles.scss";
import { getLang } from "../../helpers/languageHelper";
import NormalLoading from "../../components/ui-kits/normal-loading.component";

const AdminUserDetailPage = ({ token, match }) => {
	const userId = match.params.userId;
	const isIn = useRef();
	const [user, setUser] = useState();
	const [loading, setLoading] = useState(false);

	const loadData = useCallback(() => {
		setLoading(true);
		adminGetUserDetail(token, userId)
			.then((res) => {
				if (!isIn.current) return;
				setUser(res);
				setLoading(false);
			})
			.catch((err) => {
				if (!isIn.current) return;
				setLoading(false);
				toast.error(getLang(extractErrors(err)[0]));
			});
	}, [token, userId]);

	const approveRejectSellerProfile = (status) => {
		setLoading(true);
		adminApproveSellerProfile(token, userId, { status })
			.then((res) => {
				loadData();
			})
			.catch((err) => {
				setLoading(false);
				toast.error(getLang(extractErrors(err)[0]));
			});
	};
	const toggleAddressHide = () => {
		setLoading(true);
		adminApproveSellerProfile(token, userId, { address_hide: !user.seller_profile?.address_hide })
			.then((res) => {
				loadData();
			})
			.catch((err) => {
				if (isIn.current) {
					setLoading(false);
					toast.error(extractErrors(err)[0]);
				}
			});
	};

	useEffect(() => {
		loadData();
	}, [loadData]);

	useEffect(() => {
		isIn.current = true;

		return () => {
			isIn.current = false;
		};
	}, []);

	return (
		<div className="admin-user-detail-page">
			<Container>
				<h1 className="title">{getLang("user_detail")}</h1>
				{loading ? (
					<div className="loading">
						<NormalLoading />
					</div>
				) : !user ? (
					<h1>{getLang("user_not_found")}</h1>
				) : (
					<div className="user-detail">
						<Row>
							<Col md={6}>
								<table className="table">
									<tbody>
										<tr>
											<th scope="col">Full Name</th>
											<td>{user.name}</td>
										</tr>
										<tr>
											<th scope="col">{getLang("email_address")}</th>
											<td>{user.email}</td>
										</tr>
										<tr>
											<th scope="col">{getLang("phone_number")}</th>
											<td>{strToPhoneNumberFormat(user.phone_number)}</td>
										</tr>
										<tr>
											<th scope="col">{getLang("email_verified")}</th>
											<td>{user.email_verified ? "YES" : "NO"}</td>
										</tr>
										<tr>
											<th scope="col">{getLang("role")}</th>
											<td>{user.role}</td>
										</tr>
										<tr>
											<th scope="col">{getLang("status")}</th>
											<td>{getLang(user.status)}</td>
										</tr>
										<tr>
											<th scope="col">Street Address</th>
											<td>{user.street_address}</td>
										</tr>
										<tr>
											<th scope="col">{getLang("city")}</th>
											<td>{user.city}</td>
										</tr>
										<tr>
											<th scope="col">{getLang("zip_code")}</th>
											<td>{user.zip_code}</td>
										</tr>
										{user.seller_profile && (
											<tr>
												<th scope="col">Use Admin Address</th>
												<td>
													<input type="checkbox" id="address_hide" checked={!!user.seller_profile.address_hide} onChange={toggleAddressHide.bind(this)} />
													<label htmlFor="address_hide">Use admin address</label>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</Col>
							<Col md={6}>
								{user.seller_profile && (
									<table className="table">
										<tbody>
											<tr>
												<th scope="col">{getLang("store_name")}</th>
												<td>{user.seller_profile.store_name}</td>
											</tr>
											<tr>
												<th scope="col">Lic Type</th>
												<td>{user.seller_profile.liquor_lic_type}</td>
											</tr>
											<tr>
												<th scope="col">{getLang("liquor_lic_number")}</th>
												<td>{user.seller_profile.liquor_lic_num}</td>
											</tr>
											<tr>
												<th scope="col">{getLang("sellers_permit")}</th>
												<td>{user.seller_profile.sellers_permit}</td>
											</tr>
											<tr>
												<th scope="col">{getLang("business_address")}</th>
												<td>{user.seller_profile.business_address}</td>
											</tr>
											<tr>
												<th scope="col">Zip Code</th>
												<td>{user.seller_profile.zip_code}</td>
											</tr>
											<tr>
												<th scope="col">ACH status</th>
												<td>{!!user.seller_profile?.stripe_account?.payouts_enabled ? "Connected" : "Not connected"}</td>
											</tr>
											<tr>
												<th scope="col">{getLang("tax")}</th>
												<td>{user.seller_profile.tax} %</td>
											</tr>
											<tr>
												<th scope="col">{getLang("registered_date")}</th>
												<td>{user.seller_profile.created_at}</td>
											</tr>
											<tr>
												<th scope="col">{user.seller_profile.status}</th>
												<td>
													{user.seller_profile.status === "APPROVED" ? (
														<button className="btn btn-danger" onClick={approveRejectSellerProfile.bind(this, "REJECTED")}>
															{getLang("reject")}
														</button>
													) : (
														<button className="btn btn-success" onClick={approveRejectSellerProfile.bind(this, "APPROVED")}>
															{getLang("approve")}
														</button>
													)}
												</td>
											</tr>
										</tbody>
									</table>
								)}
								{user.billing_infos &&
									user.billing_infos.map((item) => (
										<table className="table" key={item.id}>
											<tbody>
												<tr>
													<th scope="col">First Name</th>
													<td>{item.first_name}</td>
												</tr>
												<tr>
													<th scope="col">Last Name</th>
													<td>{item.last_name}</td>
												</tr>
												<tr>
													<th scope="col">Card</th>
													<td>
														{item.card_type} ***********{item.last4}
													</td>
												</tr>
												<tr>
													<th scope="col">Saved at</th>
													<td>{item.created_at}</td>
												</tr>
											</tbody>
										</table>
									))}
								{user.shipping_infos &&
									user.shipping_infos.map((item) => (
										<table className="table" key={item.id}>
											<tbody>
												<tr>
													<th scope="col">First Name</th>
													<td>{item.first_name}</td>
												</tr>
												<tr>
													<th scope="col">Last Name</th>
													<td>{item.last_name}</td>
												</tr>
												<tr>
													<th scope="col">Phone number</th>
													<td>{strToPhoneNumberFormat(item.phone_number)}</td>
												</tr>
												<tr>
													<th scope="col">Address 1</th>
													<td>{item.address_1}</td>
												</tr>
												<tr>
													<th scope="col">Address 2</th>
													<td>{item.address_2}</td>
												</tr>
												<tr>
													<th scope="col">City, Zip code</th>
													<td>
														{item.city} {item.zip_code}
													</td>
												</tr>
												<tr>
													<th scope="col">Special Instruction</th>
													<td>{item.special_instruction}</td>
												</tr>
												<tr>
													<th scope="col">Address Type</th>
													<td>{item.is_pickup ? "For Pickup" : "For Shipping"}</td>
												</tr>
												<tr>
													<th scope="col">Saved at</th>
													<td>{item.created_at}</td>
												</tr>
											</tbody>
										</table>
									))}
							</Col>
						</Row>
					</div>
				)}
			</Container>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

export default connect(mapStateToProps)(AdminUserDetailPage);
