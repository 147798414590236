import React, { useCallback, useLayoutEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Loader from "react-loader-spinner";
import * as AuthAction from "../../redux/auth/auth.action";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getToken } from "../../redux/auth/auth.selector";
import { extractErrors, isValidEmail, isValidPhoneNumber, strToPhoneNumberFormat, strToPhoneNumberString } from "../../helpers/commonHelper";
import { toast } from "react-toastify";
import { authRequestTwoFactorAuthCode } from "../../services/ApiService";
import AppleSignButton from "../../components/auth/apple-sign-button.component";
import GoogleSignButton from "../../components/auth/google-sign-button.component";
import { getLang } from "../../helpers/languageHelper";
import signLogo from "../../assets/images/eliqx-logo-all-grey.svg";
import "./login-register-page.styles.scss";

const PhoneNumberVerificationCodeBox = ({ loading, submitLogin }) => {
	const [code, setCode] = useState("");

	const inputHandle = (event) => {
		setCode(event.target.value);
	};
	const submit = () => {
		submitLogin(code);
	};

	return (
		<div className="login-register-page">
			{loading ? (
				<div className="loading">
					<Loader type="Puff" color="#04d39f" height="100" width="100" />
				</div>
			) : (
				<Modal isOpen={true} className="modal-login modal-dialog-centered auth-modal">
					<ModalHeader>
						<div className="mb-0" style={{ color: "white" }}>
							{getLang("phone_verification_code")}
						</div>
					</ModalHeader>
					<ModalBody>
						<label htmlFor="code">{getLang("your_code")}</label>
						<input type="text" id="code" value={code} onChange={inputHandle} autoComplete="off" />
						<button className="btn btn-primary mt-3" onClick={submit}>
							{getLang("verify")}
						</button>
					</ModalBody>
				</Modal>
			)}
		</div>
	);
};

const LoginBox = ({ form, inputHandle, login }) => (
	<form onSubmit={login}>
		<div className="form-group">
			<label>{getLang("email_address")}</label>
			<input type="email" className="form-control" placeholder={getLang("example@exa.com")} required name="email" value={form.email} onChange={inputHandle} />
		</div>
		<div className="form-group">
			<label>{getLang("password")} </label>
			<input type="password" className="form-control" placeholder={getLang("password")} required name="password" value={form.password} onChange={inputHandle} />
		</div>
		<input type="submit" style={{ width: "0px", height: "0px", visibility: "hidden", zIndex: -1000 }} />
		<div className="form-group" style={{ textAlign: "center" }}>
			<Link to="#" onClick={login} className="btn btn-primary mt-1 sign-now" replace={true}>
				{getLang("log_in")}
			</Link>
			<Link to="/forgot-password" className="btn btn-link mt-1">
				Forgot Password
			</Link>
		</div>
	</form>
);

const RegisterBox = ({ form, inputHandle, register }) => {
	const [mustAgree, setMustAgree] = useState(false);
	const submit = useCallback(
		(event) => {
			event.preventDefault();
			if (!mustAgree) {
				toast.warning("You must agree with our Terms Of Service and Privacy Policy");
				return;
			}
			register();
		},
		[mustAgree, register]
	);

	return (
		<form onSubmit={submit}>
			<div className="form-group">
				<label>{getLang("name")}</label>
				<input type="text" className="form-control" placeholder={getLang("name")} required name="name" value={form.name} onChange={inputHandle} />
			</div>
			<div className="form-group">
				<label>{getLang("email_address")}</label>
				<input type="email" className="form-control" placeholder={getLang("enter_email")} required name="email" value={form.email} onChange={inputHandle} />
			</div>
			<div className="form-group">
				<label>{getLang("phone_number")}</label>
				<input
					type="text"
					className="form-control"
					placeholder="(800) 555-1212"
					required
					name="phone_number"
					value={strToPhoneNumberFormat(form.phone_number)}
					onChange={inputHandle}
				/>
			</div>
			<div className="form-group">
				<label>{getLang("password")} </label>
				<input
					type="password"
					className="form-control"
					placeholder={getLang("password")}
					required
					name="password"
					value={form.password}
					onChange={inputHandle}
				/>
			</div>
			<div className="form-group">
				<label>{getLang("confirm_password")}</label>
				<input
					type="password"
					className="form-control"
					placeholder={getLang("confirm_password")}
					required
					name="password_confirmation"
					value={form.password_confirmation}
					onChange={inputHandle}
				/>
			</div>
			<div className="must-agree">
				<span className="check-icon" onClick={() => setMustAgree(!mustAgree)}>
					<i className={mustAgree ? "fa fa-check-square-o" : "fa fa-square-o"}></i>
				</span>
				<span className="cc-checkbox">
					By signing up, you agree to the
					<Link className="cc-tos-pp-agree" to="/legal/terms-of-use">
						Terms of service
					</Link>
					and
					<Link className="cc-tos-pp-agree" to="/legal/privacy-policy">
						Privacy Policy
					</Link>
				</span>
			</div>
			<input type="submit" style={{ display: "none" }} />
			<div className="form-group" style={{ textAlign: "center" }}>
				<Link to="#" className="btn btn-primary sign-now" onClick={submit} replace={true}>
					{getLang("register")}
				</Link>
			</div>
		</form>
	);
};

const LoginRegisterPage = ({ history, location, token, submitLogin, submitRegister }) => {
	const [activeTab, setActiveTab] = useState("1");
	const [loading, setLoading] = useState(false);
	const [showCode, setShowCode] = useState(false);
	const [sid, setSid] = useState();
	const [form, setForm] = useState({
		name: "",
		email: "",
		email_verify_code: null,
		sid: "",
		phone_number: "",
		password: "",
		password_confirmation: "",
		shipping_address: "",
		city: "",
		zip_code: "",
	});

	const inputHandle = (event) => {
		const { name, value } = event.target;
		if (name === "phone_number") {
			setForm({ ...form, [name]: strToPhoneNumberString(value) });
		} else {
			setForm({ ...form, [name]: value });
		}
	};
	const loginNow = (code) => {
		setLoading(true);
		submitLogin(form.email, form.password, { code, sid })
			.then(() => goToRedirect())
			.catch((err) => {
				setLoading(false);
				toast.error(getLang(extractErrors(err)[0]));
			});
	};
	const login = (event) => {
		event.preventDefault();
		setLoading(true);
		authRequestTwoFactorAuthCode({ email: form.email, password: form.password })
			.then((res) => {
				if (res.status_code === 1) {
					setLoading(false);
					setSid(res.sid);
					setShowCode(true);
				} else {
					submitLogin(form.email, form.password)
						.then(() => goToRedirect())
						.catch((err) => {
							toast.error(getLang(extractErrors(err)[0]));
							setLoading(false);
						});
				}
			})
			.catch((err) => {
				setLoading(false);
				toast.error(getLang(extractErrors(err)[0]));
			});
	};
	const register = () => {
		if (form.password !== form.password_confirmation) {
			toast.error(getLang("password_confirmation_mismatch"));
			return;
		}
		if (!isValidPhoneNumber(form.phone_number) || !isValidEmail(form.email)) {
			toast.error(getLang("email_or_phone_number_is_invalid"));
			return;
		}
		setLoading(true);
		submitRegister({ ...form, link_prefix: `${window.location.origin}/email-verify-link/` })
			.then(() => goToRedirect())
			.catch((err) => {
				setLoading(false);
				toast.error(getLang(extractErrors(err)[0]));
			});
	};
	const goBack = () => {
		const redirect = new URLSearchParams(location.search).get("redirect");
		if (redirect) {
			history.replace(redirect);
		} else {
			history.goBack();
		}
	};
	const goToRedirect = useCallback(() => {
		let redirect = new URLSearchParams(location.search).get("redirect");
		if (!redirect || redirect === undefined) redirect = "/";
		history.replace(redirect);
	}, [history, location]);

	useLayoutEffect(() => {
		if (token) goToRedirect();
	}, [token, goToRedirect]);

	if (showCode) {
		return <PhoneNumberVerificationCodeBox loading={loading} submitLogin={loginNow} />;
	}
	return (
		<div className="login-register-page">
			{loading ? (
				<div className="loading">
					<Loader type="Puff" color="#04d39f" height="100" width="100" />
				</div>
			) : (
				<Modal isOpen={true} className="modal-login modal-dialog-centered auth-modal">
					<div className="sign-modal-header">
						<img alt="sign-logo" className="sign-logo" src={signLogo} />
						<span onClick={goBack} className="close-button">
							<i className="fa fa-close"></i>
						</span>
					</div>
					<ModalBody>
						<Nav tabs>
							<NavItem>
								<NavLink className={classnames({ active: activeTab === "1" })} onClick={() => setActiveTab("1")}>
									{getLang("register")}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className={classnames({ active: activeTab === "2" })} onClick={() => setActiveTab("2")}>
									{getLang("sign_in")}
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<RegisterBox form={form} inputHandle={inputHandle} register={register} />
							</TabPane>
							<TabPane tabId="2">
								<LoginBox form={form} inputHandle={inputHandle} login={login} />
							</TabPane>
						</TabContent>
						<div className="social-buttons-wrapper">
							<GoogleSignButton />
							<AppleSignButton />
						</div>
					</ModalBody>
				</Modal>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	token: getToken,
});

const mapDispatchToProps = (dispatch) => ({
	submitLogin: (email, password, more = {}) => dispatch(AuthAction.login(email, password, more)),
	submitRegister: (form) => dispatch(AuthAction.register(form)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginRegisterPage));
