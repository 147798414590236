import React, { useState } from "react";
import { toast } from "react-toastify";
import NormalLoading from "../../components/ui-kits/normal-loading.component";
import { extractErrors, strToPhoneNumberFormat, strToPhoneNumberString } from "../../helpers/commonHelper";
import { authForgotPassword } from "../../services/ApiService";
import "./forgot-password.styles.scss";

const ForgotPasswordPage = ({ history }) => {
	const [method, setMethod] = useState();
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [loading, setLoading] = useState(false);

	const emailInputHandle = (event) => {
		setEmail(event.target.value);
	};
	const phoneInputHandle = (event) => {
		setPhoneNumber(strToPhoneNumberString(event.target.value));
	};

	const submitForm = (event) => {
		event.preventDefault();
		const front_url = window.location.origin;
		const form = method === "EMAIL" ? { front_url, email } : { phone_number: phoneNumber };
		setLoading(true);
		authForgotPassword(form)
			.then((res) => {
				setLoading(false);
				toast.success("Successfully submitted");
				if (method === "PHONE") {
					history.replace(`/${res.user_id}/reset-password`);
				}
			})
			.catch((err) => {
				setLoading(false);
				toast.error(extractErrors(err)[0]);
			});
	};

	if (loading) {
		return (
			<div className="forgot-password-page">
				<h1>Please select how you would like to reset:</h1>
				<div className="loading">
					<NormalLoading />
				</div>
			</div>
		);
	}
	return (
		<div className="forgot-password-page">
			<h1>Please select how you would like to reset:</h1>
			{!method && (
				<div className="method-buttons">
					<button className="email-button" onClick={() => setMethod("EMAIL")}>
						via Email
					</button>
					<button className="phone-button" onClick={() => setMethod("PHONE")}>
						via Phone
					</button>
				</div>
			)}
			{method === "EMAIL" && (
				<form className="form email-form" onSubmit={submitForm}>
					<input type="email" className="email" placeholder="E-mail Address" required value={email} onChange={emailInputHandle.bind(this)} />
					<button className="btn" type="submit">
						Submit
					</button>
				</form>
			)}
			{method === "PHONE" && (
				<form className="form phone-form" onSubmit={submitForm}>
					<input
						type="tel"
						className="phone"
						placeholder="Phone number"
						required
						value={strToPhoneNumberFormat(phoneNumber)}
						onChange={phoneInputHandle.bind(this)}
					/>
					<button className="btn" type="submit">
						Submit
					</button>
				</form>
			)}
		</div>
	);
};

export default ForgotPasswordPage;
